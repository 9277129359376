import React from 'react';
import { Col, Table, Button, Icon, Row, message, Tag } from 'antd';
import axios from "axios";
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { pedidoListURL, proveedoresListURL } from "../../constants";
import { Link } from 'react-router-dom';

class PedidosList extends React.Component {
    state = {
        filteredInfo: null,
        loading: false,
        error: null,
        data: [],
        filtersProveedores: []
    }

    componentDidMount() {
        this.props.onVisit()
        this.setState({ loading: true });
        axios
            .get(pedidoListURL)
            .then(res => {
                //console.log(res.data)
                let pedidos = res.data.map(p => {
                    let trimmed_nota = (p.nota.length > 15 ? p.nota.slice(0, 30) + ' ...' : p.nota)
                    return (
                        {
                            key: p.id,
                            id: p.id,
                            proveedor: p.proveedor.nombre,
                            count_productos: p.count_productos,
                            nota: trimmed_nota,
                            status_pagos: p.status_pagos,
                            fecha: p.fecha,
                        }
                    )
                });
                this.setState({ 
                    data: pedidos,
                    loading: false
                 });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
        axios
            .get(proveedoresListURL)
            .then(res => {
                let proveedores = res.data.map(p => {
                    return (
                        {
                            text: p.nombre,
                            value: p.nombre
                        }
                    )
                });
                this.setState({ filtersProveedores: proveedores });
            })
            .catch(err => {
                console.log(err)
            });
    }

    //FILTROS FILTER
    handleChange = (pagination, filters, sorter) => {
        //console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null
        });
        message.success('Se limpiaron los filtros')
    };
    //TERMINAN FILTROS

    render() {
        let { filteredInfo, filtersProveedores, loading } = this.state;
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                render: (text, record) => <Link to={record.id + '/'}>{text}</Link>,
            },
            {
                title: 'Proveedor',
                dataIndex: 'proveedor',
                key: 'proveedor',
                filters: filtersProveedores, //[{ text: 'Elite Vapor', value: 'Elite Vapor' }, { text: 'Relx', value: 'Relx' }],
                filteredValue: filteredInfo.proveedor || null,
                onFilter: (value, record) => record.proveedor.includes(value),
                ellipsis: true,
            },
            {
                title: '# Productos',
                dataIndex: 'count_productos',
                key: 'count_productos',
                align: 'center'
            },
            {
                title: 'Comentarios',
                dataIndex: 'nota',
                key: 'nota'
            },
            {
                title: 'Status',
                dataIndex: 'status_pagos',
                key: 'status_pagos',
                align: 'center',
                filters: [{ text: 'No pagado', value: 'No pagado' }, { text: 'Pagado', value: 'Pagado' }],
                filteredValue: filteredInfo.status_pagos || null,
                onFilter: (value, record) => record.status_pagos.includes(value),
                render: (tag) => {
                    let color = (tag === "No pagado" ? "magenta" : "green")
                    return (
                        <Tag color={color} key={tag}>
                            {tag}
                        </Tag>
                    )
                }
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
            },
        ];

        return (
            <React.Fragment>
                <Row gutter={[16, 16]} >
                    <Col span={12}>
                        <h3 style={{ textAlign: 'left' }}>Lista de Pedidos</h3>
                    </Col>
                    <Col span={12}>
                        <Button style={{ float: 'right' }} onClick={this.clearAll}><Icon type="filter" /> Limpiar Filtros</Button>
                    </Col>
                </Row>
                <Table scroll={{x: true}} columns={columns} dataSource={this.state.data} bordered={true} pagination={{ defaultPageSize: 10 }} onChange={this.handleChange} loading={loading}/>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/pedidos/', 'Pedidos (Recepciones de mercancía)', '/', '', 1))
        //link1, label 1, link 2, label 2
    }
}

export default connect(null, mapDispatchToProps)(PedidosList);
