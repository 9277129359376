import React, { Component } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import Highlighter from 'react-highlight-words';
import { PagosURL, FormasDePagoURL } from '../../constants'
import axios from 'axios'
import randomColor from 'randomcolor';
import moment from 'moment';
import { newPage } from "../../store/actions/breadcrumbs";
import { connect } from 'react-redux';
import { DatePicker, Divider, Row, Col, Tag, Table, Button, Icon, Input } from 'antd';
import { Link } from 'react-router-dom';
const { RangePicker } = DatePicker;

class ReporteIngresos extends Component {
    constructor(props) {
        super(props);
        const date = new Date()
        this.state = {
            startDate: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10),
            endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().substring(0, 10),
            ingresosMenosEgresos: 0,
            ingresosTotalesPeriodo: 0,
            egresosTotalesPeriodo: 0,
            test: {},
            lineChart: {},
            pieConceptos: {},
            pieConceptosEgresos: {},
            pieFormas: {},
            pieFormasEgresos: {},
            ingresos_neteados_diego: 0,
            ingresos_neteados_roberto: 0,
            loading: false,
            filtersForma: [],
            utilidades: [],
        }
    }


    componentDidMount() {
        if(this.props.perms['ver_reporte_ingresos_e_inventarios'] === true) {
            this.props.onVisit()
            this.loadData()
        }
    }

    loadData = () => {
        //this.setState({ loading: true })
        axios
            .get(FormasDePagoURL + '?filtrosocios=si')
            .then(res => {
                let formas = res.data.map(f => {
                    return (
                        {
                            text: f.nombre,
                            value: f.nombre
                        }
                    )
                });
                this.setState({ filtersForma: formas });
            })
            .catch(err => {
                console.log(err)
            });
        axios
            .get(PagosURL + '?filtrosocios=si&start=' + this.state.startDate + '&end=' + this.state.endDate)
            .then(res => {
                let pagos = res.data.map(pago => {
                    return (
                        {
                            key: pago.id,
                            id: pago.id,
                            monto_numero: pago.monto,
                            cuenta: pago.forma_de_pago.id,
                            monto: pago.monto.toLocaleString(),
                            forma_de_pago: pago.forma_de_pago.nombre,
                            concepto: pago.concepto.nombre,
                            fecha: pago.fecha,
                        }
                    )
                });
 
                const ingresos_neteados_diego = pagos
                    .filter(p => p.cuenta === 5)                    
                    .reduce((sum, el) => {
                        return sum + el.monto_numero;
                    }, 0);
                const ingresos_neteados_roberto = pagos
                    .filter(p => p.cuenta === 6)                    
                    .reduce((sum, el) => {
                        return sum + el.monto_numero;
                    }, 0);

                this.setState({ 
                    ...this.state,
                    utilidades: pagos,
                    ingresos_neteados_diego, 
                    ingresos_neteados_roberto,
                    loading: false 
                });

            })
            .catch(err => {
                console.log(err)
                this.setState({ 
                    ...this.state,
                    loading: false
                });
            });
        axios.get(PagosURL + '?traspaso=sin&order_by=normal&start=' + this.state.startDate + '&end=' + this.state.endDate) //Line Ingresos
            .then(res => {
                const pagos = res.data.map(pago => {
                    return (
                        {
                            id: pago.id,
                            fecha: pago.fecha,
                            monto: pago.monto
                        }
                    )
                });

                const ingresosMenosEgresos = pagos
                    .map(p => p.monto)
                    .reduce((sum, el) => {
                        return sum + el;
                    }, 0);

                const arr_ingresos = []
                const arr_egresos = []
                pagos.map(p => {
                    const arr1 = (p.monto > 0 ? arr_ingresos : arr_egresos)
                    arr1.push(p.monto);
                    return 'success';
                });

                const ingresosTotalesPeriodo = arr_ingresos
                    //.map(m => m)
                    .reduce((sum, el) => {
                        return sum + el;
                    }, 0);

                const egresosTotalesPeriodo = arr_egresos
                    //.map(m => m)
                    .reduce((sum, el) => {
                        return sum + el;
                    }, 0);

                this.setState({
                    ingresosTotalesPeriodo,
                    egresosTotalesPeriodo,
                    ingresosMenosEgresos,
                    lineChart:
                    {
                        labels: pagos.map(p => {
                            return p.fecha
                        }),
                        datasets: [
                            {
                                label: 'Ingresos - Egresos',
                                hidden: false,
                                data: pagos.map(p => {
                                    return (p.monto)
                                }),
                                backgroundColor: pagos.map(p => {
                                    return 'rgba(75, 192, 192, 0.6)'
                                }),
                                //#fill: false
                            }
                        ]
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });

        axios.get(PagosURL + '?traspaso=sin&order_by=normal&start=' + this.state.startDate + '&end=' + this.state.endDate) // Pie Ingresos Concepto de pago
            .then(res => {
                const pagos = res.data.map(p => {
                    return (
                        {
                            id: p.id,
                            concepto: p.concepto,
                            monto: p.monto,
                            fecha: p.fecha
                        }
                    )
                })

                const arr_ingresos = []
                const arr_egresos = []
                pagos.map(p => {
                    const arr = (p.monto > 0 ? arr_ingresos : arr_egresos)
                    if (p.fecha >= this.state.startDate && p.fecha <= this.state.endDate) {
                        let obj_buscado = arr.find(x => x.concepto.id === p.concepto.id)
                        if (obj_buscado) {
                            let index = arr.findIndex(x => x.concepto.id === p.concepto.id) // regresa el numero de index del arr
                            arr[index].monto = arr[index].monto + p.monto // asi puedes acceder al arr y modificarlo
                        }
                        else {
                            arr.push(p);
                        };
                    }

                    return 'success';
                });

                this.setState({
                    pieConceptos:
                    {
                        labels: arr_ingresos.map(p => {
                            return p.concepto.nombre
                        }),
                        datasets: [
                            {
                                data: arr_ingresos.map(p => {
                                    return p.monto
                                }),
                                backgroundColor: randomColor({ //returns array of colors
                                    luminosity: 'light',
                                    count: arr_ingresos.length,
                                    hue: 'random'
                                }),
                            }
                        ]
                    },
                    pieConceptosEgresos:
                    {
                        labels: arr_egresos.map(p => {
                            return p.concepto.nombre
                        }),
                        datasets: [
                            {
                                data: arr_egresos.map(p => {
                                    return p.monto
                                }),
                                backgroundColor: randomColor({ //returns array of colors
                                    luminosity: 'light',
                                    count: arr_egresos.length,
                                    hue: 'random'
                                }),
                            }
                        ]
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });

        axios.get(PagosURL + '?traspaso=sin&order_by=normal&start=' + this.state.startDate + '&end=' + this.state.endDate) // Pie Ingresos Formas de pago
            .then(res => {
                const pagos = res.data.map(p => {
                    return (
                        {
                            id: p.id,
                            forma_de_pago: p.forma_de_pago,
                            monto: p.monto,
                            fecha: p.fecha
                        }
                    )
                })

                const arr_ingresos = []
                const arr_egresos = []
                pagos.map(p => {
                    const arr = (p.monto > 0 ? arr_ingresos : arr_egresos)
                    if (p.fecha >= this.state.startDate && p.fecha <= this.state.endDate) {
                        let obj_buscado = arr.find(x => x.forma_de_pago.id === p.forma_de_pago.id)
                        if (obj_buscado) {
                            let index = arr.findIndex(x => x.forma_de_pago.id === p.forma_de_pago.id) // regresa el numero de index del arr
                            arr[index].monto = arr[index].monto + p.monto // asi puedes acceder al arr y modificarlo
                        }
                        else {
                            arr.push(p);
                        };
                    }

                    return 'success';
                });

                this.setState({
                    pieFormas:
                    {
                        labels: arr_ingresos.map(p => {
                            return p.forma_de_pago.nombre
                        }),
                        datasets: [
                            {
                                data: arr_ingresos.map(p => {
                                    return p.monto
                                }),
                                backgroundColor: randomColor({ //returns array of colors
                                    luminosity: 'light',
                                    count: arr_ingresos.length,
                                    hue: 'random'
                                }),
                            }
                        ]
                    },
                    pieFormasEgresos:
                    {
                        labels: arr_egresos.map(p => {
                            return p.forma_de_pago.nombre
                        }),
                        datasets: [
                            {
                                data: arr_egresos.map(p => {
                                    return p.monto
                                }),
                                backgroundColor: randomColor({ //returns array of colors
                                    luminosity: 'light',
                                    count: arr_egresos.length,
                                    hue: 'random'
                                }),
                            }
                        ]
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });

    }

    componentDidUpdate(prevProps, prevState){
        if (this.state.ingresosTotalesPeriodo !== prevState.ingresosTotalesPeriodo || this.state.endDate !== prevState.endDate) {
            this.loadData();
    }
}


    onChange = (date, dateString) => {
        //console.log(date, dateString);
        this.setState({
            ...this.state,
            startDate: dateString[0],
            endDate: dateString[1],
            ingresosTotalesPeriodo: 0,
            egresosTotalesPeriodo: 0
        })
    }

    //EMPIEZAN FILTROS PARA SEARCH
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar producto`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });


    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    //TERMINAN FILTROS PARA SEARCH
    //FILTROS FILTER
    handleChange = (pagination, filters, sorter) => {
        //console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    //TERMINAN FILTROS FILTER

    static defaultProps = {
        displayTitle: true,
        displayLegend: true,
        legendPosition: 'right',
        location: 'City'
    }

    render() {
        const dateFormat = 'YYYY-MM-DD';
        const { startDate, endDate, ingresosMenosEgresos, ingresosTotalesPeriodo, egresosTotalesPeriodo, pieConceptos, pieConceptosEgresos, pieFormasEgresos, pieFormas } = this.state
        let { filteredInfo, filtersForma, loading } = this.state;
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                render: (text, record) => <Link to={record.id + '/'}>{text}</Link>,
            },
            {
                title: 'Monto',
                dataIndex: 'monto',
                key: 'monto',
                align: 'center',
                render: (tag) => {
                            let color = (tag.includes("-") ? "magenta" : "green")
                            return (
                                <Tag color={color} key={Math.random()}>
                                    {tag}
                                </Tag>
                            );
                        }
            },
            {
                title: 'Cuenta',
                dataIndex: 'forma_de_pago',
                key: 'forma_de_pago',
                filters: filtersForma, //[{ text: 'Elite Vapor', value: 'Elite Vapor' }, { text: 'Relx', value: 'Relx' }],
                filteredValue: filteredInfo.forma_de_pago || null,
                onFilter: (value, record) => record.forma_de_pago.includes(value),
                ellipsis: true,
            },
            {
                title: 'Concepto',
                dataIndex: 'concepto',
                key: 'concepto'
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
            },
        ];


        return (
            this.props.perms['ver_reporte_ingresos_e_inventarios'] === true &&
            (
            <React.Fragment>
                <Row>
                    <Col span={24}>
                        <div style={{ textAlign: 'center', paddingBottom: '1em' }}>
                            <h4> Rango de fechas: <RangePicker onChange={this.onChange} defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]} /> </h4>
                            <p><b>Total Ingresos del periodo:</b> {ingresosTotalesPeriodo.toLocaleString()}  |  <b>Total Egresos del periodo:</b> {egresosTotalesPeriodo.toLocaleString()}</p>
                        </div>
                        <div style={{ height: '450px' }}>
                            <Line
                                data={this.state.lineChart}
                                options={{
                                    maintainAspectRatio: false,
                                    title: {
                                        display: this.props.displayTitle,
                                        text: 'Ingresos - Egresos: $' + ingresosMenosEgresos.toLocaleString(),
                                        fontSize: 21,
                                    },
                                    legend: {
                                        display: this.props.displayLegend,
                                        position: this.props.legendPosition
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }],
                                        xAxes: [{
                                            barPercentage: 0.8 // width del bar -tal cual el rectangulo vertical-
                                        }]
                                    }
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Pie
                            data={pieConceptos}
                            options={{
                                title: {
                                    display: this.props.displayTitle,
                                    text: 'Ingresos por Concepto',
                                    fontSize: 21
                                },
                                legend: {
                                    display: this.props.displayLegend,
                                    position: this.props.legendPosition
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function (tooltipItem, data) {
                                            var allData = data.datasets[tooltipItem.datasetIndex].data;
                                            var tooltipLabel = data.labels[tooltipItem.index];
                                            var tooltipData = allData[tooltipItem.index];
                                            var total = 0;
                                            for (var i in allData) {
                                                total += allData[i];
                                            }
                                            var tooltipPercentage = ((tooltipData / total) * 100).toFixed(2);
                                            return tooltipLabel + ': $' + tooltipData + ' | ' + tooltipPercentage + '%';
                                        }
                                    }
                                }
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <Pie
                            data={pieConceptosEgresos}
                            options={{
                                title: {
                                    display: this.props.displayTitle,
                                    text: 'Egresos por Concepto',
                                    fontSize: 21
                                },
                                legend: {
                                    display: this.props.displayLegend,
                                    position: this.props.legendPosition
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function (tooltipItem, data) {
                                            var allData = data.datasets[tooltipItem.datasetIndex].data;
                                            var tooltipLabel = data.labels[tooltipItem.index];
                                            var tooltipData = allData[tooltipItem.index];
                                            var total = 0;
                                            for (var i in allData) {
                                                total += allData[i];
                                            }
                                            var tooltipPercentage = ((tooltipData / total) * 100).toFixed(2);
                                            return tooltipLabel + ': $' + tooltipData + ' | ' + tooltipPercentage + '%';
                                        }
                                    }
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Pie
                            data={pieFormas}
                            options={{
                                title: {
                                    display: this.props.displayTitle,
                                    text: 'Ingresos por Forma de Pago',
                                    fontSize: 21
                                },
                                legend: {
                                    display: this.props.displayLegend,
                                    position: this.props.legendPosition
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function (tooltipItem, data) {
                                            var allData = data.datasets[tooltipItem.datasetIndex].data;
                                            var tooltipLabel = data.labels[tooltipItem.index];
                                            var tooltipData = allData[tooltipItem.index];
                                            var total = 0;
                                            for (var i in allData) {
                                                total += allData[i];
                                            }
                                            var tooltipPercentage = ((tooltipData / total) * 100).toFixed(2);
                                            return tooltipLabel + ': $' + tooltipData + ' | ' + tooltipPercentage + '%';
                                        }
                                    }
                                }
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <Pie
                            data={pieFormasEgresos}
                            options={{
                                title: {
                                    display: this.props.displayTitle,
                                    text: 'Egresos por Forma de Pago',
                                    fontSize: 21
                                },
                                legend: {
                                    display: this.props.displayLegend,
                                    position: this.props.legendPosition
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function (tooltipItem, data) {
                                            var allData = data.datasets[tooltipItem.datasetIndex].data;
                                            var tooltipLabel = data.labels[tooltipItem.index];
                                            var tooltipData = allData[tooltipItem.index];
                                            var total = 0;
                                            for (var i in allData) {
                                                total += allData[i];
                                            }
                                            var tooltipPercentage = ((tooltipData / total) * 100).toFixed(2);
                                            return tooltipLabel + ': $' + tooltipData + ' | ' + tooltipPercentage + '%';
                                        }
                                    }
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Divider />
                <h3 style={{ textAlign: 'center' }}>Repartos y utilidades del periodo</h3>
                <p style={{ textAlign: 'center' }}><b>Total Diego:</b> {this.state.ingresos_neteados_diego.toLocaleString()}  |  <b>Total Roberto:</b> {this.state.ingresos_neteados_roberto.toLocaleString()}</p>
                <Table columns={columns} dataSource={this.state.utilidades} bordered={true} pagination={{ defaultPageSize: 20 }} onChange={this.handleChange} loading={loading}/>
            </React.Fragment>
            )
        )
    }
}

const mapStateToProps = state => {
  return {
    perms: state.auth.permissions
  };
};

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/reportes/ingresos/', 'Reporte Ingresos y Egresos', '', '', 1))
        //link1, label 1, link 2, label 2
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReporteIngresos);