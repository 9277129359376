import React, { Component } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  ventaProductoListURL,
  ventasListURL,
  PagosURL,
  categoriasListURL,
} from '../../constants';
import axios from 'axios';
import randomColor from 'randomcolor';
import moment from 'moment';
import { newPage } from '../../store/actions/breadcrumbs';
import { connect } from 'react-redux';
import { DatePicker, Divider, Row, Col, Radio, Select, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import Logo from './../../VapesYArreglaAColor.png';
const { RangePicker } = DatePicker;
const { Option } = Select;

//NOTAS MÁS RECIENTES
//Field "productos" a la fecha mantiene a productos que fueron devolución o garantía (no los quita de ahí). y el reporte de ventas jala a todos los M2M de venta productos, y de ahí jala la info.

//NOTAS IMPORTANTES SOBRE EL REPORTE DE VENTAS
//El "Valor Ventas" la cantidad total de "piezas" son la suma de "Ventas porducto instances" independientemente de si se haya hecho una devolución,
//independientemente de si se hayan dado de alta piezas como "Servicios en general" porque el display no estaba dado de alta en el sistema y luego que se hayan dado de baja esos "servicios en general" y se haya dado de alta "el display" en la venta
//en ese caso, se sumarían los dos al "valor de venta" el "servicio en general" que después se dio de baja y el "display" que luego se dio de alta en la venta cuando se registró en el sistema.
//La gran diferencia entre "Valor Ventas" arriba y "Total Ingresos del periodo" abajo, es que se dan de alta servicios y luego se aplica devolución en ellos para dar de alta el producto real en la venta.
//En abril 2021 el valor venta arriba eran 380mil. El total de servicios era 130mil, el total de ingresos era aprox 250mil.
//380mil - 130mil (la mayoriá servicios) = aprox 250mil
//Los datos de las graficas (sobre todo los pie charts) son bastante reales, solo se tiene que tachar la parte de servicios.
//Lo ideas sería que los productos siempre estén dados de alta para que no se tenga que duplicar info.
//Si quisiera cambiar por los datos correctos, simplemente tengo que ir al modelo Venta, y usar los productos de ahí, las devoluciones y garantías fields ya no estarían involucradas.
//osea haces un call a ventas.productos.all() y filtras las fechas de la venta
//Tendría que hacer un ventasVentaProductoProductosUrl react y view django, y poner exactamente lo mismo pero pasando solo las ventas productos de producto.
class Chart extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      startDate: new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .substring(0, 10),
      endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        .toISOString()
        .substring(0, 10),
      ventasTotales: 0,
      piezasTotales: 0,
      test: {},
      barChart: {},
      pieProductos: {},
      pieCategorias: {},
      pieMarcas: {},
      pieModelos: {},
      pieSabores: {},
      pieNicotina: {},
      piePromociones: {},
      tienda: 'T',
      ingresosPositivos: 0,
      ingresosNegativos: 0,
      facturacionReal: 0,
      CuentasPorCobrar: 0,
      ValorEnDevoluciones: 0,
      categoria: 'Todas las categorías',
      categorias: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.props.onVisit();
    if (this.props.token !== null) {
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({ ...this.state, loading: true });
    let base_url =
      ventaProductoListURL +
      '?reporte=si&start=' +
      this.state.startDate +
      '&end=' +
      this.state.endDate +
      '&tienda=' +
      this.state.tienda;
    let base_url_promos_y_cpc =
      ventasListURL +
      '?order_by=normal&start=' +
      this.state.startDate +
      '&end=' +
      this.state.endDate +
      '&tienda=' +
      this.state.tienda;
    if (
      this.state.categoria !== 'Todas las categorías' &&
      this.state.categoria !== undefined &&
      this.state.categoria !== 'todas'
    ) {
      base_url = base_url + `&categoria=${this.state.categoria}`;
      base_url_promos_y_cpc =
        base_url_promos_y_cpc + `&categoria=${this.state.categoria}`;
    }
    axios
      .get(categoriasListURL)
      .then((res) => {
        this.setState({
          ...this.state,
          categorias: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(base_url) //Bar Ventas
      .then((res) => {
        //Bar Ventas
        const productosventa2 = res.data.map((venta) => {
          const dinero_venta = venta.cantidad * venta.precio_de_venta;
          const piezas_vendidas = venta.cantidad;
          return {
            id: venta.id,
            fecha: venta.fecha,
            venta: dinero_venta,
            piezas: piezas_vendidas,
          };
        });

        const arrventas = [];
        productosventa2.map((dia) => {
          console.log(dia, 'dia');
          if (
            dia.fecha >= this.state.startDate &&
            dia.fecha <= this.state.endDate
          ) {
            //sirve para que se acumulen las ventas del mismo día
            let obj_buscado_ventas = arrventas.find(
              (x) => x.fecha === dia.fecha
            );
            if (obj_buscado_ventas) {
              let index = arrventas.findIndex((x) => x.fecha === dia.fecha); // regresa el numero de index del arrventas
              arrventas[index].venta = arrventas[index].venta + dia.venta; // asi puedes acceder al arrventas y modificarlo
              arrventas[index].piezas = arrventas[index].piezas + dia.piezas;
              // console.log(index, 'index')
              // console.log(arrventas[index], 'arrventas[index]')
            } else {
              arrventas.push(dia);
            }
          }
          return 'success';
        });

        const ventasTotales0 = arrventas
          .map((v) => {
            return v.venta;
          })
          .reduce((sum, el) => {
            return sum + el;
          }, 0);

        const piezasTotales = arrventas
          .map((v) => {
            return v.piezas;
          })
          .reduce((sum, el) => {
            return sum + el;
          }, 0);
        //Bar Ventas
        // Pie Productos
        const objprod = res.data.map((prod) => {
          return {
            id: prod.producto.id,
            nombre: prod.producto.nombre,
            ventas: prod.cantidad * prod.precio_de_venta,
            fecha: prod.fecha,
            piezas: prod.cantidad,
          };
        });
        const objprod2 = objprod.reduce(function (a, b) {
          return a.concat(b);
        }, []);

        const arrprod = [];
        objprod2.map((prod) => {
          if (
            prod.fecha >= this.state.startDate &&
            prod.fecha <= this.state.endDate
          ) {
            let obj_buscado_prod = arrprod.find((x) => x.id === prod.id);
            if (obj_buscado_prod) {
              let index = arrprod.findIndex((x) => x.id === prod.id); // regresa el numero de index del arrprod
              arrprod[index].ventas = arrprod[index].ventas + prod.ventas; // asi puedes acceder al arrprod y modificarlo
              arrprod[index].piezas = arrprod[index].piezas + prod.piezas;
            } else {
              arrprod.push(prod);
            }
          }
          return 'success';
        });
        // Pie Productos
        // Pie Marcas
        const objmarc = res.data.map((prod) => {
          return {
            id: prod.producto.id,
            marca: prod.producto.marca,
            ventas: prod.cantidad * prod.precio_de_venta,
            fecha: prod.fecha,
            piezas: prod.cantidad,
          };
        });
        const objmarc2 = objmarc.reduce(function (a, b) {
          return a.concat(b);
        }, []);

        const arrmarc = [];
        objmarc2.map((prod) => {
          if (
            prod.fecha >= this.state.startDate &&
            prod.fecha <= this.state.endDate
          ) {
            let obj_buscado_marc = arrmarc.find(
              (x) => x.marca.id === prod.marca.id
            );
            if (obj_buscado_marc) {
              let index = arrmarc.findIndex(
                (x) => x.marca.id === prod.marca.id
              ); // regresa el numero de index del arrmarc
              arrmarc[index].ventas = arrmarc[index].ventas + prod.ventas; // asi puedes acceder al arrmarc y modificarlo
              arrmarc[index].piezas = arrmarc[index].piezas + prod.piezas;
            } else {
              arrmarc.push(prod);
            }
          }
          return 'success';
        });
        // Pie Marcas
        // Pie Categorías
        const objcate = res.data.map((prod) => {
          return {
            id: prod.producto.id,
            categoria: prod.producto.categoria,
            ventas: prod.cantidad * prod.precio_de_venta,
            fecha: prod.fecha,
            piezas: prod.cantidad,
          };
        });
        const objcate2 = objcate.reduce(function (a, b) {
          return a.concat(b);
        }, []);

        const arrcate = [];
        objcate2.map((prod) => {
          if (
            prod.fecha >= this.state.startDate &&
            prod.fecha <= this.state.endDate
          ) {
            let obj_buscado_cate = arrcate.find(
              (x) => x.categoria.id === prod.categoria.id
            );
            if (obj_buscado_cate) {
              let index = arrcate.findIndex(
                (x) => x.categoria.id === prod.categoria.id
              ); // regresa el numero de index del arrcate
              arrcate[index].ventas = arrcate[index].ventas + prod.ventas; // asi puedes acceder al arrcate y modificarlo
              arrcate[index].piezas = arrcate[index].piezas + prod.piezas;
            } else {
              arrcate.push(prod);
            }
          }
          return 'success';
        });
        // Pie Categorías
        // Pie Modelos
        const objmode = res.data.map((prod) => {
          return {
            id: prod.producto.id,
            modelo: prod.producto.modelo,
            ventas: prod.cantidad * prod.precio_de_venta,
            fecha: prod.fecha,
            piezas: prod.cantidad,
          };
        });
        const objmode2 = objmode.reduce(function (a, b) {
          return a.concat(b);
        }, []);

        const arrmode = [];
        objmode2.map((prod) => {
          if (
            prod.fecha >= this.state.startDate &&
            prod.fecha <= this.state.endDate
          ) {
            let obj_buscado_mode = arrmode.find(
              (x) => x.modelo.id === prod.modelo.id
            );
            if (obj_buscado_mode) {
              let index = arrmode.findIndex(
                (x) => x.modelo.id === prod.modelo.id
              ); // regresa el numero de index del arrmode
              arrmode[index].ventas = arrmode[index].ventas + prod.ventas; // asi puedes acceder al arrmode y modificarlo
              arrmode[index].piezas = arrmode[index].piezas + prod.piezas;
            } else {
              arrmode.push(prod);
            }
          }
          return 'success';
        });
        // Pie Modelos
        // Pie Sabores
        const objsabo = res.data.map((prod) => {
          return {
            id: prod.producto.id,
            sabor: prod.producto.sabor,
            ventas: prod.cantidad * prod.precio_de_venta,
            fecha: prod.fecha,
            piezas: prod.cantidad,
          };
        });
        const objsabo2 = objsabo.reduce(function (a, b) {
          return a.concat(b);
        }, []);

        const arrsabo = [];
        objsabo2.map((prod) => {
          if (
            prod.fecha >= this.state.startDate &&
            prod.fecha <= this.state.endDate
          ) {
            let obj_buscado_sabo = arrsabo.find(
              (x) => x.sabor.id === prod.sabor.id
            );
            if (obj_buscado_sabo) {
              let index = arrsabo.findIndex(
                (x) => x.sabor.id === prod.sabor.id
              ); // regresa el numero de index del arrsabo
              arrsabo[index].ventas = arrsabo[index].ventas + prod.ventas; // asi puedes acceder al arrsabo y modificarlo
              arrsabo[index].piezas = arrsabo[index].piezas + prod.piezas;
            } else {
              arrsabo.push(prod);
            }
          }
          return 'success';
        });
        // Pie Sabores
        // Pie Nicotina
        const objnico = res.data.map((prod) => {
          return {
            id: prod.producto.id,
            nicotina: prod.producto.nicotina,
            ventas: prod.cantidad * prod.precio_de_venta,
            fecha: prod.fecha,
            piezas: prod.cantidad,
          };
        });
        const objnico2 = objnico.reduce(function (a, b) {
          return a.concat(b);
        }, []);

        const arrnico = [];
        objnico2.map((prod) => {
          if (
            prod.fecha >= this.state.startDate &&
            prod.fecha <= this.state.endDate
          ) {
            let obj_buscado_nico = arrnico.find(
              (x) => x.nicotina.id === prod.nicotina.id
            );
            if (obj_buscado_nico) {
              let index = arrnico.findIndex(
                (x) => x.nicotina.id === prod.nicotina.id
              ); // regresa el numero de index del arrnico
              arrnico[index].ventas = arrnico[index].ventas + prod.ventas; // asi puedes acceder al arrnico y modificarlo
              arrnico[index].piezas = arrnico[index].piezas + prod.piezas;
            } else {
              arrnico.push(prod);
            }
          }
          return 'success';
        });
        // Pie Nicotina

        this.setState({
          ...this.state,
          ventasTotales: ventasTotales0, //Bar Ventas
          piezasTotales, //Bar Ventas
          //Bar Ventas
          barChart: {
            labels: arrventas.map((p) => {
              return p.fecha;
            }),
            datasets: [
              {
                label: 'Ingresos',
                hidden: false,
                data: arrventas.map((p) => {
                  return p.venta;
                }),
                backgroundColor: arrventas.map((p) => {
                  return 'rgba(255, 99, 132, 0.6)';
                }),
              },
              {
                label: 'Piezas',
                hidden: true,
                data: arrventas.map((p) => {
                  return p.piezas;
                }),
                backgroundColor: arrventas.map((p) => {
                  return 'rgba(54, 162, 235, 0.6)';
                }),
              },
            ],
          },
          // Pie Productos
          pieProductos: {
            labels: arrprod.map((p) => {
              return p.nombre + ' (' + p.piezas + ' pz)';
            }),
            datasets: [
              {
                data: arrprod.map((p) => {
                  return p.ventas;
                }),
                backgroundColor: randomColor({
                  //returns array of colors
                  luminosity: 'light',
                  count: arrprod.length,
                  hue: 'random',
                }),
              },
            ],
          },
          // Pie Marcas
          pieMarcas: {
            labels: arrmarc.map((p) => {
              return p.marca.nombre + ' (' + p.piezas + ' pz)';
            }),
            datasets: [
              {
                data: arrmarc.map((p) => {
                  return p.ventas;
                }),
                backgroundColor: randomColor({
                  //returns array of colors
                  luminosity: 'light',
                  count: arrmarc.length,
                  hue: 'random',
                }),
              },
            ],
          },
          // Pie Categorías
          pieCategorias: {
            labels: arrcate.map((p) => {
              return p.categoria.nombre + ' (' + p.piezas + ' pz)';
            }),
            pk: arrcate.map((p) => {
              return p.categoria.id;
            }),
            datasets: [
              {
                data: arrcate.map((p) => {
                  return p.ventas;
                }),
                backgroundColor: randomColor({
                  //returns array of colors
                  luminosity: 'light',
                  count: arrcate.length,
                  hue: 'random',
                }),
              },
            ],
          },
          // Pie Modelos
          pieModelos: {
            labels: arrmode.map((p) => {
              return (
                (p.modelo.nombre === '' ? 'N/A' : p.modelo.nombre) +
                ' (' +
                p.piezas +
                ' pz)'
              );
            }),
            datasets: [
              {
                data: arrmode.map((p) => {
                  return p.ventas;
                }),
                backgroundColor: randomColor({
                  //returns array of colors
                  luminosity: 'light',
                  count: arrmode.length,
                  hue: 'random',
                }),
              },
            ],
          },
          // Pie Sabores
          pieSabores: {
            labels: arrsabo.map((p) => {
              return (
                (p.sabor.nombre === '' ? 'N/A' : p.sabor.nombre) +
                ' (' +
                p.piezas +
                ' pz)'
              );
            }),
            datasets: [
              {
                data: arrsabo.map((p) => {
                  return p.ventas;
                }),
                backgroundColor: randomColor({
                  //returns array of colors
                  luminosity: 'light',
                  count: arrsabo.length,
                  hue: 'random',
                }),
              },
            ],
          },
          // Pie Nicotina
          pieNicotina: {
            labels: arrnico.map((p) => {
              return (
                (p.nicotina.nombre === '' ? 'N/A' : p.nicotina.nombre) +
                ' (' +
                p.piezas +
                ' pz)'
              );
            }),
            datasets: [
              {
                data: arrnico.map((p) => {
                  return p.ventas;
                }),
                backgroundColor: randomColor({
                  //returns array of colors
                  luminosity: 'light',
                  count: arrnico.length,
                  hue: 'random',
                }),
              },
            ],
          },
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // Pie PROMOCIONES y CUENTAS POR COBRAR
    axios
      .get(base_url_promos_y_cpc)
      .then((res) => {
        const saldo_pendiente = res.data
          .map((venta) => {
            return venta.saldo_pendiente;
          })
          .reduce((sum, el) => {
            return sum + el;
          }, 0);

        const sumatoria_valor_field_devoluciones = res.data
          .map((venta) => {
            return venta.sumatoria_valor_field_devoluciones;
          })
          .reduce((sum, el) => {
            return sum + el;
          }, 0);

        const obj1 = res.data.map((sale) => {
          const promociones = sale.promocion.map((promo) => {
            return {
              id: promo.id,
              nombre: promo.nombre,
              fecha: sale.fecha,
              cantidad: 1,
            };
          });

          return promociones;
        });
        const obj2 = obj1.reduce(function (a, b) {
          return a.concat(b);
        }, []);

        const arr = [];
        obj2.map((promo) => {
          if (
            promo.fecha >= this.state.startDate &&
            promo.fecha <= this.state.endDate
          ) {
            let obj_buscado = arr.find((x) => x.id === promo.id);
            if (obj_buscado) {
              let index = arr.findIndex((x) => x.id === promo.id); // regresa el numero de index del arr
              arr[index].cantidad = arr[index].cantidad + promo.cantidad; // asi puedes acceder al arr y modificarlo
            } else {
              arr.push(promo);
            }
          }
          return 'success';
        });
        this.setState({
          ...this.state,
          piePromociones: {
            labels: arr.map((p) => {
              return p.nombre + ' (' + p.cantidad + ' veces)';
            }),
            datasets: [
              {
                data: arr.map((p) => {
                  return p.cantidad;
                }),
                backgroundColor: randomColor({
                  //returns array of colors
                  luminosity: 'light',
                  count: arr.length,
                  hue: 'random',
                }),
              },
            ],
          },
          CuentasPorCobrar: saldo_pendiente,
          ValorEnDevoluciones: sumatoria_valor_field_devoluciones,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    //Facturación real del periodo, Pagos.
    axios
      .get(
        PagosURL +
          '?rventas=si&ingresos=positivo&start=' +
          this.state.startDate +
          '&end=' +
          this.state.endDate +
          '&tienda=' +
          this.state.tienda
      )
      .then((res) => {
        let pagos = res.data.map((pago) => {
          return {
            key: pago.id,
            id: pago.id,
            monto_numero: pago.monto,
          };
        });

        const ingresos_positivos = pagos.reduce((sum, el) => {
          return sum + el.monto_numero;
        }, 0);

        this.setState({
          ...this.state,
          ingresosPositivos: ingresos_positivos,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        PagosURL +
          '?rventas=si&ingresos=negativo&start=' +
          this.state.startDate +
          '&end=' +
          this.state.endDate +
          '&tienda=' +
          this.state.tienda
      )
      .then((res) => {
        let pagos = res.data.map((pago) => {
          return {
            key: pago.id,
            id: pago.id,
            monto_numero: pago.monto,
          };
        });

        const ingresos_negativos = pagos.reduce((sum, el) => {
          return sum + el.monto_numero;
        }, 0);

        this.setState({
          ...this.state,
          ingresosNegativos: ingresos_negativos,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        PagosURL +
          '?rventas=si&start=' +
          this.state.startDate +
          '&end=' +
          this.state.endDate +
          '&tienda=' +
          this.state.tienda
      )
      .then((res) => {
        let pagos = res.data.map((pago) => {
          return {
            key: pago.id,
            id: pago.id,
            monto_numero: pago.monto,
          };
        });

        const ingresos_netos = pagos.reduce((sum, el) => {
          return sum + el.monto_numero;
        }, 0);

        this.setState({
          ...this.state,
          facturacionReal: ingresos_netos,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.startDate !== prevState.startDate ||
      this.state.endDate !== prevState.endDate ||
      this.state.tienda !== prevState.tienda ||
      this.state.ingresosPositivos !== prevState.ingresosPositivos ||
      this.state.CuentasPorCobrar !== prevState.CuentasPorCobrar ||
      this.state.categoria !== prevState.categoria
    ) {
      this.loadData();
    }
  }

  onChange = (date, dateString) => {
    //console.log(date, dateString);
    this.setState({
      ...this.state,
      ...{
        startDate: dateString[0],
        endDate: dateString[1],
      },
    });
  };

  onChangeRadio = (e) => {
    //console.log('radio checked', e.target.value);
    this.setState({
      ...this.state,
      tienda: e.target.value,
    });
  };

  static defaultProps = {
    displayTitle: true,
    displayLegend: true,
    legendPosition: 'right',
    location: 'City',
  };

  handlePieClick = (id) => {
    //console.log('i recieved the id', id)
    this.setState({
      ...this.state,
      categoria: id,
    });
  };

  render() {
    const dateFormat = 'YYYY-MM-DD';
    const {
      startDate,
      endDate,
      ventasTotales,
      piezasTotales,
      pieProductos,
      pieMarcas,
      pieCategorias,
      pieModelos,
      pieSabores,
      pieNicotina,
      piePromociones,
      ingresosPositivos,
      ingresosNegativos,
      categorias,
    } = this.state;
    let dimension = window.innerWidth < 450 ? '200px' : '300px';
    return this.props.perms['ver_reporte_ventas'] === true ? (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: 'center', paddingBottom: '1em' }}>
              <h4>
                {' '}
                Rango de fechas:{' '}
                <RangePicker
                  onChange={this.onChange}
                  defaultValue={[
                    moment(startDate, dateFormat),
                    moment(endDate, dateFormat),
                  ]}
                />{' '}
              </h4>
              <Radio.Group
                onChange={this.onChangeRadio}
                value={this.state.tienda}
              >
                <Radio value={'T'}>Todas</Radio>
                <Radio value={'A'}>Arregla</Radio>
                <Radio value={'V'}>Vapes</Radio>
              </Radio.Group>
              <Select
                showSearch
                style={{ width: 170, textAlign: 'center' }}
                placeholder="Todas las Categorías"
                optionFilterProp="children"
                onChange={(id) => this.handlePieClick(id)}
                value={this.state.categoria}
                allowClear={true}
                autoFocus={true}
              >
                <Option key={'todas'} value={'todas'}>
                  Todas
                </Option>
                {categorias.map((c) => {
                  return (
                    <Option key={c.id} value={c.id}>
                      {c.nombre}
                    </Option>
                  );
                })}
              </Select>
              <span style={{ margin: '4px' }}></span>
              <Spin spinning={this.state.loading} />
            </div>
            <div style={{ height: '450px' }}>
              <Bar
                data={this.state.barChart}
                options={{
                  maintainAspectRatio: false,
                  title: {
                    display: this.props.displayTitle,
                    text:
                      'Valor Ventas: $' +
                      ventasTotales.toLocaleString() +
                      ' (' +
                      piezasTotales.toLocaleString() +
                      ' piezas)',
                    fontSize: 21,
                  },
                  legend: {
                    display: this.props.displayLegend,
                    position: this.props.legendPosition,
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        barPercentage: 0.8, // width del bar -tal cual el rectangulo vertical-
                      },
                    ],
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <Pie
              data={pieProductos}
              options={{
                title: {
                  display: this.props.displayTitle,
                  text: 'Productos (% Ventas)',
                  fontSize: 21,
                },
                legend: {
                  display: this.props.displayLegend,
                  position: this.props.legendPosition,
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var allData =
                        data.datasets[tooltipItem.datasetIndex].data;
                      var tooltipLabel = data.labels[tooltipItem.index];
                      var tooltipData = allData[tooltipItem.index];
                      var total = 0;
                      for (var i in allData) {
                        total += allData[i];
                      }
                      var tooltipPercentage = (
                        (tooltipData / total) *
                        100
                      ).toFixed(2);
                      return (
                        tooltipLabel +
                        ': $' +
                        tooltipData +
                        ' | ' +
                        tooltipPercentage +
                        '%'
                      );
                    },
                  },
                },
              }}
            />
          </Col>
          <Col span={12}>
            <Pie
              data={pieMarcas}
              options={{
                title: {
                  display: this.props.displayTitle,
                  text: 'Marcas (% Ventas)',
                  fontSize: 21,
                },
                legend: {
                  display: this.props.displayLegend,
                  position: this.props.legendPosition,
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var allData =
                        data.datasets[tooltipItem.datasetIndex].data;
                      var tooltipLabel = data.labels[tooltipItem.index];
                      var tooltipData = allData[tooltipItem.index];
                      var total = 0;
                      for (var i in allData) {
                        total += allData[i];
                      }
                      var tooltipPercentage = (
                        (tooltipData / total) *
                        100
                      ).toFixed(2);
                      return (
                        tooltipLabel +
                        ': $' +
                        tooltipData +
                        ' | ' +
                        tooltipPercentage +
                        '%'
                      );
                    },
                  },
                },
              }}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <Pie
              onElementsClick={(elems) => {
                this.handlePieClick(elems[0]._chart.data.pk[elems[0]._index]);
              }}
              data={pieCategorias}
              options={{
                title: {
                  display: this.props.displayTitle,
                  text: 'Categorías (% Ventas)',
                  fontSize: 21,
                },
                legend: {
                  display: this.props.displayLegend,
                  position: this.props.legendPosition,
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var allData =
                        data.datasets[tooltipItem.datasetIndex].data;
                      var tooltipLabel = data.labels[tooltipItem.index];
                      var tooltipData = allData[tooltipItem.index];
                      var total = 0;
                      for (var i in allData) {
                        total += allData[i];
                      }
                      var tooltipPercentage = (
                        (tooltipData / total) *
                        100
                      ).toFixed(2);
                      return (
                        tooltipLabel +
                        ': $' +
                        tooltipData +
                        ' | ' +
                        tooltipPercentage +
                        '%'
                      );
                    },
                  },
                },
              }}
            />
          </Col>
          <Col span={12}>
            <Pie
              data={piePromociones}
              options={{
                title: {
                  display: this.props.displayTitle,
                  text: 'Promociones (# Veces)',
                  fontSize: 21,
                },
                legend: {
                  display: this.props.displayLegend,
                  position: this.props.legendPosition,
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var allData =
                        data.datasets[tooltipItem.datasetIndex].data;
                      var tooltipLabel = data.labels[tooltipItem.index];
                      var tooltipData = allData[tooltipItem.index];
                      var total = 0;
                      for (var i in allData) {
                        total += allData[i];
                      }
                      var tooltipPercentage = (
                        (tooltipData / total) *
                        100
                      ).toFixed(2);
                      return (
                        tooltipLabel +
                        ': $' +
                        tooltipData +
                        ' | ' +
                        tooltipPercentage +
                        '%'
                      );
                    },
                  },
                },
              }}
            />
          </Col>
        </Row>
        {this.state.tienda === 'V' && (
          <>
            <Divider />
            <Row>
              <Col span={12}>
                <Pie
                  data={pieSabores}
                  options={{
                    title: {
                      display: this.props.displayTitle,
                      text: 'Sabores (% Ventas)',
                      fontSize: 21,
                    },
                    legend: {
                      display: this.props.displayLegend,
                      position: this.props.legendPosition,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          var allData =
                            data.datasets[tooltipItem.datasetIndex].data;
                          var tooltipLabel = data.labels[tooltipItem.index];
                          var tooltipData = allData[tooltipItem.index];
                          var total = 0;
                          for (var i in allData) {
                            total += allData[i];
                          }
                          var tooltipPercentage = (
                            (tooltipData / total) *
                            100
                          ).toFixed(2);
                          return (
                            tooltipLabel +
                            ': $' +
                            tooltipData +
                            ' | ' +
                            tooltipPercentage +
                            '%'
                          );
                        },
                      },
                    },
                  }}
                />
              </Col>
              <Col span={12}>
                <Pie
                  data={pieNicotina}
                  options={{
                    title: {
                      display: this.props.displayTitle,
                      text: 'Nicotina (% Ventas)',
                      fontSize: 21,
                    },
                    legend: {
                      display: this.props.displayLegend,
                      position: this.props.legendPosition,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          var allData =
                            data.datasets[tooltipItem.datasetIndex].data;
                          var tooltipLabel = data.labels[tooltipItem.index];
                          var tooltipData = allData[tooltipItem.index];
                          var total = 0;
                          for (var i in allData) {
                            total += allData[i];
                          }
                          var tooltipPercentage = (
                            (tooltipData / total) *
                            100
                          ).toFixed(2);
                          return (
                            tooltipLabel +
                            ': $' +
                            tooltipData +
                            ' | ' +
                            tooltipPercentage +
                            '%'
                          );
                        },
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        {this.state.tienda === 'A' && (
          <>
            <Divider />
            <Row>
              <Col span={12}>
                <Pie
                  data={pieModelos}
                  options={{
                    title: {
                      display: this.props.displayTitle,
                      text: 'Modelos (% Ventas)',
                      fontSize: 21,
                    },
                    legend: {
                      display: this.props.displayLegend,
                      position: this.props.legendPosition,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          var allData =
                            data.datasets[tooltipItem.datasetIndex].data;
                          var tooltipLabel = data.labels[tooltipItem.index];
                          var tooltipData = allData[tooltipItem.index];
                          var total = 0;
                          for (var i in allData) {
                            total += allData[i];
                          }
                          var tooltipPercentage = (
                            (tooltipData / total) *
                            100
                          ).toFixed(2);
                          return (
                            tooltipLabel +
                            ': $' +
                            tooltipData +
                            ' | ' +
                            tooltipPercentage +
                            '%'
                          );
                        },
                      },
                    },
                  }}
                />
              </Col>

              <Col span={12}></Col>
            </Row>
          </>
        )}
        <Divider />
        <Row>
          <Col span={24}>
            <div style={{ textAlign: 'center', paddingBottom: '1em' }}>
              <i>
                Los datos de abajo solamente cambian con los filtros de fecha.
              </i>
              <h3 style={{ margin: '-0.25em' }}>
                Cuentas por cobrar:{' '}
                {this.state.CuentasPorCobrar.toLocaleString()}
              </h3>
              <i>De ventas del periodo.</i>

              <h3 style={{ marginTop: '0.5em', marginBottom: '-0.25em' }}>
                Ingresos netos: {this.state.facturacionReal.toLocaleString()}
              </h3>
              <i>Por venta de productos en el periodo.</i>
              <p style={{ marginTop: '0.25em' }}>
                <b>Total Ingresos del periodo:</b>{' '}
                {ingresosPositivos.toLocaleString()} |{' '}
                <b>Devoluciones del periodo:</b>{' '}
                {ingresosNegativos.toLocaleString()}
              </p>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    ) : (
      <div style={{ margin: 'auto', maxWidth: dimension, textAlign: 'center' }}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <img src={Logo} alt="Vapes logo" width={dimension}></img>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    perms: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  let path_length_check = window.location.pathname.length;
  let url_param = path_length_check < 2 ? '' : '/reportes/ventas/';
  let nombre_param = path_length_check < 2 ? 'Inicio' : 'Reporte de Ventas';
  return {
    onVisit: () => dispatch(newPage(url_param, nombre_param, '', '', 1)),
    //link1, label 1, link 2, label 2
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chart));
