import * as actionTypes from "../actions/actionTypes";
//import { updateObject } from "../utility";

const initialState = {
    pattern: '',
    recepcionEquipoObj: {},
    recepcionEquipoPreDataObj: {}, //data format to show the data on antd checkboxes
    recepcionEquipo: 'Invalido',
    conRecepcion: false,
    isValidDataRecepcion: false,
    //proveedor: '', comentado para que salga el placeholder en el select, en lugar de '' vacío
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_PATTERN:
            return {
                ...state,
                pattern: action.payload
            };
        case actionTypes.CHANGE_RECEPCION_EQUIPO:
            return {
                ...state,
                recepcionEquipoObj: action.payload
            };
        case actionTypes.RECEPCION_EQUIPO_ID:
            return {
                ...state,
                recepcionEquipo: action.payload
            };
        case actionTypes.CON_RECEPCION:
            return {
                ...state,
                ...{ conRecepcion: !state.conRecepcion } 
            };
        case actionTypes.SAVE_RECEPCION_FIELDS_DATA:
            return {
                ...state,
                recepcionEquipoPreDataObj: action.payload
            };
        case actionTypes.IS_VALID_DATA_RECEPCION:
            return {
                ...state,
                ...{ isValidDataRecepcion: action.payload }
            };
        default:
            return state;
    }
};

export default reducer;