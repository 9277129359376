import React from 'react';
import { clienteURL } from '../../../constants';
import { Button, Divider, Form, Input, message } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { newPage } from "../../../store/actions/breadcrumbs";
import { withRouter } from "react-router-dom";
import * as actions from "./../../../store/actions/clientes"

class Cliente extends React.Component {
    componentDidMount() {
        this.props.onVisit();
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                console.log(clienteURL)
                axios
                    .post(clienteURL, values)
                    .then(res => {
                        console.log(res)
                        console.log(values, 'values')
                        if (this.props.showSelect) { //dependiendo de la página en que estes te redirige o no
                            this.props.history.push(`/cliente/${res.data.id}`);
                        } else if (!this.props.showSelect) {
                            this.props.onChangeCliente(res.data.id)
                            this.props.onClickToogle()
                            this.props.continuar()
                        }

                        message.success('¡Se agregó al cliente ' + values.nombre + ' con éxto!');
                    })
                    .catch(err => {
                        console.log('errorrrr', err)
                        // let array_errores = Object.values(err.response.data)
                        // array_errores.forEach(e => {
                        //     message.error(e[0])
                        // });
                    });
            }
        });
    };

    render() {
        const { showSelect } = this.props
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                {(showSelect === true) ?
                    <div>
                        <h3 style={{ textAlign: 'left' }}>Agregar Cliente</h3>
                        <Divider />
                    </div>
                    : ''}
                <div style={{ margin: 'auto', maxWidth: '400px', textAlign: 'center' }}>
                    <Form layout={'vertical'} labelCol={null} onSubmit={this.handleSubmit}>
                        <Form.Item label="Nombre" >
                            {getFieldDecorator('nombre', {
                                rules: [{ required: true, message: 'Por favor ingresa el nombre del cliente!' }],
                            })(<Input maxLength={120} />)}
                        </Form.Item>
                        <Form.Item label="Cel/Tel" >
                            {getFieldDecorator('tel', {
                            })(<Input maxLength={40} />)}
                        </Form.Item>
                        <Form.Item label="Email" >
                            {getFieldDecorator('email', {
                            })(<Input maxLength={120} />)}
                        </Form.Item>
                        <Form.Item label="Comentarios">
                            {getFieldDecorator('comentarios', {
                            })(<Input.TextArea maxLength={350} style={{ height: 100 }} />)}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Agregar Cliente
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/cliente/', 'Clientes', '/agregar-cliente/', 'Agregar Cliente', 2)),
        //link1, label 1, link 2, label 2
        onChangeCliente: (value) => dispatch(actions.changeCliente(value))
    }
}

const WrappedApp = Form.create({ name: 'cliente' })(Cliente);
export default withRouter(connect(null, mapDispatchToProps)(WrappedApp));