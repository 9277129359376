import React from 'react';
import { proveedorDetailURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { Card, Col, Divider, Row } from 'antd';
import { Link } from 'react-router-dom'

class DetalleProveedor extends React.Component {
    state = {
        data: []
    };

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.onVisit(params)
        console.log(params)
        axios
            .get(proveedorDetailURL(params.proveedorID))
            .then(res => {
                console.log(res.data)
                this.setState({ data: res.data });
            })
            .catch(err => {
                console.log(err)
                this.setState({ error: err });
            });
    }

    render() {
        const { data } = this.state
        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Detalle Proveedor</h3>
                <Divider />
                <Row gutter={16}>
                    <Col span={8}>
                    </Col>
                    <Col span={8}>
                        <Card title={data.nombre} extra={<Link to="editar/">editar</Link>} style={{ width: 400, textAlign: 'center' }}>
                            <p><b>ID:</b> {data.id}</p>
                            <p><b>Identificador:</b> {data.identificador}</p>
                            <p><b>Comentarios: </b> {data.comentarios}</p>
                            <p></p>
                        </Card>
                    </Col>
                    <Col span={8}>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/proveedores/', 'Proveedores', `/proveedores/${params.proveedorID}/`, 'Detalle Proveedor', 2))
        //link1, label 1, link 2, label 2
    }
}

export default connect(null, mapDispatchToProps)(DetalleProveedor);