import * as actionTypes from './actionTypes';
import axios from 'axios';
import { productosListURL, promocionesListURL } from './../../constants';

export const handleSelectChangeProd = (value) => {
  return {
    type: actionTypes.HANDLE_SELECT_PRODUCTO,
    payload: value,
  };
};

export const handleSelectChangePromo = (value) => {
  return {
    type: actionTypes.HANDLE_SELECT_PROMO,
    payload: value,
  };
};

export const concatProductoVenta = (value) => {
  return {
    type: actionTypes.CONCAT_PRODUCTO_VENTA,
    payload: value,
  };
};

export const deleteProductoVenta = (value) => {
  return {
    type: actionTypes.DELETE_ROW_PRODUCTO_VENTA,
    payload: value,
  };
};

export const changeProductoVenta = (value) => {
  return {
    type: actionTypes.CHANGE_PRODUCTO_VENTA_STATE,
    payload: value,
  };
};

export const changePedidoProducto = (value) => {
  return {
    type: actionTypes.CHANGE_PEDIDO_PRODUCTO_STATE,
    payload: value,
  };
};

export const changeGarantiaProducto = (value) => {
  return {
    type: actionTypes.CHANGE_GARANTIA_PRODUCTO_STATE,
    payload: value,
  };
};

export const resetPedidoProducto = () => {
  return {
    type: actionTypes.RESET_PRODUCTOS_STATE,
  };
};

export const changePromosArray = (value) => {
  return {
    type: actionTypes.CHANGE_PROMOS_ARRAY_STATE,
    payload: value,
  };
};

export const loadPromoData = () => {
  return (dispatch) => {
    axios
      .get(promocionesListURL)
      .then((res) => {
        let promociones = res.data.map((p) => {
          return {
            key: p.id,
            id: p.id,
            nombre: p.nombre,
          };
        });
        dispatch({
          type: actionTypes.LOAD_PROMO_DATA,
          payload: promociones,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: err,
        });
      });
  };
};

export const loadProductosData = (id, sonGarantias, tienda) => {
  //solo tengo que pasar 'Garantias' como segundo arg, si quiero ver garntías
  return (dispatch) => {
    let getURL =
      parseInt(id) > 0
        ? productosListURL + '?proveedor=' + id
        : productosListURL;
    let getURLcheckGarantias =
      sonGarantias === 'Garantias' ? getURL + '&garantias=si' : getURL;
    if (tienda) {
      //parche para solo cargar productos de una tienda (se usa en la venta nueva). Tengo que pasar 0 como el primer arg
      getURLcheckGarantias = productosListURL + '?tienda=' + tienda;
    }
    axios
      .get(getURLcheckGarantias)
      .then((res) => {
        let productos = res.data.map((prod) => {
          return {
            key: prod.id,
            id: prod.id,
            nombre: prod.nombre,
            stock: prod.stock,
            garantias: prod.garantias,
          };
        });
        dispatch({
          type: actionTypes.LOAD_PRODUCTOS_DATA,
          payload: productos,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: err,
        });
      });
  };
};

export const calcularValorTotal = (value) => {
  return {
    type: actionTypes.CALCULAR_VALOR_TOTAL,
    payload: value,
  };
};
