import React, { Component } from 'react';
import { BalanceAlDiaURL } from '../../constants';
import { Table } from 'antd';

export class TotalIngresosDia extends Component {
  fetchData = () => {
    this.props.load(
      BalanceAlDiaURL +
        '?fecha=' +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
          .toISOString()
          .substring(0, 10) +
        '&tienda=' +
        this.props.tienda
    );
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tienda !== prevProps.tienda) {
      this.fetchData();
    }
  }

  render() {
    const columns = [
      {
        title: 'Forma de Pago',
        dataIndex: 'forma_de_pago',
        key: 'forma_de_pago',
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        align: 'left',
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.data}
          bordered={true}
          pagination={false}
          size="middle"
        />
      </div>
    );
  }
}

export default TotalIngresosDia;
