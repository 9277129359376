import React, { Component } from 'react';
import ProveedorComp from "../Proveedores/AgregarProveedor"
import { Button, Select, Icon } from 'antd';
import * as actions from "./../../store/actions/proveedores"
import * as actionsProductos from "./../../store/actions/productos"
import { connect } from 'react-redux';
const { Option } = Select;

export class Proveedor extends Component {
    state = {
        loading: false,
        error: null,
        showSelect: true,
    }

    componentDidMount() {
        if (this.props !== undefined) {
            this.props.onFetchProveedores()
        }
    }

    onClickToogle = (e) => {
        this.setState({
            ...this.state,
            ...{ showSelect: !this.state.showSelect }
        })
    }

    onChangeProveedorAndReset = (value) => {
        this.props.onChangeProveedor(value)
        this.props.onResetPedidoProducto()
    }

    render() {
        const { showSelect } = this.state;
        const { proveedores, proveedor, noncreate } = this.props;
        return (

            <React.Fragment>
                {proveedores !== undefined &&
                    <div style={{ textAlign: 'center' }}>
                        {(noncreate === 'true' ? null :
                            <React.Fragment>
                                <Button onClick={this.onClickToogle} type="primary">{showSelect ? <Icon type="user-add" /> : <Icon type="search" />}</Button>
                                <br></br>
                                <br></br>
                            </React.Fragment>
                        )}
                        {showSelect ? (
                            <Select
                                showSearch
                                style={{ width: 400 }}
                                placeholder="Proveedores"
                                optionFilterProp="children"
                                onChange={this.onChangeProveedorAndReset}
                                value={proveedor}
                            >
                                {
                                    proveedores.map(p => {
                                        return <Option key={p.id} value={p.id}>{p.nombre}</Option>
                                    })
                                }

                            </Select>
                        ) :
                            <ProveedorComp desdePedido={this.props.desdePedido} showSelect={showSelect} onClickToogle={this.onClickToogle} continuar={() => this.props.nextStep()}></ProveedorComp>
                        }
                    </div>
                }
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        proveedores: state.proveedor.proveedores,
        proveedor: state.proveedor.proveedor,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchProveedores: () => dispatch(actions.loadProveedoresData()),
        onChangeProveedor: (value) => dispatch(actions.changeProveedor(value)),
        onResetPedidoProducto: () => dispatch(actionsProductos.resetPedidoProducto())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Proveedor);