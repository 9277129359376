import React, { Component } from 'react';
import { Button, Icon, InputNumber, Select, Statistic, Table, message } from 'antd';
import { connect } from 'react-redux';
import { productoDetailURL } from './../../constants';
import * as actions from "./../../store/actions/productos";
import axios from 'axios';
const { Option } = Select;

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Marca',
        dataIndex: 'marca',
        key: 'marca',
    },
    {
        title: '# de Garantías a enviar',
        dataIndex: 'cantidad',
        key: 'cantidad',
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
    },
];

export class PedidoProductoForm extends Component {
    componentDidMount() {
        this.props.onFetchProductos(this.props.proveedor)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.productosGarantia !== prevProps.productosGarantia) { //State is passed as props with redux
            this.calculateTotal() //ir worked on async functions like "handleOnClick" but not when it wasn't async like on the delete handlers
        } //That is why it had to be done here
    }

    handleOnClickPedido = () => {
        axios
            .get(productoDetailURL(this.props.producto))
            .then(res => {
                let productoA = res.data
                productoA["cantidad"] = 1;
                const { productosGarantia } = this.props
                let no_existe0 = true
                productosGarantia.map(p => {
                    if (productoA.id === p.id || productoA.length === 0) { //check if id is in productosVenta table or if the table is empty
                        message.error('El producto ya esta en el listado');
                        no_existe0 = false
                        return false
                    }
                    return 'I went though all prods and none are repeated'
                });
                if (no_existe0) {
                    let new_state = this.props.productosGarantia.concat(productoA)
                    this.props.onChangeGarantiaProducto(new_state)
                }
            })
            .catch(err => {
                console.log(err);
                message.error('Hubo un error. Recuerda seleccionar un producto.');
                this.setState({ error: err, loading: false });
            });
    };

    handleChange = (nombre, id, e) => { //el nombre es la cantidad o el precio
        let { productosGarantia } = this.props;
        let tipo_nombre = (nombre === 'costo' ? 0 : 1) //NaN BUG: dejar en cero el precio mínimo o en uno la cantidad mínima -cuando quitas el cursor del input- deja NaN si no pones un valor y eso causa un error, por eso onblur le pone o 0 al precio o 1 a la cantidad que es el minimo
        let new_state = productosGarantia.map(prod => {
            if (prod.id === id) {
                //prod[nombre] = parseFloat(nombre === 'cantidad' ? e : e.target.value)
                prod[nombre] = parseFloat(e === '' || e === null || isNaN(e) ? tipo_nombre : e) //InputNumber de antd, pasa el valor en e, no en e.target.value
            }
            return prod
        })

        this.props.onChangeGarantiaProducto(new_state)
    };

    onClickDeleteRowHandler = value => {
        let new_state = this.props.productosGarantia.filter(prod => prod.id !== value)
        this.props.onChangeGarantiaProducto(new_state)
    }

    calculateTotal = () => {
        let { productosGarantia, onCalcularValorTotal } = this.props;
        let values = productosGarantia.map(prod => {
            let tot = parseInt(prod.cantidad)
            return tot
        })
        function sumVals(total, num) {
            return total + num;
        }
        let valor_tot = (values.length === 0 ? 0 : values.reduce(sumVals))
        onCalcularValorTotal(valor_tot)
    };

    render() {
        const { productos, producto, productosGarantia, valorTotal, onHandleSelectProd } = this.props;
        //para dar submit y q los obj PeridoProducto tengan los mismos fields, tendría que usar productoA_V2 pero tendria que hacer un nested map en PedidoProductoForm [{1: {prod, costo, cantidad}}, {2: {prod, costo, cantidad}}]
        let productosB = productosGarantia.map(prod => {
            let input_cantidad = <React.Fragment><InputNumber onChange={(e) => this.handleChange('cantidad', prod.id, e)} type="number" style={{ width: '50%' }} defaultValue={prod.cantidad} min={1} max={prod.garantias}/> <span style={{ marginLeft: 10, ...(prod.stock > 2 ? { color: 'green' } : { color: 'red' }), }}> Stock Garantías: {prod.garantias}</span></React.Fragment>
            let delete_row = (
                <Button onClick={() => this.onClickDeleteRowHandler(prod.id)}>
                    <Icon type="delete" theme="filled" style={{ color: 'red' }} />
                </Button>
            )
            return (
                {
                    key: prod.id,
                    id: prod.id,
                    marca: prod.marca.nombre,
                    nombre: prod.nombre,
                    cantidad: input_cantidad,
                    delete: delete_row,
                }
            )
        });

        return (
            <React.Fragment>
                <div style={{ textAlign: 'center' }}>
                    <Table locale={{ emptyText: 'No hay productos seleccionados' }} size={'middle'} columns={columns} dataSource={productosB} bordered={true} pagination={false} />
                    <br></br>
                    <Select
                        showSearch
                        style={{ width: 400 }}
                        placeholder="Buscar producto"
                        optionFilterProp="children"
                        onChange={onHandleSelectProd}
                        value={producto}
                        allowClear={true}
                        autoFocus={true}
                    >
                        {
                            productos.map(p => {
                                return <Option key={p.id} value={p.id}>{p.nombre} {p.id} ({p.garantias})</Option>
                            })
                        }
                    </Select>
                    <Button
                        type="primary"
                        style={{ margin: '0.5em' }}
                        onClick={this.handleOnClickPedido}>
                        <Icon type="plus" />
                    </Button>
                    <br></br>
                    <br></br>
                    <Statistic title="Total a enviar" suffix={"Piezas"} value={valorTotal} />
                    <br></br>
                    <Button.Group>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={this.props.prevStep}>
                            <Icon type="left" /> Regresar
                </Button>

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.props.nextStep}>
                            Continuar <Icon type="right" />
                        </Button>
                    </Button.Group>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        productos: state.producto.productos,
        producto: state.producto.producto,
        valorTotal: state.producto.valorTotal,
        proveedor: state.proveedor.proveedor,
        productosGarantia: state.producto.productosGarantia,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchProductos: (id) => dispatch(actions.loadProductosData(id, 'Garantias')),
        onHandleSelectProd: (val) => dispatch(actions.handleSelectChangeProd(val)),
        onChangeGarantiaProducto: (new_state) => dispatch(actions.changeGarantiaProducto(new_state)),
        onCalcularValorTotal: (val) => dispatch(actions.calcularValorTotal(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PedidoProductoForm);
