import React, { Component } from 'react';
import { PagosURL } from '../../constants';
import { Table, Tag } from 'antd';
import { Link } from 'react-router-dom';

export class OtrosPagos extends Component {
  fetchData = () => {
    this.props.load(
      PagosURL +
        '?fecha=' +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
          .toISOString()
          .substring(0, 10) +
        '&tienda=' +
        this.props.tienda
    );
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tienda !== prevProps.tienda) {
      this.fetchData();
    }
  }

  render() {
    const columns = [
      {
        title: 'Venta ID',
        dataIndex: 'venta',
        key: 'venta',
        render: (text, record) =>
          record.venta === 'N/A' ? (
            'N/A'
          ) : (
            <Link to={'/ventas/' + record.venta + '/'}>{text}</Link>
          ),
      },
      {
        title: 'Forma de Pago',
        dataIndex: 'forma_de_pago',
        key: 'forma_de_pago',
        render: (text, record) => (
          <Link to={'/pagos/' + record.key + '/'}>{text}</Link>
        ),
      },
      {
        title: 'Concepto',
        dataIndex: 'concepto',
        key: 'concepto',
      },
      {
        title: 'Monto',
        dataIndex: 'monto',
        key: 'monto',
        align: 'center',
        render: (tag) => {
          let color = tag.includes('-') ? 'magenta' : 'green';
          return (
            <Tag color={color} key={tag}>
              {tag}
            </Tag>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <Table
          columns={columns}
          dataSource={this.props.data}
          bordered={true}
          pagination={false}
          locale={{ emptyText: 'No hay otros pagos en esta fecha.' }}
          size="middle"
        />
      </React.Fragment>
    );
  }
}

export default OtrosPagos;
