import React, { Component } from 'react';
import { Button, Input, Icon, Statistic, Table, message } from 'antd';
import axios from "axios";
import { proveedorDetailURL, solicitudGarantiaURL } from '../../constants';
import { connect } from 'react-redux';
import * as actionsProveedor from "./../../store/actions/proveedores";

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Marca',
        dataIndex: 'marca',
        key: 'marca',
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
    }
];

export class Confirm extends Component {

    state = {
        nombreProv: ''
    }
    componentDidMount() {
        const { proveedor } = this.props
        if (proveedor !== undefined) {
            //SI SE PUEDE: .get(proveedorDetailURL + proveedor + '/') 
            //NO SE PUEDE: .get(proveedorDetailURL, proveedor) Es una función, get no acepta parametros(creo) y por eso me daba errores
            axios
                .get(proveedorDetailURL(proveedor)) // MANERA OPTIMA Y CORRECTA
                .then(res => {
                    console.log(res.data.nombre)
                    this.setState({
                        nombreProv: res.data.nombre
                    });
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    handleChange = (e) => {
        this.props.onHandleChangedComentarios(e.target.value)
    };

    handleSubmit = () => {
        const { productosGarantia, proveedor, comentarios, history } = this.props
        axios
            .post(solicitudGarantiaURL, {
                productosGarantia,
                proveedor,
                comentarios
            })
            .then(res => {
                history.replace(`/solicitud-de-garantias/`);
                this.props.onSubmitResetReduxState()
                message.success('Las garantías fueron enviadas con éxito.');
            })
            .catch(err => {
                console.log(err)
                message.error(err.response.data.message);
            });
    }

    render() {
        const { productosGarantia, comentarios, valorTotal } = this.props;
        const { nombreProv } = this.state

        let counter = 0
        let productosB = productosGarantia.map(prod => {
            counter += 65;
            return (
                {
                    key: prod.id + counter, //unique key
                    id: prod.id,
                    marca: prod.marca.nombre,
                    nombre: prod.nombre,
                    cantidad: prod.cantidad,
                }
            )
        });
        return (
            <React.Fragment>
                <div style={{ textAlign: 'center' }}>
                    <Table locale={{ emptyText: 'No hay garantias seleccionadas' }} size={'small'} columns={columns} dataSource={productosB} bordered={true} pagination={false} />
                    <br></br>
                    <h3>Proveedor: {nombreProv}</h3>
                    <Input.TextArea placeholder="Comentarios" onChange={(e) => this.handleChange(e)} maxLength={300} style={{ height: 50, maxWidth: '450px' }} value={comentarios} />
                    <br></br>
                    <br></br>
                    <Statistic title="Total a enviar" suffix={"Piezas"} value={valorTotal} />
                    <br></br>
                    <Button.Group>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={this.back}>
                            <Icon type="left" /> Regresar
                        </Button>

                        <Button
                            type="primary"
                            variant="contained"
                            onClick={this.handleSubmit}>
                            Confirmar Envío <Icon type="save" />
                        </Button>
                    </Button.Group>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        proveedor: state.proveedor.proveedor,
        comentarios: state.proveedor.comentarios,
        productosGarantia: state.producto.productosGarantia,
        valorTotal: state.producto.valorTotal,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onHandleChangedComentarios: (val) => dispatch(actionsProveedor.handleChangedComentarios(val)),
        onSubmitResetReduxState: () => dispatch({ type: 'RESET_REDUX_STATE' })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Confirm);