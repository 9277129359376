import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Input,
  Icon,
  Statistic,
  Row,
  Table,
  message,
} from 'antd';
import {
  DetalleClienteURL,
  ventaNuevaURL,
  recepcionEquipoURL,
} from '../../../constants';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actionsClientes from './../../../store/actions/clientes';
import * as actionsRecepciones from './../../../store/actions/recepciones';

import Promocion from './../Promocion';

const columnsProd = [
  {
    title: 'Producto',
    dataIndex: 'nombre',
    key: 'nombre',
  },
  {
    title: 'Cantidad',
    dataIndex: 'cantidad',
    key: 'cantidad',
  },
  {
    title: 'Precio',
    dataIndex: 'precio',
    key: 'precio',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
];

const columnsPago = [
  {
    title: 'Forma de Pago',
    dataIndex: 'nombre',
    key: 'nombre',
  },
  {
    title: 'Importe',
    dataIndex: 'importe',
    key: 'importe',
  },
];

export class Confirm extends Component {
  state = {
    nombreCliente: '',
  };
  componentDidMount() {
    const { cliente } = this.props;
    // console.log(this.props.recepcionEquipoObj)
    if (cliente !== undefined) {
      axios
        .get(DetalleClienteURL(cliente)) //paso asi al cliente porque sino al enseñar su nombre solo me enseña el ID
        .then((res) => {
          this.setState({
            ...this.state,
            ...{ nombreCliente: res.data.nombre },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleChange = (e) => {
    this.props.onHandleChangedComentarios(e.target.value);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
    // console.log('this.state.nombreCliente', this.state.nombreCliente === '')
  };

  onChangeCbx = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  // componentDidUpdate(prevProps, prevState) {
  //     if (this.props.recepcionEquipo !== prevProps.recepcionEquipo) {
  //         this.pro
  //     }
  // }

  handleSubmit = async () => {
    const {
      cliente,
      recepcionEquipoObj,
      isValidDataRecepcion,
      productosVenta,
      formasArray,
      comentarios,
      promosArray,
      internet,
      history,
      onRecepcionEquipoID,
      conRecepcion,
    } = this.props;
    if (cliente === 'Buscar cliente...' || cliente === '') {
      this.props.firstStep();
      message.error('Seleciona a un cliente', 5);
      return;
    }
    if (conRecepcion && !isValidDataRecepcion) {
      this.props.firstStep();
      message.error(
        'Completa la Recepción del equipo, sino se recibe ningún equipo da clic en el botón azul "CON Recepción de equipo", no olvides dar clic en "Guardar Recepción" ',
        12
      );
      return;
    }
    if (productosVenta.length === 0) {
      message.error('Debes de completar el campo: Productos.');
      return;
    }

    if (promosArray.length === 0) {
      message.error(
        'Debes de seleccionar las promociones que aplicaron, si no hubo selecciona NO HUBO PROMOCIÓN.',
        7
      );
      return;
    }

    if (conRecepcion && isValidDataRecepcion) {
      //conRecepcion === true
      await axios //tuve que esperar con await sino se seguia a la sigueitne async call
        .post(recepcionEquipoURL, recepcionEquipoObj)
        .then((res) => {
          //console.log(res.data.id, 'RESPONSE ID')
          onRecepcionEquipoID(res.data.id);
        })
        .catch((err) => {
          console.log(err.response);
          message.error(
            'Hay un problema con la recepción el equipo Confirm.js'
          );
        });
    }
    //console.log(conRecepcion, 'conRecepcion');
    //console.log(this.props.recepcionEquipo, 'recepcionEquipo');
    await axios
      .post(ventaNuevaURL, {
        cliente,
        recepcion_equipo: this.props.recepcionEquipo, //Se usa directo de this.props, sino carga los props antes de que se cambie.
        productosVenta,
        formasArray,
        comentarios,
        internet,
        promosArray,
        tienda: this.props.tienda,
      })
      .then((res) => {
        //console.log(res)
        history.replace(`/ventas/`); //tengo que pasar history como props porque "Confirm.js" no es parte deel Router como tal, solo "VentaForm.js"
        this.props.onSubmitResetReduxState();
        message.success('La venta fue registrada con éxito.', 5);
        window.open(
          `https://b.arreglatupantalla.com/api/ticket-intranet/${res.data}/`,
          '_blank'
        );
      })
      .catch((err) => {
        //console.log(err.response, 'ERRRROR')
        message.error(err.response.data.message);
      });
  };

  render() {
    const {
      productosVenta,
      formasArray,
      totalFormasDePago,
      valorTotal,
      comentarios,
      conRecepcion,
    } = this.props;

    let productosB = productosVenta.map((prod) => {
      let total_row = parseFloat(prod.cantidad * prod.precio);
      return {
        key: prod.id,
        id: prod.id,
        nombre: prod.nombre,
        cantidad: prod.cantidad,
        precio: prod.precio,
        total: total_row,
      };
    });

    let formasB = formasArray.map((forma) => {
      return {
        key: forma.id,
        id: forma.id,
        nombre: forma.nombre,
        importe: forma.importe,
      };
    });

    const totalesString = totalFormasDePago + ' / ' + valorTotal;
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col span={11} style={{ textAlign: 'center' }}>
            {/* <h3>Cliente: {(this.state.nombreCliente === '' ? 'No hay cliente seleccionado' : this.state.nombreCliente)}</h3> */}
            <h3>
              Cliente:{' '}
              {this.state.nombreCliente === ''
                ? 'No hay cliente seleccionado'
                : this.state.nombreCliente}
            </h3>
            <Checkbox onChange={this.props.onChangeCbx}>
              ¿Venta por Internet?
            </Checkbox>
            <br></br>
            <br></br>
            <Input.TextArea
              placeholder="Comentarios..."
              onChange={(e) => this.handleChange(e)}
              maxLength={300}
              style={{ height: 50 }}
              value={comentarios}
            />
            <br></br>
            <br></br>
            <Promocion text="Selecciona las promociones que aplicaron" />
            <br></br>
            <br></br>
            <Statistic
              title="Total"
              prefix={'MX'}
              value={totalesString}
              precision={2}
            />
            <br></br>
            <Button.Group>
              <Button color="secondary" variant="contained" onClick={this.back}>
                <Icon type="left" /> Regresar
              </Button>

              <Button
                type="primary"
                color="primary"
                variant="contained"
                onClick={this.handleSubmit}
              >
                Confirmar Venta <Icon type="tag" />
              </Button>
            </Button.Group>
          </Col>
          <Col span={2}></Col>
          <Col span={11} style={{ textAlign: 'center' }}>
            <Table
              title={() => 'Productos'}
              columns={columnsProd}
              dataSource={productosB}
              bordered={true}
              pagination={false}
              size="small"
              locale={{ emptyText: 'No hay productos seleccionados' }}
            />
            <br></br>
            <Table
              title={() => 'Pagos'}
              columns={columnsPago}
              dataSource={formasB}
              bordered={true}
              pagination={false}
              size="small"
              locale={{ emptyText: 'No hay formas de pago seleccionadas' }}
            />
            <br></br>
            {conRecepcion && (
              <h1 style={{ color: '#52c41a' }}>
                Recibiendo equipo: <Icon type="tablet" /> <span></span>{' '}
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
              </h1>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cliente: state.cliente.cliente,
    comentarios: state.cliente.comentarios,
    productosVenta: state.producto.productosVenta,
    valorTotal: state.producto.valorTotal,
    formasArray: state.pago.formasArray,
    promociones: state.producto.promociones,
    promosArray: state.producto.promosArray,
    promocionSelec: state.producto.promocionSelec,
    totalFormasDePago: state.pago.totalFormasDePago,
    recepcionEquipoObj: state.recepcion.recepcionEquipoObj,
    recepcionEquipo: state.recepcion.recepcionEquipo,
    conRecepcion: state.recepcion.conRecepcion,
    isValidDataRecepcion: state.recepcion.isValidDataRecepcion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHandleChangedComentarios: (val) =>
      dispatch(actionsClientes.handleChangedComentarios(val)),
    onRecepcionEquipoID: (val) =>
      dispatch(actionsRecepciones.recepcionEquipoID(val)),
    onSubmitResetReduxState: () => dispatch({ type: 'RESET_REDUX_STATE' }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
