import React from 'react';
import { VentaDetailURL, ventaEditarURL, DevGarVentaProductoURL, productosListURL, ventaNuevaURL, recepcionEquipoUpdateURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import VentaProductoForm from './NuevaVenta/VentaProductoForm';
import Promocion from './Promocion';
import * as actions from "../../store/actions/productos"
import { Icon as IconUI } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ModalPago from './../Pagos/ModalPago';
import RecepcionEquipo from './../Reparaciones/RecepcionEquipo';
import { Button, Divider, Form, Table, Input, Icon, message, Modal, InputNumber, Tag, Select, Tooltip, Badge } from 'antd';
const { Option } = Select;

const columnsPromos = [
    {
        title: 'ID Promo',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Promoción',
        dataIndex: 'nombre',
        key: 'nombre',
    },
];

const columnsProd = [
    {
        title: 'Producto',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
    },
    {
        title: 'Precio',
        dataIndex: 'precio',
        key: 'precio',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
    },
    {
        title: 'Fecha Venta',
        dataIndex: 'fecha',
        key: 'fecha',
    },
    {
        title: '',
        dataIndex: 'botones',
        key: 'botones',
    },
];

const columnsDevoluciones = [
    {
        title: 'Producto',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
    },
    {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
    },
];

const columnsGarantias = [
    {
        title: 'Producto',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
    },
    {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
    },
]

const columnsPago = [
    {
        title: 'Forma de Pago',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Importe',
        dataIndex: 'importe',
        key: 'importe',
    },
    {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
    },
];

class VentaDetail extends React.Component {
    state = {
        data: null, //data es el object de la venta
        visibleDevol: false,
        visibleGarantia: false,
        visibleProductos: false,
        visibleReparacion: false,
        clickedVentaProd: '',
        cantidad: 1,
        monto: null,
        //formaSelec: 'asdf',
        formasDePago: [],
        productos: [],
        productosVenta: [],
        promos: []
    };

    loadVentaData = () => {
        const { match: { params } } = this.props;
        this.props.onVisit(params)
        axios
            .get(VentaDetailURL(params.ventaID))
            .then(res => {
                let promos = res.data.promocion.map(p => {
                    return (
                        {
                            key: p.id,
                            id: p.id,
                            nombre: p.nombre,
                        }
                    )
                });
                this.setState({
                    data: res.data,
                    promos: promos
                });
                this.props.form.setFieldsValue({
                    nota: res.data.nota,
                });
            })
            .catch(err => {
                console.log(err)
                //this.setState({ error: err });
            });
    }

    componentDidMount() {
        this.loadVentaData();
    }

    handleSelectChange = (value, id) => {
        //console.log(typeof value, id)
        axios
            .patch(recepcionEquipoUpdateURL(id), 
                {
                    status: value,
                }
            )
            .then(res => {
                    //console.log(res);
                    this.loadVentaData();
                })
                .catch(err => {
                    console.log(err.response)
                });
    }

    handleSubmit = e => {
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                axios
                    .put(ventaEditarURL(params.ventaID), {
                        nota: this.props.form.getFieldsValue().nota
                    })
                    .then(res => {
                        this.props.history.push(`/ventas/${res.data.id}/`);
                        message.success('¡Se modificó la nota con éxto!');
                    })
                    .catch(err => {
                        message.error('Hubo un error.');
                    });
            }
        });
    };

    showModal = (nombre, prod) => {
        this.setState({
            ...this.state,
            ...{
                [nombre]: true, //visibleDevol ó garantias??
                clickedVentaProd: prod
            }
        });
    };

    showModalProductos = () => {
        axios
            .get(productosListURL)
            .then(res => {
                let productos = res.data.map(prod => {
                    return (
                        {
                            key: prod.id,
                            id: prod.id,
                            nombre: prod.nombre,
                            stock: prod.stock
                        }
                    )
                });
                this.setState({ productos: productos });
            })

        this.props.onChangePromosArray(this.state.promos)

        this.setState({
            ...this.state,
            ...{
                visibleProductos: true,
            }
        });
    };

    handleOkDevGar = (nombre, e) => {
        const { clickedVentaProd, cantidad, data } = this.state
        axios
            .post(DevGarVentaProductoURL, {
                cantidad: cantidad,
                id_producto: clickedVentaProd.producto.id,
                id_venta: data.id,
                devolucion_o_garantia: nombre,
                id_ventaproducto: clickedVentaProd.id,
            })
            .then(res => {
                //console.log(res)
                this.loadVentaData();
                message.success(res.data.message);
                // if (nombre === 'devolucion') {
                //     this.showModalPago();
                // }
            })
            .catch(err => {
                if (err.response === undefined){
                    console.log(err)
                } else {
                    message.error(err.response.data.message);
                }
            })

        this.setState({
            visibleDevol: false,
            visibleGarantia: false,
        });
    };

    handleOkProducto = (e) => {
        const { productosVenta, promosArray } = this.props
        axios
            .post(ventaNuevaURL, {
                venta_nueva_o_editada: 'Editada',
                productosVenta,
                promosArray,
                venta_id: this.state.data.id
            })
            .then(res => {
                //this.props.replace(`/ventas/`); //tengo que pasar history como props porque "Confirm.js" no es parte deel Router como tal, solo "VentaForm.js"
                this.setState({
                    ...this.state,
                    ...{
                        visibleProductos: false,
                    }
                });
                this.loadVentaData(); //Que se actualize la venta
                this.props.onSubmitResetReduxState() //Que se borre el state de ventaproductos y arrays similares
                message.success('La venta fue actualizada con éxito.');
            })
            .catch(err => {
                //console.log(err.response, 'ERRRROR')
                message.error(err.response.data.message);
            });
    }

    handleCancel = (nombre, e) => {
        this.setState({
            ...this.state,
            [nombre]: false,
            monto: 0,
            cantidad: 1
        });
        this.props.onSubmitResetReduxState()
    };

    handleChange = (nombre, e) => { // en antd pasa el arg sin hacer e.target.value...
        this.setState({ //lo reuso para pago y devolucion piezas
            ...this.state,
            ...{ [nombre]: e } // "e" es la "cantidad" o el "monto", dependiendo de donde se llame la función.
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data, clickedVentaProd} = this.state;
        if (data === null) {
            return null;
        }

        let productosB = data.productos.map(prod => {
            let total_row = parseFloat(prod.cantidad * prod.precio_de_venta)
            let botones_row = (prod.balance_positivo_dev_gar === 'No' ? 'Dev/Gar aplicada' : (
                <div style={{ justifyContent: 'space-around', display: 'flex' }}>
                    <Tooltip placement="top" title="Aplicar devolución">
                    <Button onClick={() => this.showModal('visibleDevol', prod)}>
                        <Icon type="left-circle" theme="filled" style={{ color: 'red' }} />
                    </Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Enviar a Garantía">
                    <Button onClick={() => this.showModal('visibleGarantia', prod)}>
                        <Icon type="warning" theme="filled" style={{ color: 'gold' }} />
                    </Button>
                    </Tooltip>
                </div>
            )
            )
            return (
                {
                    key: prod.id,
                    id: prod.id,
                    nombre: prod.producto.nombre,
                    cantidad: prod.cantidad,
                    precio: prod.precio_de_venta,
                    total: total_row,
                    fecha: prod.fecha,
                    botones: botones_row
                }
            )
        });

        let devoluciones = data.devoluciones.map(prod => {
            return (
                {
                    key: prod.id,
                    id: prod.id,
                    nombre: prod.producto.nombre,
                    cantidad: prod.cantidad,
                    fecha: prod.fecha
                }
            )
        });

        let garantias = data.garantias.map(prod => {
            return (
                {
                    key: prod.id,
                    id: prod.id,
                    nombre: prod.producto.nombre,
                    cantidad: prod.cantidad,
                    fecha: prod.fecha
                }
            )
        });

        let formasB = data.pago.map(p => {
            return (
                {
                    key: p.id,
                    id: p.forma_de_pago.id,
                    nombre: p.forma_de_pago.nombre,
                    importe: p.monto,
                    fecha: p.fecha
                }
            )
        });

        let status = data.recepcion_equipo.status
        let color = (status === "p" ? "magenta" : (status === "e" ? "green" : ( status === "t" ? "gold" : "cyan")))
        let texto_status = (status === "e" ? "Entregado" : (status === "t" ? "Terminado" : (status === "p" ? "En Proceso" : "N/A")))
        let badge_color = (data.numero_impresiones > 1 ? {backgroundColor: 'red', zIndex:0} : {backgroundColor:'#52c41a', zIndex:0})
        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>
                    Venta ID: {data.id} <span style={{marginLeft: '0.3em'}}></span> <Tag color={(data.status_pagos === "No pagada" ? "magenta" : (data.status_pagos === "Pagada" ? "green" : 'gold'))}>{data.status_pagos}</Tag>
                </h3>
                <Divider />
                <div style={{ maxWidth: '700px', margin: 'auto', textAlign: 'center' }}>
                    <h4 style={{ fontSize: '1.2em' }}><b>Cliente: </b> <Link to={`/cliente/${data.cliente.id}`}>{data.cliente.nombre}</Link> 
                        <a style={{paddingLeft: '15px'}} target="_blank" rel="noopener noreferrer" href={`https://b.arreglatupantalla.com/api/ticket-intranet/${data.id}/`}>
                            <Badge count={data.numero_impresiones} style={badge_color}>
                                <Icon type="printer" style={{ fontSize: '28px', color: 'grey' }} />
                            </Badge>
                        </a>
                    </h4>
                    <p style={{ fontSize: '1.2em' }}><b>Valor Venta:</b> {data.valor_venta} {data.status_pagos === 'No pagada' && <React.Fragment> <b style={{ marginLeft: '0.8em' }}> Saldo Pendiente:</b> {data.saldo_pendiente} </React.Fragment>}  
                        
                    </p>                    
                    <p><b>Fecha de Venta:</b> {data.fecha} | <b>¿Venta por Internet?:</b> {(data.internet === true ? 'Si' : 'No')} | <b>Capturó:</b> {data.usuario.username}</p>
                    {data.recepcion_equipo.id > 0 &&
                    <>
                    <div><b>Status Reparación: </b><Tag color={color}>{texto_status}</Tag>
                        <Select
                            value={'Cambiar Status'}
                            size={'small'}
                            style={{ width: '20%' }}
                            onChange={(value) => this.handleSelectChange(value, data.recepcion_equipo.id)}
                            >
                            <Option value="p">En Proceso</Option>
                            <Option value="t">Terminado</Option>
                            <Option value="e">Entregado</Option>
                        </Select>
                        <span style={{ margin: '0.5em' }} ></span>
                        <Button
                            type="dashed"
                            size="small"
                            onClick={() => this.showModal('visibleReparacion', 0)}>
                            <Icon type='tablet' /> Editar Reparación
                        </Button>
                        <span style={{ margin: '0.5em' }} ></span>
                    </div>
                    <br></br>
                    <br></br>
                    </>
                    }
                    <Button
                        onClick={() => this.clickChild()}>
                        <IconUI name='payment' /> Aplicar Pago
                    </Button>
                    <span style={{ margin: '0.5em' }} ></span>
                    <Button
                        type="primary"
                        color='primary'
                        variant="contained"
                        onClick={this.showModalProductos}>
                        <IconUI name='add' /> Agregar Productos
                    </Button>
                    <br></br>
                    <br></br>
                    <Form labelCol={{}} wrapperCol={{}} onSubmit={this.handleSubmit}>
                        <Form.Item>
                            {getFieldDecorator('nota', {
                            })(<Input.TextArea placeholder="Comentarios..." maxLength={350} />)}
                            <Button
                                size='small'
                                variant="contained"
                                onClick={this.handleSubmit}>
                                <Icon type="save" /> Guardar Comentarios
                            </Button>
                        </Form.Item>

                    </Form>

                    <Table title={() => 'Productos Vendidos'} columns={columnsProd} dataSource={productosB} bordered={true} pagination={false} size="small" locale={{ emptyText: 'No hay productos seleccionados' }} />
                    <br></br>
                    <Table title={() => 'Pagos y Devoluciones'} columns={columnsPago} dataSource={formasB} bordered={true} pagination={false} size="small" locale={{ emptyText: 'No hay pagos' }} />
                    <br></br>
                    <Table title={() => 'Promociones Aplicadas'} columns={columnsPromos} dataSource={this.state.promos} bordered={true} pagination={false} size="small" locale={{ emptyText: 'No se aplicaron promociones' }} />
                    <br></br>

                    {devoluciones.length > 0 &&
                        <div>
                            <hr></hr>
                            <br></br>
                            <Table title={() => 'Devolucion de productos vendidos (se regresa la pieza a stock ó a garantías y se regresa el dinero al cliente)'} columns={columnsDevoluciones} dataSource={devoluciones} bordered={true} pagination={false} size="small" locale={{ emptyText: 'No hay devoluciones' }} />
                        </div>
                    }
                    {garantias.length > 0 &&
                        <div>
                            <hr></hr>
                            <br></br>
                            <Table title={() => 'Garantías (Piezas dañadas que regresa el cliente y se van a garantías. Posteriormente se da de baja la pieza que se le va a aplicar como garantía)'} columns={columnsGarantias} dataSource={garantias} bordered={true} pagination={false} size="small" locale={{ emptyText: 'No hay garantías' }} />
                        </div>
                    }

                    <Modal
                        title="Aplicar devolución"
                        visible={this.state.visibleDevol}
                        onOk={(e) => this.handleOkDevGar('devolucion', e)}
                        onCancel={() => this.handleCancel('visibleDevol')}
                        cancelText="Cancelar"
                        okText="Devolver pieza"
                    >
                        {clickedVentaProd.producto &&
                            <p>Ingresa la cantidad del producto <b>"{clickedVentaProd.producto.nombre}"</b> a devolver:</p>
                        }

                        <InputNumber value={this.state.cantidad} onChange={(e) => this.handleChange('cantidad', e)} style={{ width: '15%' }} defaultValue={1} />
                    </Modal>
                    <Modal
                        title="Mandar pieza a Garantía"
                        visible={this.state.visibleGarantia}
                        onOk={(e) => this.handleOkDevGar('garantia', e)}
                        onCancel={() => this.handleCancel('visibleGarantia')}
                        cancelText="Cancelar"
                        okText="Aplicar"
                    >
                        {clickedVentaProd.producto &&
                        <React.Fragment>
                        <p>Piezas dañadas que regresa el cliente y se van a garantías. Posteriormente se da de baja la pieza que se le va a aplicar como garantía)</p>
                        <br></br>
                            <p>Ingresa la cantidad del producto <b>"{clickedVentaProd.producto.nombre}"</b> a mandar a garantías:</p>
                            </ React.Fragment>
                        }

                        <InputNumber value={this.state.cantidad} onChange={(e) => this.handleChange('cantidad', e)} style={{ width: '15%' }} defaultValue={1} />
                    </Modal>
                    <ModalPago title="Aplicar pago o devolución" instrucciones="una devolución" gasto="una DEVOLUCIÓN, pago de la tienda al cliente." ingreso="INGRESO, pago del cliente a la tienda." venta_id={this.state.data.id} load={() => this.loadVentaData()} setClick={click => this.clickChild = click} />
                    <Modal
                        title="Agregar Productos a la Venta"
                        visible={this.state.visibleProductos}
                        onOk={this.handleOkProducto}
                        onCancel={() => this.handleCancel('visibleProductos')}
                        cancelText="Cancelar"
                        okText="Agregar Productos"
                    >
                        <div style={{ textAlign: 'center' }}>
                            <VentaProductoForm />
                            <hr></hr>
                            <br></br>
                            <Promocion text="Selecciona las promociones que aplicaron, éstas incluyen otras ventas." />
                        </div>
                    </Modal>
                    <Modal
                        title="Editar Reparación"
                        visible={this.state.visibleReparacion}
                        onOk={(e) => this.clickChild(e)}
                        onCancel={() => this.handleCancel('visibleReparacion')}
                        cancelText="Cancelar"
                        okText="Guardar"
                    >
                        <RecepcionEquipo setClick={click => this.clickChild = click} ancho="400px" size="default" ventaEdit={true} recepcionID={data.recepcion_equipo.id} closeModal={() => this.handleCancel('visibleReparacion')}/>
                    </Modal>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        productos: state.producto.productos,
        producto: state.producto.producto,
        valorTotal: state.producto.valorTotal,
        productosVenta: state.producto.productosVenta,
        formasDePago: state.pago.formasDePago,
        totalFormasDePago: state.pago.totalFormasDePago,
        formaSelec: state.pago.formaSelec,
        promosArray: state.producto.promosArray,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/ventas/', 'Ventas', `/ventas/${params.ventaID}/`, 'Detalle de Venta', 2)),
        //link1, label 1, link 2, label 2
        onChangePromosArray: (val) => dispatch(actions.changePromosArray(val)),
        onSubmitResetReduxState: () => dispatch({ type: 'RESET_REDUX_STATE' })
    }
}

const WrappedApp = Form.create({ name: 'editar-venta' })(VentaDetail);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedApp);