import axios from "axios";
import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};

export const setUserPermissions = data => {
  return {
    type: actionTypes.SET_USER_PERMISSIONS,
    payload: data
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("username");
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
      // dispatch({ type: 'RESET_REDUX_STATE' });
      // dispatch({ type: 'RESET_PERMISSIONS_STATE' });
    }, expirationTime * 1000);
  };
};

export const authLogin = (username, password) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post("https://b.arreglatupantalla.com/rest-auth/login/", {
        username: username,
        password: password
      })
      .then(res => {
        const token = res.data.key;
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`
        }
        axios
        .get("https://b.arreglatupantalla.com/api/permisos-usuario/")
        .then(res => {
          dispatch(setUserPermissions(res.data))
        }).catch(err => {
        console.log(err);
      });
        const expirationDate = new Date(new Date().getTime() + 360000 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("username", username);
        localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(360000));
      })
      .catch(err => {
        //console.log(err.response)
        dispatch(authFail(err));
      });
  };
};

export const authCheckState = () => { //this function is called from App.js
  return dispatch => {
    const token = localStorage.getItem("token");
    //console.log(token, 'toookeen')
    if (token === undefined) {
      dispatch(logout());
      // dispatch({ type: 'RESET_REDUX_STATE' });
      // dispatch({ type: 'RESET_PERMISSIONS_STATE' });
    } else {
      //console.log(token, 'token from authchekcstate')
      if(token !== undefined && token !== null){
      axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
      }
      axios
        .get("https://b.arreglatupantalla.com/api/permisos-usuario/")
        .then(res => {
          dispatch(setUserPermissions(res.data))
        })
        .catch(err => {
        console.log(err);
      });
    };
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
        // dispatch({ type: 'RESET_REDUX_STATE' });
        // dispatch({ type: 'RESET_PERMISSIONS_STATE' });
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

// Setup config with token - helper function
export const tokenConfig = getState => {
  // Get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};
