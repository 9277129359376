import React, { Component } from 'react';
import { BalanceAlDiaURL } from '../../constants';
import axios from 'axios';
import { Table } from 'antd';

export class BalanceAlDia extends Component {
  state = {
    data: [],
    loading: false,
  };

  fetchBalance = () => {
    this.setState({ loading: true });
    axios
      .get(BalanceAlDiaURL + '?tienda=' + this.props.tienda)
      .then((res) => {
        //console.log(res.data) // {Efectivo: 2260, Mercado Pago: 0, Tarjeta: 3950}
        //No puedes loopear un object, debes de loppear un array. La tabla de antd espera un array por eso inizializa el state con data: []
        //antd Table también espera los key tipo 'forma_de_pago' que pones en los "columns"
        let formas = Object.keys(res.data).map(function (key, index) {
          return {
            key: Math.random(),
            forma_de_pago: key,
            balance: '$ ' + res.data[key].toLocaleString(),
          };
        });
        this.setState({ data: formas, loading: false });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
  };

  componentDidMount() {
    this.fetchBalance();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tienda !== prevProps.tienda) {
      this.fetchBalance();
    }
  }

  render() {
    const { data, loading } = this.state;

    const columns = [
      {
        title: 'Forma de Pago',
        dataIndex: 'forma_de_pago',
        key: 'forma_de_pago',
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        align: 'left',
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          dataSource={data}
          bordered={true}
          pagination={false}
          size="middle"
          loading={loading}
        />
      </div>
    );
  }
}

export default BalanceAlDia;
