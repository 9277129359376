import React from "react";
import { connect } from "react-redux";
import { newPage } from "./../store/actions/breadcrumbs";
import Logo from "./../VapesYArreglaAColor.png";

class Inicio extends React.Component {
  componentDidMount(){
    this.props.onVisit()
  }

  render() {
    let dimension = (window.innerWidth < 450 ? '200px' : '300px');

    return (
      <div style={{margin: 'auto', maxWidth: dimension, textAlign: 'center'}}>
          <br></br>
          <br></br>
          <h5 style={{textAlign: 'center'}}> ¡Bienvenido!</h5>
          <br></br>
          <br></br>
          <br></br>
          <img src={Logo} alt='Vapes logo' width={dimension} ></img>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onVisit: () => dispatch(newPage('/', 'Inicio', '', '', 1))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Inicio);