import React from 'react';
import Highlighter from 'react-highlight-words';
import { newPage } from "../../store/actions/breadcrumbs";
import { connect } from 'react-redux';
import { Input, Button, Icon, Table, Tag } from 'antd';
import axios from "axios";
import { Link } from 'react-router-dom'
import { proveedoresListURL } from "../../constants";

class Proveedores extends React.Component {
    state = {
        searchText: '',
        loading: false,
        error: null,
        data: []
    }

    componentDidMount() {
        this.props.onVisit()
        this.setState({ loading: true });
        axios
            .get(proveedoresListURL)
            .then(res => {
                //console.log(res.data)
                let proveedores = res.data.map(p => {
                    let trimmed_comentario = (p.comentarios.length > 15 ? p.comentarios.slice(0, 15) + ' ...' : p.comentarios)
                    return (
                        {
                            key: p.id,
                            id: p.id,
                            status: p.status,
                            nombre: p.nombre,
                            balance_credito: '$ ' + p.balance_credito,
                            comentarios: trimmed_comentario,
                            total_garantias: p.total_garantias
                        }
                    )
                });
                //console.log(ventas)
                this.setState({ 
                    data: proveedores,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    }

    //EMPIEZAN FILTROS PARA SEARCH
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar producto`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    //TERMINAN FILTROS PARA SEARCH

    //FILTROS SORT Y FILTER
    handleChange = (pagination, filters, sorter) => {
        //console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            sortedInfo: sorter,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'descend',
                columnKey: 'stock',
            },
        });
    };
    //TERMINAN FILTROS SORT Y FILTER

    render() {
        const { loading } = this.state;
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center'
            },
            {
                title: 'Proveedor',
                dataIndex: 'nombre',
                key: 'nombre',
                ...this.getColumnSearchProps('nombre'),
                render: (text, record) => <Link to={record.id + '/'}>{text}</Link>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status'
            },
            {
                title: 'Crédito Disponible',
                dataIndex: 'balance_credito',
                key: 'balance_credito'
            },
            {
                title: 'Comentarios',
                dataIndex: 'comentarios',
                key: 'comentarios',

            },
            {
                title: 'Tot. Garantías',
                dataIndex: 'total_garantias',
                key: 'total_garantias',
                align: 'center',
                sorter: (a, b) => a.total_garantias - b.total_garantias,
                render: (tag) => {
                    let color = (tag > 9 ? "magenta" : (tag > 5 ? "gold" : "green"))
                    return (
                        <Tag color={color} key={tag}>
                            {tag}
                        </Tag>
                    )
                }
            },
        ];
        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Proveedores</h3>
                <Table columns={columns} dataSource={this.state.data} bordered={true} pagination={{ defaultPageSize: 10 }} loading={loading}/>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/proveedores/', 'Proveedores', '/', '', 1))
        //link1, label 1, link 2, label 2
    }
}

export default connect(null, mapDispatchToProps)(Proveedores);