import * as actionTypes from "../actions/actionTypes";
//import { updateObject } from "../utility";

const initialState = {
    proveedores: [],
    comentarios: ''
    //proveedor: '', comentado para que salga el placeholder en el select, en lugar de '' vacío
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_PROVEEDORES_DATA:
            return {
                ...state,
                proveedores: action.payload
            };
        case actionTypes.CHANGE_PROVEEDOR:
            return {
                ...state,
                proveedor: action.payload
            };
        case actionTypes.CHANGE_COMENTARIOS_PROVEEDOR:
            return {
                ...state,
                comentarios: action.payload
            };
        default:
            return state;
    }
};

export default reducer;