import * as actionTypes from './actionTypes';
import axios from 'axios';
import { FormasDePagoURL } from './../../constants';

export const loadFormasDePago = (tienda) => {
  return (dispatch) => {
    let getURL = tienda
      ? FormasDePagoURL + '?tienda=' + tienda
      : FormasDePagoURL;
    axios
      .get(getURL)
      .then((res) => {
        let formas = res.data.map((forma) => {
          return {
            key: forma.id,
            id: forma.id,
            nombre: forma.nombre,
          };
        });
        dispatch({
          type: actionTypes.LOAD_FORMAS_DATA,
          payload: formas,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: err,
        });
      });
  };
};

export const calcularTotalAPagar = (value) => {
  return {
    type: actionTypes.CALCULAR_TOTAL_A_PAGAR,
    payload: value,
  };
};

export const changeFromasArrayState = (value) => {
  return {
    type: actionTypes.CHANGE_FORMAS_ARRAY_STATE,
    payload: value,
  };
};

export const changeFromaSelec = (value) => {
  return {
    type: actionTypes.CHANGE_FORMA_SELEC,
    payload: value,
  };
};
