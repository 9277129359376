import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  to1: '/',
  to2: '/',
  label1: 'label1',
  label2: 'label2',
  numOfBcrumbs: 2,
};

const bcrumbsChange = (state, action) => {
  return updateObject(state, {
    to1: action.to1,
    label1: action.label1,
    to2: action.to2,
    label2: action.label2,
    numOfBcrumbs: action.num
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NEW_LOADED_PAGE: //caso breadcrumbs
      return bcrumbsChange(state, action);
    default:
      return state;
  }
};

export default reducer;
