import React, { Component } from 'react';
import { ventasListURL } from '../../constants';
import { Table, Tag } from 'antd';
import { Link } from 'react-router-dom';

export class VentasDelDia extends Component {
  fetchData = () => {
    this.props.load(
      ventasListURL +
        '?fecha=' +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
          .toISOString()
          .substring(0, 10) +
        '&tienda_res_dia=' +
        this.props.tienda
    );
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tienda !== prevProps.tienda) {
      this.fetchData();
    }
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => (
          <Link to={'/ventas/' + record.id + '/'}>{text}</Link>
        ),
      },
      {
        title: 'Produto (cantidad)',
        dataIndex: 'productos',
        key: 'productos',
        render: (tags) => (
          <>
            {tags.map((tag) => {
              //let color = (tag === "No pagado" ? "magenta" : "green")
              return (
                <Tag color="blue" key={Math.random()}>
                  {tag}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: 'Comentarios',
        dataIndex: 'nota',
        key: 'nota',
      },
      {
        title: 'Pagos (monto)',
        dataIndex: 'forma_de_pago',
        key: 'forma_de_pago',
        render: (tags) => (
          <>
            {tags.map((tag) => {
              let color = tag.includes('-') ? 'magenta' : 'green';
              return (
                <Tag color={color} key={Math.random()}>
                  {tag}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: 'Valor Venta',
        dataIndex: 'valor_venta',
        key: 'valor_venta',
        align: 'center',
      },
    ];
    return (
      <React.Fragment>
        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={this.props.data}
          bordered={true}
          pagination={false}
          locale={{ emptyText: 'No hay ventas en esta fecha.' }}
          size="middle"
        />
      </React.Fragment>
    );
  }
}

export default VentasDelDia;
