import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import Proveedor from './../Proveedores/Proveedor';
import PedidoProductoForm from './PedidoProductoForm';
import Confirm from './Confirm';
import { Redirect, withRouter } from "react-router-dom"
import { Steps, Divider, message, Button, Icon } from 'antd';
const { Step } = Steps;

export class PedidoForm extends Component {
    state = {
        step: 0,
        loading: false,
        error: null
    };

    componentDidMount() {
        this.props.onVisit()
    }

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        if (this.props.proveedor !== undefined) {
            this.setState({
                step: step + 1
            });
        }
        else {
            message.error('Selecciona un proveedor para continuar');
        }
    };

    // Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };

    onChange = current => {
        this.setState({ step: current });
    };

    render() {
        const { step } = this.state;
        const { proveedor } = this.props;
        let hayProveedor = (proveedor === undefined ? true : false)
        const steps = (
            <React.Fragment>
                <Steps current={step} onChange={this.onChange}>
                    <Step title="Proveedor" />
                    <Step title="Seleccionar Productos" disabled={hayProveedor}/>
                    <Step title="Confirmar Pedido" disabled={hayProveedor}/>
                </Steps>
                <Divider />
            </React.Fragment>
        )
        const solo_boton_continuar = (
            <div style={{ textAlign: 'center' }}>
                <br /><br />

                <Button
                    onClick={this.nextStep}
                >
                    Continuar <Icon type="right" />
                </Button>
            </div>
        )

        switch (step) {
            case 0:
                return (
                    <React.Fragment>
                        {steps}
                        <Proveedor
                            proveedor={proveedor}
                            nextStep={this.nextStep}
                            desdePedido={true}
                        />
                        {solo_boton_continuar}
                    </React.Fragment>
                );
            case 1:
                return (
                    <React.Fragment>
                        {steps}
                        <PedidoProductoForm
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                        />
                    </React.Fragment>
                );
            case 2:
                return (
                    <React.Fragment>
                        {steps}
                        <Confirm
                            prevStep={this.prevStep}
                            history={this.props.history}
                        />
                    </React.Fragment>
                );
            default: return <Redirect to="/pedidos/" />;
        }
    }
}

const mapStateToProps = state => {
    return {
        proveedor: state.proveedor.proveedor
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/pedidos/', 'Pedidos', '/pedido-nuevo/', 'Recepción de Mercancía', 2))
        //link1, label 1, link 2, label 2
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PedidoForm));