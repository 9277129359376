import React from 'react';
import { garantiaDetailURL, garantiaEditarURL, recibirGarantiaProductoURL, CreditoProveedorViewURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { Button, Divider, Form, Table, Input, Icon, message, Modal, InputNumber, Checkbox } from 'antd';
import { Link } from 'react-router-dom'

const columnsPed = [
    {
        title: 'ID Gar. Pod.',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Producto',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Enviadas',
        dataIndex: 'garantias_enviadas',
        key: 'garantias_enviadas',
    },
    {
        title: 'Recibidas',
        dataIndex: 'garantias_recibidas',
        key: 'garantias_recibidas',
    },
    {
        title: 'Crédito',
        dataIndex: 'garantias_credito',
        key: 'garantias_credito',
    },
    {
        title: 'Rechazadas',
        dataIndex: 'garantias_rechazadas',
        key: 'garantias_rechazadas',
    },
    {
        title: '',
        dataIndex: 'editar',
        key: 'editar',
    }
];

class GarantiaDetail extends React.Component {
    state = {
        data: null,
        visibleGarProd: false,
        visibleCredito: false,
        clickedGarantiaProd: '',
        recibidasInput: 0,
        creditoInput: 0,
        rechazadasInput: 0,
        montoCredito: 0
    };

    loadGarantiaData = () => {
        const { match: { params } } = this.props;
        axios
            .get(garantiaDetailURL(params.garantiaID))
            .then(res => {
                //var valor = (res.data.garantias_pendientes === 'True' ? true : false) NO SIRVE ASI, NO SE PASA COMOUN STRING TRUE, SINO COMO true
                this.setState({
                    data: res.data,
                    garantias_pendientes: res.data.garantias_pendientes
                });
                this.props.form.setFieldsValue({
                    comentarios: res.data.comentarios,
                });
            })
            .catch(err => {
                console.log(err)
                //this.setState({ error: err });
            });
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.onVisit(params)
        this.loadGarantiaData()
    }


    handleSubmit = e => {
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                axios
                    .put(garantiaEditarURL(params.garantiaID), {
                        comentarios: this.props.form.getFieldsValue().comentarios
                    })
                    .then(res => {
                        //console.log(res)
                        //console.log(values, 'values')
                        this.props.history.push(`/solicitud-de-garantias/${res.data.id}/`);
                        message.success('¡Se modificaron los comentarios!');
                    })
                    .catch(err => {
                        console.log(err)
                        message.error('Hubo un error.');
                    });
            }
        });
    };

    showModal = (nombre, prod) => {
        this.setState({
            ...this.state,
            ...{
                [nombre]: true,
                clickedGarantiaProd: prod
            }
        });
    };

    showModalCredito = (nombre) => {
        this.setState({
            ...this.state,
            ...{
                [nombre]: true,
            }
        });
    };

    handleCancel = (nombre, e) => {
        this.setState({
            ...this.state,
            [nombre]: false
        });
        //console.log(this.state.clickedGarantiaProd, 'clickedGarantiaProd')
        //this.props.onSubmitResetReduxState()
    };

    handleChange = (nombre, e) => { // en antd pasa el arg sin hacer e.target.value...
        this.setState({ //lo reuso para pago y devolucion piezas
            ...this.state,
            ...{ [nombre]: e } // "e" es la "cantidad" o el "monto", dependiendo de donde se llame la función.
        });
    };

    onChangeCbx = (e) => {
        var valor = (e.target.checked === true ? 'True' : 'False')
        this.setState({
            ...this.state,
            garantias_pendientes: e.target.checked
        });
        const { match: { params } } = this.props;

        axios
            .put(garantiaEditarURL(params.garantiaID), {
                garantias_pendientes: valor
            })
            .then(res => {
                this.props.history.push(`/solicitud-de-garantias/${res.data.id}/`);
                message.success('¡No olvides escribir los comentarios!');
                this.loadGarantiaData()
            })
            .catch(err => {
                console.log(err)
                message.error('Hubo un error.');
            });
        //window.location.reload(true);
    }

    handleOk = (e) => {
        const { recibidasInput, creditoInput, rechazadasInput, clickedGarantiaProd } = this.state
        if ((recibidasInput + creditoInput + rechazadasInput) !== clickedGarantiaProd.garantias_enviadas) {
            message.error('Las piezas recibidas + crédito + rechazadas deben de ser igual a las enviadas');
        }
        else if (recibidasInput == null || creditoInput == null || rechazadasInput == null) {
            message.error('El cambo debe de ser mayor o igual a 0.');
        }
        else {
            axios
                .post(recibirGarantiaProductoURL, { //You can also use post method to update
                    solicitud_id: this.state.data.id,
                    garantia_id: clickedGarantiaProd.id,
                    garantias_recibidas: recibidasInput,
                    garantias_rechazadas: rechazadasInput,
                    garantias_credito: creditoInput
                })
                .then(res => {
                    //this.props.replace(`/ventas/`); //tengo que pasar history como props porque "Confirm.js" no es parte deel Router como tal, solo "VentaForm.js"
                    this.setState({
                        ...this.state,
                        ...{
                            visibleGarProd: false,
                            recibidasInput: 0,
                            creditoInput: 0,
                            rechazadasInput: 0                       
                        }
                    });
                    this.loadGarantiaData() //Que se actualize la venta
                    message.success(res.data.message);
                })
                .catch(err => {
                    //console.log(err.response, 'ERRRROR')
                    message.error(err.response.data.message);
                });
        }
    }

    handleOkCredito = (e) => {
        axios
            .post(CreditoProveedorViewURL, { //You can also use post method to update
                solicitud_id: this.state.data.id,
                proveedor: this.state.data.proveedor.id,
                monto: this.state.montoCredito
            })
            .then(res => {
                //this.props.replace(`/ventas/`); //tengo que pasar history como props porque "Confirm.js" no es parte deel Router como tal, solo "VentaForm.js"
                this.setState({
                    ...this.state,
                    ...{
                        visibleCredito: false,
                        montoCredito: 0                  
                    }
                });
                this.loadGarantiaData() //Que se actualize
                message.success(res.data.message);
            })
            .catch(err => {
                //console.log(err.response, 'ERRRROR')
                message.error(err.response.data.message);
            });
        }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { data, clickedGarantiaProd } = this.state;
        if (data === null) {
            return null;
        }

        const columnsPag = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => <Link to={record.id + '/'}>{text}</Link>,
            },
            {
                title: 'Monto',
                dataIndex: 'monto',
                key: 'monto',
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
            }
        ];

        let creditos = data.pago.map(p => {
            return (
                {
                    key: p.id,
                    id: p.id,
                    monto: p.monto,
                    fecha: p.fecha
                }
            )
        });

        let garantias = data.garantia_producto.map(gp => {
            let editar_row = (
                <div style={{ justifyContent: 'space-around', display: 'flex' }}>
                    {(gp.touched === "si" ? <Icon style={{ color: 'green' }} type="check-circle" theme="filled" /> :
                        <Button onClick={() => this.showModal('visibleGarProd', gp)}>
                            <Icon type="edit" theme="filled" style={{ color: '#1890ff' }} />
                        </Button>
                    )}
                </div>
            )

            return (
                {
                    key: gp.id,
                    id: gp.id,
                    nombre: gp.producto.nombre,
                    garantias_enviadas: gp.garantias_enviadas,
                    garantias_recibidas: gp.garantias_recibidas,
                    garantias_credito: gp.garantias_credito,
                    garantias_rechazadas: gp.garantias_rechazadas,
                    editar: editar_row
                }
            )
        });

        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>
                    Solicitud de Garantía ID: {data.id}  <div style={{ marginLeft: 15, display: 'inline', borderRadius: 7, maxWidth: 40, backgroundColor: (data.status === "Completada" ? "green" : (data.status === "Enviada" ? "yellow" : 'red')), color: 'black', opacity: 0.7, padding: 3 }}>{data.status}</div>
                </h3>
                <Divider />
                <div style={{ textAlign: 'center' }}>
                    <div style={{ maxWidth: '700px', margin: 'auto' }}>
                        <h4 style={{ fontSize: '1.2em' }}><b>Proveedor: </b> <Link to={`/proveedores/${data.proveedor.id}`}>{data.proveedor.nombre}</Link> </h4>
                        <p style={{ fontSize: '1.2em' }}><b>Total piezas:</b> {data.total} {data.acumulado_creditos > 0 ? [<b key={1}> | Crédito acumulado: </b>, data.acumulado_creditos] : ''}</p>
                        <p><b>Fecha:</b> {data.fecha}</p>
                        <Button type="primary" size="small" onClick={() => this.showModalCredito('visibleCredito')}>
                            Agregar Crédito a Favor Nuestro
                        </Button>
                        <br></br>
                        <br></br>
                        <Form labelCol={{}} wrapperCol={{}} onSubmit={this.handleSubmit}>
                            <Checkbox onChange={this.onChangeCbx} checked={this.state.garantias_pendientes}>¿Con pendientes? Si hay piezas que el proveedor no envió porque no tenía antotalos en los comentarios para poder dar seguimiento.</Checkbox>
                            {/* <Input.TextArea placeholder="Numero de comentarios y comentarios*" onChange={(e) => this.handleChangeTa(e)} maxLength={300} style={{ height: 50 }} value={comentarios} /> */}
                            <Form.Item>
                                {getFieldDecorator('comentarios', {
                                })(<Input.TextArea placeholder="Comentarios" maxLength={350} />)}
                                <Button
                                    size='small'
                                    variant="contained"
                                    onClick={this.handleSubmit}>
                                    <Icon type="save" /> Guradar Comentarios
                            </Button>
                            </Form.Item>
                        </Form>
                        {data.pago.length > 0 &&
                        <Table title={() => 'Créditos del Proveedor a Nosotros'} columns={columnsPag} dataSource={creditos} bordered={true} pagination={false} size="medium"/>
                        }
                        <Table title={() => 'Garantías'} columns={columnsPed} dataSource={garantias} bordered={true} pagination={false} size="medium" locale={{ emptyText: 'No hay garantías' }} />
                        <Modal
                            title={clickedGarantiaProd.garantias_enviadas && `Producto: ${clickedGarantiaProd.producto.nombre}`}
                            visible={this.state.visibleGarProd}
                            onOk={this.handleOk}
                            onCancel={() => this.handleCancel('visibleGarProd')}
                            cancelText="Cancelar"
                            okText="Aceptar"
                        >
                            <div style={{ textAlign: 'center' }}>
                                {clickedGarantiaProd.garantias_enviadas &&
                                    <b>Piezas ENVIADAS: {clickedGarantiaProd.garantias_enviadas}</b>

                                }
                                <br></br>
                                <br></br>
                                <p><b>¿Cuántas se RECIBIERON FÍSICAMENTE?</b></p>
                                <InputNumber value={this.state.recibidasInput} onChange={(e) => this.handleChange('recibidasInput', e)} style={{ width: '15%' }} max={clickedGarantiaProd.garantias_enviadas} min={0} />
                                <p></p>
                                <p><b>¿Cuántas se aplican cómo CRÉDITO?</b></p>
                                <InputNumber value={this.state.creditoInput} onChange={(e) => this.handleChange('creditoInput', e)} style={{ width: '15%' }} max={clickedGarantiaProd.garantias_enviadas} min={0} />
                                <p></p>
                                <p><b>¿Cuántas fueron RECHAZADAS?</b></p>
                                <InputNumber value={this.state.rechazadasInput} onChange={(e) => this.handleChange('rechazadasInput', e)} style={{ width: '15%' }} max={clickedGarantiaProd.garantias_enviadas} min={0} />
                                <p></p>
                            </div>

                        </Modal>
                        <Modal
                            title="Agregar Crédito a Favor"
                            visible={this.state.visibleCredito}
                            onOk={this.handleOkCredito}
                            onCancel={() => this.handleCancel('visibleCredito')}
                            cancelText="Cancelar"
                            okText="Aplicar Crédito"
                        >
                            <div style={{ textAlign: 'center' }}>
                                <p>Es el crédito que da el proveedor en dinero en lugar de mandarnos piezas de garantías. Una especie de saldo a favor que podemos usar para futuros pedidos.</p>
                                Monto: <InputNumber value={this.state.montoCredito} onChange={(e) => this.handleChange('montoCredito', e)} style={{ width: '15%' }} />
                                <p></p>
                            </div>

                        </Modal>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/solicitud-de-garantias/', 'Garantias', `/solicitud-de-garantias/${params.garantiaID}/`, 'Detalle de Solicitud', 2))
        //link1, label 1, link 2, label 2
    }
}

//export default connect(null, mapDispatchToProps)(VentaDetail);

const WrappedApp = Form.create({ name: 'editar-venta' })(GarantiaDetail);
export default connect(null, mapDispatchToProps)(WrappedApp);