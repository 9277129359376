import React from 'react';
import { Route } from 'react-router-dom';
import Hoc from './hoc/hoc';
import PrivateRoute from './components/PrivateRoute';

import Login from './containers/Login';
import Inicio from './containers/Inicio';
import TablaProductos from './containers/Productos/TablaProductos';
import Clientes from './containers/Ventas/Clientes';
import Cliente from './containers/Ventas/Cliente/AgregarCliente';
import Proveedor from './containers/Proveedores/AgregarProveedor';
import Proveedores from './containers/Proveedores/ProveedoresList';
import DetalleProveedor from './containers/Proveedores/DetalleProveedor';
import EditarProveedor from './containers/Proveedores/EditarProveedor';
import EditarCliente from './containers/Ventas/Cliente/EditarCliente';
import DetalleCliente from './containers/Ventas/Cliente/DetalleCliente';
import Ventas from './containers/Ventas/Ventas';
import VentasSiempre from './components/VentasSiempre';
import VentaDetail from './containers/Ventas/VentaDetail';
import Devolucion from './containers/Ventas/Devolucion';
import ProductoNuevo from './containers/Productos/ProductoNuevo';
import ProductoDetail from './containers/Productos/ProductoDetail';
import PedidoForm from './containers/Pedidos/PedidoForm';
import PedidosList from './containers/Pedidos/PedidosList';
import PedidoDetail from './containers/Pedidos/PedidoDetail';
import VentaForm from './containers/Ventas/NuevaVenta/VentaForm';
import ReporteVentas from './containers/Reportes/ReporteVentas';
import ReporteInventarios from './containers/Reportes/ReporteInventarios';
import ReporteIngresos from './containers/Reportes/ReporteIngresos';
import Pagos from './containers/Pagos/PagosList';
import PagosSiempre from './components/PagosSiempre';
import DetallePago from './containers/Pagos/PagoDetail';
import AgregarPago from './containers/Pagos/AgregarPago';
import AgregarConcepto from './containers/Admin/AgregarConcepto';
import Instrucciones from './containers/Admin/Instrucciones';
import ResumenDia from './containers/Reportes/ResumenDia';
import RecepcionEquipo from './containers/Reparaciones/RecepcionEquipo';
import ReparacionesList from './containers/Reparaciones/ReparacionesList';
import TraspasoFondos from './containers/Pagos/TraspasoFondos';

import SolicitudGarantiaForm from './containers/Garantias/SolicitudGarantiaForm';
import GarantiasList from './containers/Garantias/GarantiasList';
import GarantiaDetail from './containers/Garantias/GarantiaDetail';

const BaseRouter = () => (
  <Hoc>
    <PrivateRoute exact path="/pedido-nuevo/" component={PedidoForm} />
    <PrivateRoute exact path="/pedidos/" component={PedidosList} />
    <PrivateRoute
      exact
      path="/productos/:productoID"
      component={ProductoDetail}
    />
    <PrivateRoute exact path="/producto-nuevo/" component={ProductoNuevo} />
    <PrivateRoute exact path="/productos/" component={TablaProductos} />
    <PrivateRoute
      exact
      path="/agregar-cliente/"
      render={
        () => (
          <Cliente showSelect={true} />
        ) /*reuso el comp asi que necesito pasar props aqui*/
      }
    />
    <Route
      exact
      path="/agregar-proveedor/"
      render={() => <Proveedor showSelect={true} />}
    />
    <PrivateRoute exact path="/proveedores/" component={Proveedores} />
    <PrivateRoute
      exact
      path="/proveedores/:proveedorID/editar"
      component={EditarProveedor}
    />
    <PrivateRoute
      exact
      path="/proveedores/:proveedorID"
      component={DetalleProveedor}
    />
    <PrivateRoute
      exact
      path="/solicitud-de-garantias-nueva/"
      component={SolicitudGarantiaForm}
    />
    <PrivateRoute
      exact
      path="/solicitud-de-garantias/:garantiaID"
      component={GarantiaDetail}
    />
    <PrivateRoute
      exact
      path="/solicitud-de-garantias/"
      component={GarantiasList}
    />
    <PrivateRoute exact path="/cliente/" component={Clientes} />
    <PrivateRoute
      exact
      path="/cliente/:clienteID/editar"
      component={EditarCliente}
    />
    <PrivateRoute exact path="/cliente/:clienteID" component={DetalleCliente} />
    <PrivateRoute exact path="/ventas/" component={Ventas} />
    <PrivateRoute
      exact
      path="/ventas/desde-siempre/"
      component={VentasSiempre}
    />
    <PrivateRoute exact path="/pagos/" component={Pagos} />
    <PrivateRoute exact path="/pagos/desde-siempre/" component={PagosSiempre} />
    <PrivateRoute exact path="/pagos/:pagoID" component={DetallePago} />
    <PrivateRoute exact path="/agregar-pago/" component={AgregarPago} />
    <PrivateRoute exact path="/agregar-concepto/" component={AgregarConcepto} />
    <PrivateRoute exact path="/ventas/:ventaID" component={VentaDetail} />
    <PrivateRoute
      exact
      path="/ventas/:ventaID/devolucion"
      component={Devolucion}
    />
    <PrivateRoute exact path="/pedidos/:pedidoID" component={PedidoDetail} />
    <PrivateRoute exact path="/venta/" component={VentaForm} />
    <PrivateRoute exact path="/reportes/ventas/" component={ReporteVentas} />
    <PrivateRoute
      exact
      path="/reportes/inventarios/"
      component={ReporteInventarios}
    />
    <PrivateRoute
      exact
      path="/reportes/ingresos/"
      component={ReporteIngresos}
    />
    <PrivateRoute exact path="/resumen-dia/" component={ResumenDia} />
    <PrivateRoute exact path="/recepcion-equipo/" component={RecepcionEquipo} />
    <PrivateRoute exact path="/reparaciones/" component={ReparacionesList} />
    <PrivateRoute
      exact
      path="/traspaso-de-fondos/"
      component={TraspasoFondos}
    />
    <PrivateRoute exact path="/instrucciones/" component={Instrucciones} />
    <Route exact path="/login/" component={Login} />
    <PrivateRoute exact path="/" component={Inicio} />
  </Hoc>
);

export default BaseRouter;
