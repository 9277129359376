import * as actionTypes from "./actionTypes";
import axios from 'axios';
import { clientesListURL } from './../../constants'


export const loadClientesData = () => {
    const headers = {
  'Content-Type': 'application/json',
  Authorization: 'Token 5ccf5ebd73f35701b3b829b04245f9b614566614',
};
    return dispatch => {
        axios
            .get(clientesListURL, headers)
            .then(res => {
                let clientes = res.data.map(cliente => {
                    return (
                        {
                            key: cliente.id,
                            id: cliente.id,
                            nombre: cliente.nombre,
                        }
                    )
                });
                dispatch({
                    type: actionTypes.LOAD_CLIENTES_DATA,
                    payload: clientes //clientes: clientes
                });
            })
            .catch(err => {
                dispatch({
                    type: actionTypes.ERROR,
                    payload: err
                });
            });
    }
}

export const changeCliente = (value) => {
    return {
        type: actionTypes.CHANGE_CLIENTE,
        payload: value
    };
};

export const handleChangedComentarios = (value) => {
    return {
        type: actionTypes.CHANGE_COMENTARIOS_CLIENTE,
        payload: value
    };
}


export const newPage = (to1, label1, to2, label2, num) => {
    return {
        type: actionTypes.NEW_LOADED_PAGE,
        to1: to1,
        label1: label1,
        to2: to2,
        label2: label2,
        num
    };
};