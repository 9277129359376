import React, { Component } from 'react';
import {
  Button,
  Icon,
  Select,
  Statistic,
  Table,
  InputNumber,
  message,
} from 'antd';
import { connect } from 'react-redux';
import * as actions from './../../../store/actions/productos';
import axios from 'axios';
import { productoDetailURL } from './../../../constants';
const { Option } = Select;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Nombre',
    dataIndex: 'nombre',
    key: 'nombre',
  },
  {
    title: 'Marca',
    dataIndex: 'marca',
    key: 'marca',
  },
  {
    title: 'Cantidad',
    dataIndex: 'cantidad',
    key: 'cantidad',
  },
  {
    title: 'Precio',
    dataIndex: 'precio',
    key: 'precio',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
  {
    title: '',
    dataIndex: 'delete',
    key: 'delete',
  },
];

export class VentaProductoForm extends Component {
  componentDidMount() {
    this.props.onFetchProductos(this.props.tienda);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.productosVenta !== prevProps.productosVenta) {
      //State is passed as props with redux
      this.calculateTotal(); //ir worked on async functions like "handleOnClick" but not when it wasn't async like on the delete handlers
    } //That is why it had to be done here
  }

  handleOnClickProducto = () => {
    axios
      .get(productoDetailURL(this.props.producto))
      .then((res) => {
        let productoA = res.data;
        //let productoA = res.data.filter(prod => prod.id === this.props.producto) //cuando con .get(productosListURL)
        productoA['cantidad'] = productoA.stock > 0 ? 1 : 0; //antes cuando era productoA[0]... tienes que acceder con [0] porque filter regresa un array y dentro está el object que quieres
        productoA.precio = productoA.precio_de_venta; // esto y lo de arriba es lo mismo acceder con .costo o 'costo'

        const { productosVenta } = this.props;
        let no_existe0 = true;
        productosVenta.map((p) => {
          if (productoA.id === p.id || productoA.length === 0) {
            //check if id is in productosVenta table or if the table is empty
            message.error('El producto ya esta en el listado');
            no_existe0 = false;
            return false;
          }
          return 'I went though all prods and none are repeated';
        });
        if (no_existe0) {
          this.props.concatProductoVenta(productoA);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Hubo un error. Recuerda seleccionar un producto.');
        this.setState({ error: err, loading: false });
      });
  };

  handleChange = (nombre, id, e) => {
    //el nombre es la cantidad o el precio
    let { productosVenta } = this.props;
    let tipo_nombre = nombre === 'precio' ? 0 : 1; //NaN BUG: dejar en cero el precio mínimo o en uno la cantidad mínima -cuando quitas el cursor del input- deja NaN si no pones un valor y eso causa un error, por eso onblur le pone o 0 al precio o 1 a la cantidad que es el minimo
    let new_state = productosVenta.map((prod) => {
      if (prod.id === id) {
        //prod[nombre] = parseFloat(nombre === 'cantidad' ? e : e.target.value)
        prod[nombre] = parseFloat(
          e === '' || e === null || isNaN(e) ? tipo_nombre : e
        ); //InputNumber de antd, pasa el valor en e, no en e.target.value
      }
      return prod;
    });

    this.props.onChangeProductoVenta(new_state);
  };

  calculateTotal = () => {
    let { productosVenta, onCalcularValorTotal } = this.props;
    let values = productosVenta.map((prod) => {
      let tot = parseFloat(prod.cantidad * prod.precio);
      return tot;
    });
    function sumVals(total, num) {
      return total + num;
    }
    let valor_tot = values.length === 0 ? 0 : values.reduce(sumVals);
    onCalcularValorTotal(valor_tot);
  };

  onBlur = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      productos,
      producto,
      productosVenta,
      valorTotal,
      onHandleSelectProd,
      onDeleteProductoVenta,
    } = this.props;
    let productosB = productosVenta.map((prod) => {
      let input_cantidad = (
        <React.Fragment>
          <InputNumber
            onChange={(e) => this.handleChange('cantidad', prod.id, e)}
            type="number"
            style={{ width: '50%' }}
            defaultValue={
              isNaN(prod.cantidad) || prod.cantidad === 'NaN'
                ? 0
                : prod.cantidad
            }
            max={prod.stock}
            min={1}
          />
          <span
            style={{
              marginLeft: 10,
              ...(prod.stock > 2 ? { color: 'green' } : { color: 'red' }),
            }}
            maxLength={prod.stock}
          >
            {' '}
            Stock: {prod.stock}
          </span>
        </React.Fragment>
      );
      let input_precio = (
        <InputNumber
          onChange={(e) => this.handleChange('precio', prod.id, e)}
          type="number"
          style={{ width: '50%' }}
          placeholder="$"
          defaultValue={prod.precio}
          min={0}
        />
      );
      let total_row = parseFloat(prod.cantidad * prod.precio);
      let delete_row = (
        <Button onClick={() => onDeleteProductoVenta(prod.id)}>
          <Icon type="delete" theme="filled" style={{ color: 'red' }} />
        </Button>
      );
      return {
        key: prod.id,
        id: prod.id,
        nombre: prod.nombre,
        marca: prod.marca.nombre,
        cantidad: input_cantidad,
        precio: input_precio,
        total: total_row,
        delete: delete_row,
      };
    });

    return (
      <React.Fragment>
        <Table
          locale={{ emptyText: 'No hay productos seleccionados' }}
          size={'middle'}
          columns={columns}
          dataSource={productosB}
          bordered={true}
          pagination={false}
        />
        <br></br>
        <Select
          showSearch
          style={{ maxWidth: 400, minWidth: 250, textAlign: 'center' }}
          placeholder="Buscar producto..."
          optionFilterProp="children"
          onChange={onHandleSelectProd}
          value={producto}
          allowClear={true}
          autoFocus={true}
        >
          {productos.map((p) => {
            return (
              <Option key={p.id} value={p.id}>
                {p.nombre} ({p.stock})
              </Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          style={{ margin: '0.5em' }}
          onClick={this.handleOnClickProducto}
        >
          <Icon type="plus" />
        </Button>
        <br></br>
        <br></br>
        <Statistic
          title="Total"
          prefix={'MX'}
          value={valorTotal}
          precision={2}
        />
        <br></br>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productos: state.producto.productos,
    producto: state.producto.producto,
    valorTotal: state.producto.valorTotal,
    productosVenta: state.producto.productosVenta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchProductos: (tienda) =>
      dispatch(actions.loadProductosData(0, 'no_es_garantia', tienda)),
    onHandleSelectProd: (val) => dispatch(actions.handleSelectChangeProd(val)),
    concatProductoVenta: (prod) => dispatch(actions.concatProductoVenta(prod)),
    onDeleteProductoVenta: (prodID) =>
      dispatch(actions.deleteProductoVenta(prodID)),
    onChangeProductoVenta: (new_state) =>
      dispatch(actions.changeProductoVenta(new_state)),
    onCalcularValorTotal: (val) => dispatch(actions.calcularValorTotal(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VentaProductoForm);
