import * as actionTypes from "./actionTypes";

export const changePattern = (value) => {
    return {
        type: actionTypes.CHANGE_PATTERN,
        payload: value
    };
};

export const changeRecepcionEquipo = (value) => {
    return {
        type: actionTypes.CHANGE_RECEPCION_EQUIPO,
        payload: value
    };
};

export const changeConRecepcion = () => {
    return {
        type: actionTypes.CON_RECEPCION
    };
};

export const recepcionEquipoID = (value) => {
    return {
        type: actionTypes.RECEPCION_EQUIPO_ID,
        payload: value
    };
};

export const saveRecepcionFieldsData = (value) => {
    return {
        type: actionTypes.SAVE_RECEPCION_FIELDS_DATA,
        payload: value
    };
};

export const checkIsValidDataRecepcion = (value) => {
    return {
        type: actionTypes.IS_VALID_DATA_RECEPCION,
        payload: value
    };
};