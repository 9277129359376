import React from 'react';
import { FormasDePagoURL, traspasoDeFondosURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { Button, Divider, Form, Input, Icon, message, InputNumber, Select } from 'antd';
import Signo from './../../components/Signo'
const { Option } = Select;

class AgegarPago extends React.Component {
    state = {
        formasDePago: [],
        PositivoONegativo: null
    };

    componentDidMount() {
        this.props.onVisit();
        axios
            .get(FormasDePagoURL)
            .then(res => {
                let formas = res.data.map(forma => {
                    return (
                        {
                            key: forma.id,
                            id: forma.id,
                            nombre: forma.nombre,
                        }
                    )
                });
                this.setState({ formasDePago: formas });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                axios
                    .post(traspasoDeFondosURL, values)
                    .then(res => {
                        //console.log(res)
                       // console.log(values, 'values')
                        this.props.history.push(`/pagos/`);
                        message.success('¡Se efectuó el traspaso de fondos con éxito!');
                    })
                    .catch(err => {
                        console.log(err)
                        message.error(err.response.data.message);
                    });
            }
        });
    };

    handleInputChange = e => {
        this.setState({
            ...this.state,
            ...{ PositivoONegativo: e }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { formasDePago, PositivoONegativo } = this.state;
        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Traspaso de Fondos</h3>
                <Divider />
                <div style={{ margin: 'auto', maxWidth: '400px', textAlign: 'center' }}>
                    <Form labelCol={{}} wrapperCol={{}} onSubmit={this.handleSubmit}>
                        <Signo monto={PositivoONegativo} gasto="un monto negativo. EL MONTO DEBE DE SER POSITIVO" ingreso="un Traspaso de Fondos"/>
                        <div style={{ margin: '-1em'}}></div>
                        <Form.Item label="Monto">
                            {getFieldDecorator('monto', {
                                rules: [
                                    {
                                        required: true,
                                        message: '*',
                                    },
                                ],
                            })(<InputNumber type="number" placeholder="Monto" autoFocus={true} onChange={this.handleInputChange} />)}
                        </Form.Item>
                        <div style={{ margin: '-2em'}}></div>
                        <Form.Item label="Cuenta Origen">
                            {getFieldDecorator('cuenta_origen', {
                                rules: [
                                    {
                                        required: true,
                                        message: '*',
                                    },
                                ],
                            })(<Select
                                showSearch
                                style={{ maxWidth: 400 }}
                                placeholder="Buscar cuenta origen"
                                optionFilterProp="children"
                                //onChange={this.onChangeHandler}
                                allowClear={true}
                            >

                                {
                                    formasDePago.map(f => {
                                        return <Option key={f.id} value={f.id}>{f.nombre}</Option>
                                    })
                                }

                            </Select>)}
                        </Form.Item>
                        <div style={{ margin: '-2em'}}></div>
                        <Form.Item label="Cuenta Destino">
                            {getFieldDecorator('cuenta_destino', {
                                rules: [
                                    {
                                        required: true,
                                        message: '*',
                                    },
                                ],
                            })(<Select
                                showSearch
                                style={{ maxWidth: 400 }}
                                placeholder="Buscar cuenta destino"
                                optionFilterProp="children"
                                //onChange={this.onChangeHandler}
                                allowClear={true}
                            >
                                {
                                    formasDePago.map(f => {
                                        return <Option key={f.id} value={f.id}>{f.nombre}</Option>
                                    })
                                }

                            </Select>)}
                        </Form.Item>
                        <div style={{ margin: '-2em'}}></div>
                        <Form.Item label="Comentarios">
                            {getFieldDecorator('comentario', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Agrega un comentario ¿Cuál es la razón del traspaso?',
                                    },
                                ],
                            })(<Input.TextArea placeholder="Comentarios del pago" maxLength={350} />)}
                        </Form.Item>
                        <Button
                            color="primary"
                            size='small'
                            variant="contained"
                            onClick={this.handleSubmit}>
                            Guradar <Icon type="save" />
                        </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/traspaso-de-fondos/', 'Traspasar Fondos', '/', '', 1))
        //link1, label 1, link 2, label 2
    }
}

const WrappedApp = Form.create({ name: 'pago-nuevo' })(AgegarPago);
export default connect(null, mapDispatchToProps)(WrappedApp);