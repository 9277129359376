import React from 'react';
import CanvasDraw from "react-canvas-draw";
import { Form, Input, Button, Select, Row, Col, Checkbox, message } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { coloresListURL, modelosListURL, recepcionEquipoDetailURL, recepcionEquipoUpdateURL } from '../../constants';
import Pattern from './../../pattern.png';
import * as actions from "../../store/actions/recepciones";

const { Option } = Select;

class RecepcionEquipo extends React.Component {
    state = {
        colores: [],
        modelos: [],
        encendido: 'e',
        pattern: ''
    };

    componentWillUnmount() {
    let fields_to_redux = this.props.form.getFieldsValue()
    this.props.onSaveRecepcionFieldsData(fields_to_redux) // Guarda la data en redux para poder volver a cargarla en el formato de antd
  }

    componentDidMount() {
        if(!this.props.ventaEdit){
            this.props.form.setFieldsValue(this.props.recepcionEquipoPreDataObj)
            //let str_pattern = this.props.pattern.toString() //UNCOMMENTED
            //let pattern = JSON.parse(str_pattern) //UNCOMMENTED
            // let pattern = localStorage.getItem(
            //     "savedDrawing"
            // );

            let patt_from_redux = this.props.pattern //COMMENTED
            //console.log(pattern, 'pattern from local storage')
            //console.log(patt_from_redux, 'patt_from_redux')
            this.setState({
                ...this.state,
                pattern: patt_from_redux //pattern
            })
        }
        this.props.onVisit();
        if(this.props.ventaEdit){
            this.props.setClick(this.handleSubmit);
        }
        axios
            .get(modelosListURL)
            .then(res => {
                this.setState({
                    ...this.state,
                    modelos: res.data
                })
            })
            .catch(err => {
                console.log(err);
            });
        axios
            .get(coloresListURL)
            .then(res => {
                this.setState({
                    ...this.state,
                    colores: res.data
                })
            })
            .catch(err => {
                console.log(err);
            });
        if(this.props.ventaEdit) {
            axios
                .get(recepcionEquipoDetailURL(this.props.recepcionID))
                .then(res => {
                    // Antd para los valores del checkbox group en un array se_recibe_con y si_se_recibe_encendido.
                    // Los tengo que normalizar porque de python llegan separados.
                    let myObject = res.data
                    //console.log(myObject, 'myobject4')
                    const { cristal_touch_roto, display_danado, no_manda_imagen, tapa_danada, marco_golpeado, ya_fue_intervenido, mojado, SIM, funda } = myObject
                    const { altavoz, auriculares, bocina, centro_de_carga, carcaza_buen_estado, camara_frontal, camara_trasera, lector_SIM, lector_de_huella, face_ID, microfono} = myObject
                    let myArray = [ "cristal_touch_roto", "display_danado", "no_manda_imagen", "tapa_danada", "marco_golpeado", "ya_fue_intervenido", "mojado", "SIM", "funda", "altavoz", "auriculares", "bocina", "centro_de_carga", "carcaza_buen_estado", "camara_frontal", "camara_trasera", "lector_SIM", "lector_de_huella", "face_ID", "microfono"]

                    let se_recibe_con = []; 
                    if (cristal_touch_roto === true) {se_recibe_con.push("cristal_touch_roto")}
                    if (display_danado === true) {se_recibe_con.push("display_danado")}
                    if (no_manda_imagen === true) {se_recibe_con.push("no_manda_imagen")}
                    if (tapa_danada === true) {se_recibe_con.push("tapa_danada")}
                    if (marco_golpeado === true) {se_recibe_con.push("marco_golpeado")}
                    if (ya_fue_intervenido === true) {se_recibe_con.push("ya_fue_intervenido")}
                    if (mojado === true) {se_recibe_con.push("mojado")}
                    if (SIM === true) {se_recibe_con.push("SIM")}
                    if (funda === true) {se_recibe_con.push("funda")}

                    let si_se_recibe_encendido = []
                    if (altavoz === true) {si_se_recibe_encendido.push("altavoz")}
                    if (auriculares === true) {si_se_recibe_encendido.push("auriculares")}
                    if (bocina === true) {si_se_recibe_encendido.push("bocina")}
                    if (centro_de_carga === true) {si_se_recibe_encendido.push("centro_de_carga")}
                    if (carcaza_buen_estado === true) {si_se_recibe_encendido.push("carcaza_buen_estado")}
                    if (camara_frontal === true) {si_se_recibe_encendido.push("camara_frontal")}
                    if (camara_trasera === true) {si_se_recibe_encendido.push("camara_trasera")}
                    if (lector_SIM === true) {si_se_recibe_encendido.push("lector_SIM")}
                    if (lector_de_huella === true) {si_se_recibe_encendido.push("lector_de_huella")}
                    if (face_ID === true) {si_se_recibe_encendido.push("face_ID")}
                    if (microfono === true) {si_se_recibe_encendido.push("microfono")}

                    for (const property in myObject) {
                        //console.log(property, 'property');
                        //console.log(myArray.includes(property), 'myArray.includes(property)');
                        if (myArray.includes(property)) {
                            delete myObject[property]
                            //console.log(myObject, 'myObject without', property)
                        } //console.log(`${property}: ${object[property]}`);
                    }

                    let pattern = JSON.parse(myObject.pattern)
                    //console.log(pattern, 'pattern from recepcion edit')
                    let normalized_object = Object.assign(myObject, {modelo: myObject.modelo.id, color: myObject.color.id, se_recibe_con, si_se_recibe_encendido })
                    this.props.form.setFieldsValue(normalized_object) //tengo que pasar solo el id del modelo y color object o hay error
                    this.setState({
                        ...this.state,
                        pattern: pattern
                    });
                })
                .catch(err => {
                    console.log(err);
                });
            //this.props.form.setFieldsValue(this.props.recepcionEquipoObj)
        }
    }

    onChangeHandler = (nombre, value) => {
        //console.log('value y nombre', value, nombre)
        this.props.form.setFieldsValue({ //Se usa esto en ves de this.setState
            [nombre]: value,
        });
        if (nombre === 'apagado_o_encendido'){
            this.setState({
                ...this.state,
                encendido: value

            })
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        var stringed_ver = JSON.stringify(this.saveableCanvas.getSaveData()) //haces el obj un string para guardarlo en la database
            //this.props.onChangePattern(stringed_ver); //UNCOMENTED
            this.onChangeHandler('pattern', stringed_ver);
            this.props.onChangePattern(this.saveableCanvas.getSaveData()); //COMMENTED
            localStorage.setItem(
                "savedDrawing",
                this.saveableCanvas.getSaveData()
            );
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                message.success(`Cambios guardados.`);
                const valores_se_recibe_con = values.se_recibe_con
                const valores_si_se_recibe_encendido = values.si_se_recibe_encendido
                valores_se_recibe_con.map(ch => {
                    return values[ch] = true
                })
                if(this.state.encendido === 'e'){
                    valores_si_se_recibe_encendido.map(ch => {
                        return values[ch] = true
                    })
                }
                //console.log('CHANGED VALUES', values)
                this.props.onChangeRecepcionEquipo(values)
                this.props.onCheckIsValidDataRecepcion(true)
                delete values.se_recibe_con;
                delete values.si_se_recibe_encendido;
                if(this.props.ventaEdit){
                axios
                    .patch(recepcionEquipoUpdateURL(this.props.recepcionID),
                        values
                    )
                    .then(res => {
                        //console.log(res)
                        //this.props.history.push(`/cliente/${res.data.id}`);
                        //this.props.history.replace(`/productos/`);
                        message.success(`Se actualizó la nota de reparación.`);
                    })
                    .catch(err => {
                        message.error(err.response.data.message);
                    });
                    this.props.closeModal()
                }
            }
            else {
                this.props.onCheckIsValidDataRecepcion(false) //Quiere decir que hay err porque el form no es válido
            }
        });
    };

    render() {
        const { colores, modelos, encendido } = this.state
        const { size, ancho } = this.props
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                <div style={{ margin: 'auto', maxWidth: ancho }}>
                    <Form layout={'vertical'} onSubmit={this.handleSubmit}>
                        <Form.Item label="Modelo">
                            {getFieldDecorator('modelo', {
                                rules: [
                                    {
                                        required: true,
                                        message: '.',
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    size={size}
                                    style={{ maxWidth: 400, textAlign: 'center' }}
                                    placeholder="Buscar modelo..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('modelo', value)}
                                    allowClear={true}
                                >
                                    {
                                        modelos.map(m => {
                                            return <Option key={m.id} value={m.id}>{m.nombre}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <div style={{ margin: '-1.5em'}}></div>
                        <Form.Item label="Color">
                            {getFieldDecorator('color', {
                                rules: [
                                    {
                                        required: true,
                                        message: '.',
                                    },
                                ],
                            })(
                                <Select
                                    size={size}
                                    showSearch
                                    style={{ maxWidth: 400, textAlign: 'center' }}
                                    placeholder="Buscar color..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('color', value)}
                                    allowClear={true}
                                >
                                    {
                                        colores.map(c => {
                                            return <Option key={c.id} value={c.id}>{c.nombre}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <div style={{ margin: '-1.5em'}}></div>
                        <Form.Item label="Se recibe con:">
                            {getFieldDecorator('se_recibe_con', {
                                initialValue: ['A', 'B'],
                            })(
                        <Checkbox.Group style={{ width: '100%' }}>
                            <Row>
                            <Col span={12}>
                                <Checkbox value="cristal_touch_roto">Cristal/Touch roto</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="display_danado">Display Dañado</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="no_manda_imagen">No manda imagen</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="tapa_danada">Tapa Dañada</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="marco_golpeado">Marco Golpeado</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="ya_fue_intervenido">Ya fue intervenido</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="mojado">Mojado</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="SIM">SIM</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="funda">Funda</Checkbox>
                            </Col>
                            </Row>
                        </Checkbox.Group>
                        )}
                        </Form.Item>
                        <div style={{ margin: '-2em'}}></div>
                        <Form.Item label="Otras observaciones del estado del equipo">
                            {getFieldDecorator('otros_se_recibe_con', {
                                rules: [
                                    {
                                        message: 'Por favor menos de 350 caracteres.',
                                        max: 350
                                    },
                                ],
                            })(<Input.TextArea placeholder="Otras observaciones..." />)}
                        </Form.Item>
                        <div style={{ margin: '-1em'}}></div>
                        <Form.Item label="¿Se recibe apagado ó encendido?">
                            {getFieldDecorator('apagado_o_encendido', {
                                rules: [
                                    {
                                        required: true,
                                        message: '.',
                                    },
                                ],
                            })(
                                <Select
                                    size={size}
                                    style={{ maxWidth: 400, textAlign: 'center' }}
                                    placeholder="Seleccionar..."
                                    optionFilterProp="children"
                                    onChange={(value) => { this.onChangeHandler('apagado_o_encendido', value); if(value === 'a'){this.onChangeHandler('botones', 'no')} }}
                                    allowClear={true}
                                >
                                    <Option key={"a"} value={"a"}>Apagado</Option>
                                    <Option key={"e"} value={"e"}>Encendido</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <div style={{ margin: '-2em'}}></div>
                        {(encendido === 'e' ?
                        <Form.Item label="Si se recibe encendido, indicar con una palomita si si funcionan los siguientes componentes:">
                            {getFieldDecorator('si_se_recibe_encendido', {
                                initialValue: [''],
                            })(
                        <Checkbox.Group style={{ width: '100%' }}>
                            <Row>
                            <Col span={12}>
                                <Checkbox value="altavoz">Altavoz</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="auriculares">Auriculares</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="bocina">Bocina</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="centro_de_carga">Centro de carga</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="carcaza_buen_estado">Carcaza buen Edo.</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="camara_frontal">Cámara frontal</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="camara_trasera">Cámara trasera</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="lector_SIM">Lector SIM</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="lector_de_huella">Lector de Huella</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="face_ID">Face ID</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="microfono">Micrófono</Checkbox>
                            </Col>
                            </Row>
                        </Checkbox.Group>
                        )}
                        </Form.Item>
                        : <p style={{fontSize: '12px'}}>Si el equipo se recibe apagado, si no se le pueden realizar pruebas ó si el equipo ha sido intervenido antes: <b>NO</b> nos hacmos responsables de cualquier <b>DAÑO PREVIO </b>
                         que tenga cualquier comoponente del equipo entregado. 
                        <b> SIN EXCEPCIONES</b>.
                        </p>)}
                        <div style={{ margin: '-0.5em'}}></div>
                        <Form.Item label="¿Funcionan todos los botones?">
                            {getFieldDecorator('botones', {
                                rules: [
                                    {
                                        required: true,
                                        message: '.',
                                    },
                                ],
                            })(
                                <Select
                                    size={size}
                                    style={{ maxWidth: 400, textAlign: 'center' }}
                                    placeholder="Seleccionar..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('botones', value)}
                                    allowClear={true}
                                >
                                    <Option key={"si"} value={"si"}>Si, todos</Option>
                                    <Option key={"no"} value={"no"}>No, no todos funcionan</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <div style={{ margin: '-2em'}}></div>
                        <Form.Item label="EN ESPECÍFICO NO FUNCIONA:">
                            {getFieldDecorator('en_especifico_no_funciona', {
                                rules: [
                                    {
                                        message: 'Por favor menos de 350 caracteres.',
                                        max: 350
                                    },
                                ],
                            })(<Input.TextArea placeholder="En específico no funciona..." />)}
                        </Form.Item>
                        <div style={{ margin: '-1.5em'}}></div>
                        <Form.Item label="Clave:" labelCol={{span:4, offset: -4}}>
                            {getFieldDecorator('clave', {
                                rules: [
                                    {
                                        message: 'Por favor menos de 20 caracteres.',
                                        max: 20
                                    },
                                ],
                            })(<Input style={{ width: '40%' }} size="small" placeholder="Clave del equipo..." />)}
                        </Form.Item>
                        <div style={{ margin: '-2em'}}></div>
                        <Form.Item label="Patrón">
                        <Button.Group>
                            <Button
                                icon="undo"
                                size="small"
                                onClick={() => {
                                this.saveableCanvas.undo();
                                }}
                            >
                            Deshacer
                            </Button>
                            <Button
                                icon="delete"
                                size="small"
                                onClick={() => {
                                this.saveableCanvas.clear();
                                }}
                            >
                            Borrar
                            </Button>
                          </Button.Group>
                    
                                {getFieldDecorator('pattern', {
                                })(<Input type="hidden" />)}
                            <CanvasDraw ref={canvasDraw => (this.saveableCanvas = canvasDraw)} imgSrc={Pattern} catenaryColor={"grey"} brushRadius={5} brushColor="#1890ff" canvasHeight={140} canvasWidth={140} saveData={this.state.pattern}/>
                        </Form.Item>
                        <p style={{fontSize: '12px', marginTop: '-2em'}}>
                        Cualquier golpe, síntoma o desperfecto no reportado por el usuario será enteramente atribuible a éste.
                        <b> SIN NOTA NO SE ACEPTAN RECLAMACIONES NI GARANTÍAS, NO HAY EXCEPCIONES.</b>
                        <br></br>
                        <br></br>
                        Entrego el equipo con las condiciones descritas en este ticket. Acepto las políticas de garantía y los términos y condiciones de <u>www.arreglatupantalla.com</u>
                        <br></br>
                        <br></br>
                        Firma:_______________________________________
                        </p>
                        {!this.props.ventaEdit &&
                        <Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Button type="primary" htmlType="submit">
                                    Guardar Recepción
                            </Button>
                            </div>
                        </Form.Item>
                        }
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        pattern: state.recepcion.pattern,
        recepcionEquipoObj: state.recepcion.recepcionEquipoObj,
        recepcionEquipoPreDataObj: state.recepcion.recepcionEquipoPreDataObj
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/productos/', 'Productos', '/producto-nuevo/', 'Nuevo Producto', 2)),
        //link1, label 1, link 2, label 2
        onChangePattern: (val) => dispatch(actions.changePattern(val)),
        onChangeRecepcionEquipo: (val) => dispatch(actions.changeRecepcionEquipo(val)),
        onCheckIsValidDataRecepcion: (val) => dispatch(actions.checkIsValidDataRecepcion(val)),
        onSaveRecepcionFieldsData: (val) => dispatch(actions.saveRecepcionFieldsData(val)), //Guarada la info para precargar los fields
    }
}

const WrappedComponent = Form.create({ name: 'producto-nuevo' })(RecepcionEquipo);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);