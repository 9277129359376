import React, { Component } from 'react';
import { Button, Icon, Select, Radio } from 'antd';
import Cliente from '../Cliente/AgregarCliente';
import * as actions from './../../../store/actions/clientes';
import { connect } from 'react-redux';

const { Option } = Select;

export class SeleccionarCliente extends Component {
  state = {
    loading: false,
    error: null,
    showSelect: true,
  };

  componentDidMount() {
    this.props.onFetchClientes();
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  onClickToogle = (e) => {
    this.setState({
      ...this.state,
      ...{ showSelect: !this.state.showSelect },
    });
  };

  render() {
    const { showSelect } = this.state;
    const { cliente, clientes } = this.props;

    return (
      <React.Fragment>
        <div style={{ textAlign: 'center' }}>
          <Radio.Group onChange={this.props.onChangeTienda} defaultValue="V">
            <Radio.Button value="V">Vapes</Radio.Button>
            <Radio.Button value="A">Arregla</Radio.Button>
          </Radio.Group>
          <br></br>
          <br></br>
          <Button onClick={this.onClickToogle} type="primary">
            {showSelect ? <Icon type="user-add" /> : <Icon type="search" />}
          </Button>
          <br></br>
          <br></br>
          {
            showSelect ? (
              <Select
                showSearch
                style={{ maxWidth: 400, minWidth: 250, textAlign: 'center' }}
                placeholder="Buscar cliente..."
                optionFilterProp="children"
                onChange={this.props.onChangeCliente}
                value={cliente}
                allowClear={true}
                autoFocus={true}
              >
                {clientes.map((c) => {
                  return (
                    <Option key={c.id} value={c.id}>
                      {c.nombre}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              <Cliente
                showSelect={showSelect}
                onClickToogle={this.onClickToogle}
                continuar={() => this.props.nextStep()}
              ></Cliente>
            ) //nextStep() es para que no se eneseñe el valor del cliente, sino se enseña el id y no el nombre, cuando cambias de paso ya se enseña bien el nombre
          }
          {/* <br></br>
                    <br></br>
                    {(showSelect ?
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.continue}
                        >
                            Continuar <Icon type="right" />
                        </Button>
                        : '')} */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientes: state.cliente.clientes,
    cliente: state.cliente.cliente,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchClientes: () => dispatch(actions.loadClientesData()),
    onChangeCliente: (value) => dispatch(actions.changeCliente(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeleccionarCliente);
