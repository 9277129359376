const localhost = 'https://b.arreglatupantalla.com';

const apiURL = '/api';

export const endpoint = `${localhost}${apiURL}`;

export const productosListURL = `${endpoint}/productos/`;
export const productoNuevoURL = `${endpoint}/producto-nuevo/`;
export const productoDetailURL = (id) => `${endpoint}/productos/${id}/`;
export const productoUpdateURL = (id) => `${endpoint}/productos/${id}/editar/`;
export const ventaProductoListURL = `${endpoint}/venta-producto/`;
export const ventasVentaProductoProductosUrl = `${endpoint}/ventas/venta-producto/productos-field/`;
export const DevGarVentaProductoURL = `${endpoint}/dev-gar-ventaproducto/`;
export const modelosListURL = `${endpoint}/modelos/`;
export const coloresListURL = `${endpoint}/colores/`;

export const recepcionEquipoURL = `${endpoint}/recepcion-equipo/`;
export const recepcionEquipoListURL = `${endpoint}/recepciones-de-equipos/`;
export const recepcionEquipoUpdateURL = (id) =>
  `${endpoint}/recepcion-equipo/${id}/editar/`;
export const recepcionEquipoDetailURL = (id) =>
  `${endpoint}/recepcion-equipo/${id}/`;

export const ventasListURL = `${endpoint}/ventas/`;
export const VentaDetailURL = (id) => `${endpoint}/ventas/${id}/`;
export const ventaEditarURL = (id) => `${endpoint}/ventas/${id}/editar/`;
export const ventaNuevaURL = `${endpoint}/venta-nueva/`;

export const clientesListURL = `${endpoint}/cliente/`;
export const clienteURL = `${endpoint}/agregar-cliente/`;
export const DetalleClienteURL = (id) => `${endpoint}/cliente/${id}/`;
export const clienteEditarURL = (id) => `${endpoint}/cliente/${id}/editar/`;

export const proveedoresListURL = `${endpoint}/proveedores/`;
export const proveedorNuevoURL = `${endpoint}/agregar-proveedor/`;
export const proveedorEditarURL = (id) => `${endpoint}/proveedor/${id}/editar/`;
export const proveedorDetailURL = (id) => `${endpoint}/proveedor/${id}/`;

export const pedidoNuevoURL = `${endpoint}/pedido-nuevo/`;
export const pedidoListURL = `${endpoint}/pedidos/`;
export const PedidoDetailURL = (id) => `${endpoint}/pedidos/${id}/`;
export const pedidoEditarURL = (id) => `${endpoint}/pedidos/${id}/editar/`;

export const categoriasListURL = `${endpoint}/categorias/`;
export const marcasListURL = `${endpoint}/marcas/`;
export const saboresListURL = `${endpoint}/sabores/`;
export const nicotinasListURL = `${endpoint}/nicotinas/`;
export const FormasDePagoURL = `${endpoint}/formas-de-pago/`;
export const promocionesListURL = `${endpoint}/promociones/`;

export const ConceptosDePagoURL = `${endpoint}/conceptos-de-pago/`;
export const agregarConceptoURL = `${endpoint}/agregar-concepto/`;

export const PagosURL = `${endpoint}/pagos/`;
export const pagoDetailURL = (id) => `${endpoint}/pagos/${id}/`;
export const pagoEditarURL = (id) => `${endpoint}/pagos/${id}/editar/`;
export const pagoNuevoURL = `${endpoint}/pago-nuevo/`;
export const traspasoDeFondosURL = `${endpoint}/traspaso-de-fondos/`;
export const CreditoProveedorViewURL = `${endpoint}/credito-proveedor-view/`;

export const solicitudGarantiaURL = `${endpoint}/solicitud-de-garantias-nueva/`;
export const garantiasListURL = `${endpoint}/garantias-list/`;
export const garantiaDetailURL = (id) =>
  `${endpoint}/solicitud-de-garantias/${id}/`;
export const garantiaEditarURL = (id) =>
  `${endpoint}/solicitud-de-garantias/${id}/editar/`;
export const recibirGarantiaProductoURL = `${endpoint}/recibir-garantia-producto/`;

//Reportes
export const BalanceAlDiaURL = `${endpoint}/balance-al-dia/`;
