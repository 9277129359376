import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import axios from 'axios';

import authReducer from "./store/reducers/auth";
import bcrumbsReducer from "./store/reducers/breadcrumbs";
import clienteReducer from "./store/reducers/clientes";
import productoReducer from "./store/reducers/productos";
import pagoReducer from "./store/reducers/pago";
import proveedorReducer from "./store/reducers/proveedores";
import recepcionReducer from "./store/reducers/recepciones";


const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  auth: authReducer,
  bcrumbs: bcrumbsReducer,
  cliente: clienteReducer,
  producto: productoReducer,
  pago: pagoReducer,
  proveedor: proveedorReducer,
  recepcion: recepcionReducer
});

const rootReducer = (state, action) => { //para poner initial state, necesario para refrescar el state despues de una venta.
  if (action.type === 'RESET_REDUX_STATE') {
    state.producto.productosVenta = [];
    state.producto.promosArray = [];
    state.cliente.cliente = 'Buscar cliente...';
    state.producto.producto = 'Buscar producto...';
    state.producto.valorTotal = 0;
    state.pago.formasArray = [];
    state.pago.totalFormasDePago = 0;
    state.pago.formaSelec = 'Seleccionar forma de pago...';
    state.producto.productosPedido = [];
    state.proveedor.proveedor = '';
    state.proveedor.comentarios = '';
    state.cliente.comentarios = '';
    state.producto.productosGarantia = [];
    state.recepcion.pattern = '';
    state.recepcion.recepcionEquipoObj = {};
    state.recepcion.recepcionEquipoPreDataObj = {};
    state.recepcion.conRecepcion = false;
    state.recepcion.isValidDataRecepcion = false;
  }

  if (action.type === 'RESET_PERMISSIONS_STATE') {
    state.auth.permissions = {ver_reporte_ventas: false, ver_reporte_ingresos_e_inventarios: false};
  }

  return appReducer(state, action)
}

const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));

//Agrega los headers a todos los requests, siempre y cuando haya un token
//Así te evitas problemas al refrescar la página siempre tienen autorización
let token_ls = localStorage.getItem("token")
if(token_ls !== null) {
  axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token_ls}`
          }
  }

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
registerServiceWorker();
