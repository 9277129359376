export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";

export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';

export const NEW_LOADED_PAGE = 'NEW_LOADED_PAGE';
export const ERROR = 'ERROR';

export const LOAD_CLIENTES_DATA = 'LOAD_CLIENTES_DATA';
export const CHANGE_CLIENTE = 'CHANGE_CLIENTE';
export const CHANGE_COMENTARIOS_CLIENTE = 'CHANGE_COMENTARIOS_CLIENTE';


export const LOAD_PRODUCTOS_DATA = 'LOAD_PRODUCTOS_DATA';
export const HANDLE_SELECT_PRODUCTO = 'HANDLE_SELECT_PRODUCTO';
export const CONCAT_PRODUCTO_VENTA = 'CONCAT_PRODUCTO_VENTA';
export const DELETE_ROW_PRODUCTO_VENTA = 'DELETE_ROW_PRODUCTO_VENTA';
export const CHANGE_PRODUCTO_VENTA_STATE = 'CHANGE_PRODUCTO_VENTA_STATE';
export const CALCULAR_VALOR_TOTAL = 'CALCULAR_VALOR_TOTAL';
export const LOAD_PROMO_DATA = 'LOAD_PROMO_DATA';
export const HANDLE_SELECT_PROMO = 'HANDLE_SELECT_PROMO';
export const CHANGE_PROMOS_ARRAY_STATE = 'CHANGE_PROMOS_ARRAY_STATE';
export const CHANGE_PEDIDO_PRODUCTO_STATE = 'CHANGE_PEDIDO_PRODUCTO_STATE';
export const RESET_PRODUCTOS_STATE = 'RESET_PRODUCTOS_STATE';

export const CHANGE_PATTERN = 'CHANGE_PATTERN';
export const CHANGE_RECEPCION_EQUIPO = 'CHANGE_RECEPCION_EQUIPO';
export const RECEPCION_EQUIPO_ID = 'RECEPCION_EQUIPO_ID';
export const CON_RECEPCION = 'CON_RECEPCION';
export const IS_VALID_DATA_RECEPCION = 'IS_VALID_DATA_RECEPCION';
export const SAVE_RECEPCION_FIELDS_DATA = 'SAVE_RECEPCION_FIELDS_DATA';

export const CHANGE_GARANTIA_PRODUCTO_STATE = 'CHANGE_GARANTIA_PRODUCTO_STATE';

export const LOAD_FORMAS_DATA = 'LOAD_FORMAS_DATA';
export const CALCULAR_TOTAL_A_PAGAR = 'CALCULAR_TOTAL_A_PAGAR';
export const CHANGE_FORMAS_ARRAY_STATE = 'CHANGE_FORMAS_ARRAY_STATE';
export const CHANGE_FORMA_SELEC = 'CHANGE_FORMA_SELEC';

export const LOAD_PROVEEDORES_DATA = 'LOAD_PROVEEDORES_DATA';
export const CHANGE_PROVEEDOR = 'CHANGE_PROVEEDOR';
export const CHANGE_COMENTARIOS_PROVEEDOR = 'CHANGE_COMENTARIOS_PROVEEDOR';





