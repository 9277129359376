import React from 'react';
import { Divider, Collapse } from 'antd';
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
const { Panel } = Collapse;

class Instrucciones extends React.Component {
    componentDidMount() {
        this.props.onVisit();
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <h3 style={{ textAlign: 'left' }}>Instrucciones <i>y dudas sobre la app</i> </h3>
                    <Divider />
                </div>
                <Collapse>
                    <Panel header="PEDIDOS" key="1">
                    <Collapse accordion>
                        <Panel header="Casos pedidos y garantías (con el proveedor)" key="1">
                        <ol>
                        <li>Se mandan piezas para garantía:</li>
                            <span>-El proveedor manda piezas</span> <br></br>
                            <span>-El proveedor rechaza las garantías (no aplicaron)</span> <br></br>
                            <span>-El proveedor da crédito.</span>
                        <br></br>
                        <br></br>
                        <li>Hay errores en la recepción de mercancía:</li>
                            <span>-No debería de haber. Se supone que es mercancía que se recibe físicamente. En caso de que haay un error se me avisa y yo: hago la modificación y resto la cantidad mal de stock (que debe de estar ahí) </span>
                        <br></br>
                        <br></br>
                        <li>Una pieza sale defectuosa del pedido:</li>
                            <span>-Se mandan a garantías -> se hace una solicitud de garantías -> Se puede abonar crédito o dinero (saldo favorable con el proveedor) ó cambiar por una pieza ó sino aplica la garantía se rechaza.</span>
                        <br></br>
                        <br></br>
                        <li>De plano es una pieza que no se va a usar y no se va a pagar.</li>
                            <span>-Me dicen y yo puedo hacer la modificación (eliminar la pieza del pedidoproducto y del stock, como si nunca hubiera entrado)</span>
                        </ol>
                        </Panel>
                        <Panel header="Consideraciones en PEDIDOS" key="2">
                            <ul>
                                <li>No se permiten hacer "recepciones de mercancía" con piezas negativas.</li>
                            </ul>
                            </Panel>
                    </Collapse>
                    </Panel>
                    <Panel header="PRODUCTOS" key="2">
                        <Collapse accordion>
                            <Panel header="¿Cómo creo un producto nuevo?" key="1">
                                <p>Desde "Productos" -> "+ Producto". Los campos de cada producto como "Marca, Categoría, Modelo, Sabor y % Nicotina." Se deben de crear antes en "Admin -> Panel de Admin"</p>
                                <p>Una vez que creas uno de estos campos tienes que refrescar la página "Nuevo Producto" para que te aparezcan las opciones que creaste.</p>
                            </Panel>
                            <Panel header="¿Cómo cambio el costo y precio de venta de un producto?" key="2">
                                <p>El "costo sugerido" y "precio de venta" de un producto que aparecen por default en "pedidos" y "caputrar venta" se pueden editar para cada producto desde el detalle del producto.</p>
                            </Panel>
                        </Collapse>
                    </Panel>
                    <Panel header="VENTAS" key="3">
                        <Collapse accordion>
                            <Panel header="Consideraciones en CAPTURAR VENTA" key="1">
                            <ul>
                                <li>Si se capturan ventas con productos con stock en "0" el stock no se va a agregar a la venta. Y si todos los productos eran con stock"0" se creará una venta con sin productos (estos se pueden agregar después cuando haya stock).</li>
                                <br></br>
                                <li>Si se hizo una venta y todavía no se tenía la pieza se puede meter como "Servicio" y cuando llegue el producto actualizar la venta (desde el detalle de venta meter el producto que no tenías antes).</li>
                                <br></br>
                                <li>Recuerda que si la venta lleva una "Recepción de equipo" debes de ingresar los datos del telefono al momento de crear la venta, después no podrás. Tendrías que crear una venta nueva.</li>
                            </ul>
                            </Panel>
                            <Panel header="Casos garantías y devoluciones (con el cliente)" key="2">
                                <ol>
                                <li>Se regresa el dinero al cliente y se regresa la pieza a inventario:</li>
                                    <b><i>Los puntos de abajo se hacen desde el detalle de la venta.</i></b> <br></br>
                                    <span>-Dar clic en flecha roja "Aplicar Devolución" y se regresa la pieza a stock.</span> <br></br>
                                    <span>-Da clic en "Aplicar Pago" y aplica un pago negativo (o sea devolución).</span> <br></br>
                                    <span>-NOTA*: si además la pieza está defectuosa después de aplicar la devolución desde el detalle de la venta, se va al detalle de producto y se da click en botón "pieza defectuosa" para mandarla a garantías (pero es importante que si es devolución primero se haga la devolución).</span>
                                <br></br>
                                <br></br>
                                <li>Se cambia pieza defectuosa y se le pone una nueva (o sea se aplica una garantía)</li>
                                <b><i>Los puntos de abajo se hacen desde el detalle de la venta.</i></b> <br></br>
                                    <span>-Dar clic en triangulo amarillo "Enviar a garantía" </span> <br></br>
                                    <span>-Hay dos opciones para dar la nueva pieza de garantía de baja:</span> <br></br>
                                    1. Desde esa misma venta poner "agregar producto" seleccionar la pieza que va a ser garantía y ponerle costo 0. Se puede "Editar Reparación" para editar el estado del teléfono.
                                    2. Crear una venta nueva y dar de baja la pieza correspondiente con costo cero, al seleccionar la promoción poner "Garantía".
			                        -Cualquiera de las dos opciones es viable porque se guarda todo en el historial del cliente que se puede consultar desde el detalle del cliente.
                                <br></br>
                                <br></br>
                                <li> Una devolución por ajuste de un costo</li>
                                    <span>-Se mete una devolución de producto total desde el detalle de venta y "agregar productos" (agregar otra vez el mismo producto pero con un costo diferente. Así todo cuadra).</span>
                                <br></br>
                                <br></br>
                                <li>Hay una pieza defectuosa en stock</li>
                                    <span>-Esta pieza no necesariamente se instaló con un cliente. Desde que se probó salió mala. </span><br></br>
                                    <span>-En el detalle del producto dar clic en "Pieza Defectuosa" y mandarla a garantía.</span>
                                </ol>
                        </Panel>
                            <Panel header="¿Qué pasa si un equipo regresa para aplicar una garantía?" key="3">
                                <p>¿Se mete una nueva venta o se edita la que ya tenía? Idelamente se le hace una nueva nota, aunque también se podría editar la venta existente.</p>
                                <p>De cualquier manera se queda todo guardado en el historial del cliente. Pero creo que sería bueno tener un estándar, idealmente crear una nota nueva y poner costo cero y en promoción poner garantía</p>
                            </Panel>
                            <Panel header="¿Debo de anotar el nombre del cliente cuando es una venta de Vapes?" key="4">
                                <p>No necesariamente, puedes dar de alta un cliente "Venta General". Y usar a este cliente simepre que solo compren vapes. Idealmente tratar de dar de alta al cliente con su correo para poder mandarles campañas y promociones.</p>
                            </Panel>
                            <Panel header="¿Porque en el ticket a veces se imprime el logo de Vapes y a veces el de Arregla?" key="5">
                                <p>Depende. Si hay una reparación siempre va a salir el de Arregla. Sino hay nada de Arregla siempre sale el de Vapes y si hay de los dos depende de cual haya más.</p>
                            </Panel>
                        </Collapse>
                    </Panel>
                    <Panel header="REPARACION" key="4">
                        <Collapse accordion>
                            <Panel header="Consideraciones en una RECEPCION DE EQUIPO" key="1">
                            <ul>
                                <li>Si hacen algún cambio en la "Recepción de equipo" no olviden dar clic en "Guardar recepción" o no se guardarán los cambios.</li>
                                <br></br>
                                <li>Al editar una reparación si das clic en botón "Editar reparación" y vuelves a cerrar el cuadro no te vuelve a aparecer el "Patrón de la clave", si quieres verlo tienes que refrescar la página (ctrl + R).</li>
                                <br></br>
                                <li>Puedes agregar todos los productos a una venta que quieras, pero solo puedes agregar una "Recepción de equipo" por cada venta.</li>
                                <br></br>
                                <li>Solo puedes agregar una "Recepción de equipo" al inicio de "capturar venta" si por algo no la agregaste, crea una venta nueva y cancela la otra (haciendo devoluciones de producto. En el historial queda que se devolvió ese producto).</li>
                                <br></br>
                                <li>El servicio a domicilio se registra como un producto de la categría "Servicios" "Domicilio". El proveedor es "ArreglaTuPantalla".</li>
                            </ul>
                            </Panel>
                        </Collapse>
                    </Panel>
                    <Panel header="ADMIN" key="5">
                        <Collapse accordion>
                            <Panel header="¿Cómo se da de baja el dinero cuando Diego se lo lleva?" key="1">
                                <p>Se da de baja como "Traspaso" de "Efectivo" a "Cuenta Central"</p>
                            </Panel>
                            <Panel header="Conceptos de pago" key="2">
                            <ul>
                                <li>Detalle Pedido SE REGISTRA EN AUTOMÁTICO COMO "pago de pedidos".</li>
                                <br></br>
                                <li>Detalle Venta SE REGISTRA EN AUTOMÁTICO COMO "venta de producto".</li>
                                <br></br>
                                <li>Admin -> Capturar ingreso o gasto SE SELECCIONA EL CONCEPTO.</li>
                                <br></br>
                                <li>Capturar venta (el concepto es directo "venta de producto").</li>
                                <br></br>
                                <li>Traspaso de fondos SE REGISTRA EN AUTOMÁTICO COMO "traspaso de fondos".</li>
                                <br></br>
                                <li>Se pueden dar de alta todos los conceptos de pago/gasto necesarios.</li>
                            </ul>
                            </Panel>
                            <Panel header="'Forma de pago' y 'Cuenta' es lo mismo" key="3" showArrow={false}>
                            </Panel>
                        </Collapse>
                    </Panel>
                    <Panel header="REPORTES" key="6">
                        <Collapse accordion>
                            <Panel header="Reportes en general" key="1">
                                <p>Si la descripción del producto ya no cabe en el pie chart, si haces la ventana del navegador más grande se acomoda y caben. Si la ventana ya esta al máximo agarras las flechas de la esquina y le haces zoom hasta que el pie sea del tamaño de la ventana.</p>
                            </Panel>
                            {this.props.perms['cosas_de_socios'] === true &&
                            <Panel header="Reporte Resumen del día *SOLO SOCIOS VEN ESTA INFO" key="2">
                                <ul>
                                    <li>No se incluyen traspasos en "Total Ingresos del día: 0 | Total Egresos del día: -1,950"</li>
                                    <br></br>
                                    <li>Si se incluyen traspasos en tabla "Total Ingresos - Egresos del día".</li>
                                    <br></br>
                                    <li>Si se incluyen traspasos en tabla "Ingresos/Gastos del día" pero Oscar solo puede ver los que el ó Rodolfo registraron.</li>
                                </ul>
                            </Panel>
                            }
                            {this.props.perms['cosas_de_socios'] === true &&
                            <Panel header="Reporte de Ventas *SOLO SOCIOS VEN ESTA INFO" key="3">
                                <ul>
                                    <li>Oscar si tiene acceso (para que conozca que se mueve de inventario).</li>
                                    <br></br>
                                    <li>Hasta abajo se puede ver las ventas reales del periodo en dinero para calcular comisiones de Oscar.</li>
                                    <br></br>
                                    <li>El pie "Modelo" enseña las ventas ACUMULADAS DE CADA MODELO, así se SUMAN todas las ventas de "iPhone 6 negro C, iPhone6 blanco A, iPhone 6 negro R, etc. en una sola "iPhone 6". Este pie solo se muestra cuando esta el filtro de "arregla".</li>
                                    <br></br>
                                    <li>El valor de "Valor Ventas" arriba de la tabla principal, es lo que valen las ventas sin promociones. Si se generó una venta y después se hizo una devolución el valor de la devolución NO se resta de. "Valor Ventas". No está contemplado en este valor. Este valor tampoco contempla lo que ya se ha pagado.</li>
                                    <br></br>
                                    <li>El "Valor Ventas" e "Ingresos netos" no necesariamente cuadran porque puede ser que se hayan pagado ventas registradas en un periodo diferente al que esta seleccionado..</li>
                                    <br></br>
                                    <li>Las "cuentas por cobrar" hasta abajo son solo sobre ese periodo. Contemplan las devoluciones, o sea si hubo una devolución no se considera como una cuenta por cobrar.</li>
                                    <br></br>
                                    <li>Los "Ingresos netos" son el "total de ingresos del periodo menos las devoluciones del periodo" solo contempla el concepto "venta de producto" para ingresos y devoluciones.</li>
                                </ul>
                            </Panel>
                            }
                            {this.props.perms['cosas_de_socios'] === true &&  
                            <Panel header="Reporte Ingresos y Egresos *SOLO SOCIOS VEN ESTA INFO" key="4">
                                <ul>
                                    <li>No se incluyen traspasos de fondos en las gráficas ni en "Total ingresos egresos del periodo".</li>
                                    <br></br>
                                    <li>Solo se incluyen traspasos de fondos en tabla de abajo "Repartos y utilidades del periodo".</li>
                                </ul> 
                            </Panel>
                            }
                        </Collapse>
                    </Panel>
                    {this.props.perms['cosas_de_socios'] === true &&
                    <Panel header="UTILIDADES *SOLO SOCIOS VEN ESTA INFO" key="7">
                        <Collapse accordion>
                            <Panel header="¿Dónde vienen las utilidades?" key="1">
                                <p>Se pueden encontrar en la tabla de hasta abajo del "Reporte Ingresos y Egresos".</p>
                            </Panel>
                            <Panel header="¿Cómo se hacen los repartos?" key="2">
                                <ul>
                                    <li>Siempre por medio de un "Traspaso de fondos".</li>
                                    <br></br>
                                    <li>De la "Cuenta central" se pasan a la cuenta "Diego" o cuenta "Roberto".</li>
                                    <br></br>
                                    <li>Puede ser directo de "Efectivo" o "Mercado Pago" pero siempre por medio de un "Traspaso" para que se reste de donde sale y se sume a donde llega.</li>
                                </ul>
                            </Panel>
                            <Panel header='¿Que pasa si "Roberto" o "Diego" pagan algo por el negocio como publicidad, contabilidad o cualquier cosa? ¿Dónde lo registran?' key="3">
                                <p>Se mete como un "Gasto" o "Egreso" normal, desde el botón "Admin -> Capturar Ingreso o Gasto" seleccionado la cuenta "Roberto" o "Diego" (signo negativo al principio, por ejemplo: "-200 pesos").</p>
                            </Panel>
                            <Panel header='¿Puede Oscar o algún vendedor ver los movimientos de utilidades?' key="4">
                                <p>No. Todos los traspasos de fondos o pagos registrados por nosotros no los ven. Y cómo nootros nadamás registramos utilidades o cosas que hemos metido al negocio, no importa que no lo vean. Todo lo relacionado con operación del negocio lo registran ellos y eso lo podemos ver todos.</p>
                            </Panel>
                        </Collapse>
                    </Panel>
                        }
                </Collapse>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
  return {
    perms: state.auth.permissions
  };
};

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/instrucciones/', 'Instrucciones', '/', '', 1))
        //link1, label 1, link 2, label 2
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Instrucciones);