import React from 'react';
import { PedidoDetailURL, pedidoEditarURL, CreditoProveedorViewURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { Icon as IconUI } from 'semantic-ui-react';
import { Button, Divider, Form, Table, Input, Icon, message, Modal, InputNumber, Tag } from 'antd';
import { Link } from 'react-router-dom';
import ModalPago from './../Pagos/ModalPago';

const columnsPed = [
    {
        title: 'ID Pedido Poducto',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Producto',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
    },
    {
        title: 'Precio',
        dataIndex: 'precio',
        key: 'precio',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
    },
];

const columnsPago = [
    {
        title: 'Forma de Pago',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: 'Importe',
        dataIndex: 'importe',
        key: 'importe',
    },
    {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
    },
];

class PedidoDetail extends React.Component {
    state = {
        data: null,
        visibleCredito: false,
        montoCredito: 0
    };

    loadPedidoData = () => {
        const { match: { params } } = this.props;
        this.props.onVisit(params)
        //console.log(params)
        axios
            .get(PedidoDetailURL(params.pedidoID))
            .then(res => {
                this.setState({
                    data: res.data
                });
                this.props.form.setFieldsValue({
                    nota: res.data.nota,
                });
            })
            .catch(err => {
                console.log(err)
                //this.setState({ error: err });
            });
    }

    componentDidMount() {
        this.loadPedidoData()
    }

    handleSubmit = e => {
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                axios
                    .put(pedidoEditarURL(params.pedidoID), {
                        nota: this.props.form.getFieldsValue().nota
                    })
                    .then(res => {
                        //console.log(res)
                        //console.log(values, 'values')
                        this.props.history.push(`/pedidos/${res.data.id}/`);
                        message.success('¡Se modificó la nota con éxto!');
                    })
                    .catch(err => {
                        console.log(err)
                        message.error('Hubo un error.');
                    });
            }
        });
    };

    showModalCredito = (nombre) => {
        this.setState({
            ...this.state,
            ...{
                [nombre]: true,
            }
        });
    };

    handleCancel = (nombre, e) => {
        this.setState({
            ...this.state,
            [nombre]: false
        });
    };

    handleChange = (nombre, e) => { // en antd pasa el arg sin hacer e.target.value...
        this.setState({ //lo reuso para pago y devolucion piezas
            ...this.state,
            ...{ [nombre]: e } // "e" es la "cantidad" o el "monto", dependiendo de donde se llame la función.
        });
    };

    handleOkCredito = (e) => {
        axios
            .post(CreditoProveedorViewURL + '?aplicarcred=si', { //You can also use post method to update
                proveedor: this.state.data.proveedor.id,
                pedido_id: this.state.data.id,
                monto: this.state.montoCredito
            })
            .then(res => {
                //this.props.replace(`/ventas/`); //tengo que pasar history como props porque "Confirm.js" no es parte deel Router como tal, solo "VentaForm.js"
                this.setState({
                    ...this.state,
                    ...{
                        visibleCredito: false,
                        montoCredito: 0                  
                    }
                });
                this.loadPedidoData() //Que se actualize
                message.success(res.data.message);
            })
            .catch(err => {
                //console.log(err.response, 'ERRRROR')
                //console.log(err)
                message.error(err.response.data.message);
            });
        }

    accessChild = () => {
        this.refs.child.showModalPago()
    }
 
    render() {
        const { getFieldDecorator } = this.props.form;
        const { data } = this.state;
        if (data === null) {
            return null;
        }

        // let pedidos = {
        //     producto: 'asdf',
        //     cantidad: 5,
        //     precio: 10,
        //     total: 50
        // }
        let pedidos = data.pedido_producto.map(pp => {
            return (
                {
                    key: pp.id,
                    id: pp.id,
                    nombre: pp.producto.nombre,
                    cantidad: pp.cantidad,
                    precio: pp.precio_unitario,
                    total: pp.total
                }
            )
        });

        let formasB = data.pago.map(p => {
            return (
                {
                    key: p.id,
                    id: p.id,
                    nombre: p.forma_de_pago.nombre,
                    importe: p.monto,
                    fecha: p.fecha
                }
            )
        });

        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>
                    Pedido ID: {data.id} <span style={{marginLeft: '0.3em'}}></span> <Tag color={(data.status_pagos === "Pagado" ? "green" : "magenta")}>{data.status_pagos}</Tag>
                </h3>
                <Divider />
                <div style={{ textAlign: 'center' }}>
                    <div style={{ maxWidth: '700px', margin: 'auto' }}>
                        <h4 style={{ fontSize: '1.2em' }}><b>Proveedor: </b> <Link to={`/proveedores/${data.proveedor.id}`}>{data.proveedor.nombre}</Link> </h4>
                        <p style={{ fontSize: '1.2em' }}><b>Valor Pedido:</b> {data.valor_pedido} <b style={{ marginLeft: '0.8em' }}> Saldo Pendiente:</b> {data.saldo_pendiente} </p>
                        <p><b>Fecha:</b> {data.fecha}</p>
                        <Button
                            onClick={() => this.clickChild()}>
                            <IconUI name='payment' /> Aplicar Pago
                        </Button>
                        <span style={{marginLeft: '0.5em'}}></span>
                        {this.state.data.proveedor.balance_credito > 0 &&
                        <Button type="primary" onClick={() => this.showModalCredito('visibleCredito')}>
                            Usar Crédito
                        </Button>
                        }
                        <br></br>
                        <br></br>
                        <Form labelCol={{}} wrapperCol={{}} onSubmit={this.handleSubmit}>
                            {/* <Input.TextArea placeholder="Numero de nota y comentarios*" onChange={(e) => this.handleChangeTa(e)} maxLength={300} style={{ height: 50 }} value={comentarios} /> */}
                            <Form.Item>
                                {getFieldDecorator('nota', {
                                })(<Input.TextArea placeholder="Numero de nota y comentarios*" maxLength={350} />)}
                                <Button
                                    size='small'
                                    variant="contained"
                                    onClick={this.handleSubmit}>
                                    <Icon type="save" /> Guradar Comentarios
                            </Button>
                            </Form.Item>
                        </Form>
                        <Table title={() => 'Pagos y Devoluciones'} columns={columnsPago} dataSource={formasB} bordered={true} pagination={false} size="small" locale={{ emptyText: 'No hay pagos' }} />
                        <Table title={() => 'Productos'} columns={columnsPed} dataSource={pedidos} bordered={true} pagination={false} size="medium" locale={{ emptyText: 'No hay productos' }} />
                    </div>
                </div>
                <ModalPago title="Registrar un pago al proveedor" instrucciones="un pago al proveedor" gasto="un PAGO AL PROVEEDOR, es una salida de dinero de nuestra cuenta." ingreso="una DEVOLUCION DEL PROVEEDOR, el proveedor nos paga, es un ingreso para nosotros." pedido_id={this.state.data.id} load={() => this.loadPedidoData()} setClick={click => this.clickChild = click} />
                <Modal
                            title="Usar Crédito"
                            visible={this.state.visibleCredito}
                            onOk={this.handleOkCredito}
                            onCancel={() => this.handleCancel('visibleCredito')}
                            cancelText="Cancelar"
                            okText="Aplicar Crédito"
                        >
                            <div style={{ textAlign: 'center' }}>
                                <p>Crédito que dio el proveedor en dinero en lugar de mandarnos piezas de garantías. Una especie de saldo a favor que tenemos. <i style={{color: 'red'}}> Ingresa el monto de crédito a utilizar con signo negativo.</i> </p>
                                <p><b>Crédito Disponible: </b>$ {this.state.data.proveedor.balance_credito}</p>
                                Monto: <InputNumber value={this.state.montoCredito} onChange={(e) => this.handleChange('montoCredito', e)} style={{ width: '25%' }} />
                                <p></p>
                            </div>

                        </Modal>
            </React.Fragment >
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/pedidos/', 'Pedidos', `/pedidos/${params.pedidoID}/`, 'Detalle de Pedido', 2))
        //link1, label 1, link 2, label 2
    }
}

//export default connect(null, mapDispatchToProps)(VentaDetail);

const WrappedApp = Form.create({ name: 'editar-venta' })(PedidoDetail);
export default connect(null, mapDispatchToProps)(WrappedApp);