import React from 'react';
import Highlighter from 'react-highlight-words';
import { newPage } from '../../store/actions/breadcrumbs';
import { connect } from 'react-redux';
import { Input, Button, Icon, Table, Tag } from 'antd';
//import { connect } from 'react-redux';
//import { initProdutos } from "../../store/actions/core"
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ventasListURL } from '../../constants';

class Ventas extends React.Component {
  state = {
    searchText: '',
    loading: false,
    error: null,
    data: [],
  };

  componentDidMount() {
    console.log(this.props, 'this.props');
    console.log(this.props.desdeSiempre, 'this.props.desdeSiempre');

    if (!this.props.otroLado) {
      this.props.onVisit();
    }
    this.setState({ loading: true });
    let ventas_url_fechas_check =
      this.props.desdeSiempre === true
        ? ventasListURL
        : ventasListURL + '?ultimomes=1';
    let get_call =
      this.props.clienteID > 0
        ? ventasListURL + '?cliente=' + this.props.clienteID
        : ventas_url_fechas_check;
    axios
      .get(get_call)
      .then((res) => {
        //console.log(res.data)
        let ventas = res.data.map((venta) => {
          let productos = venta.productos.map(
            (p) => p.producto.nombre + ' (' + p.cantidad + ')'
          );
          //let pagos = venta.pago.map(p => p.monto)
          let trimmed_nota =
            venta.nota.length > 15
              ? venta.nota.slice(0, 15) + ' ...'
              : venta.nota;
          return {
            key: Math.random(),
            id: venta.id,
            cliente: venta.cliente.nombre,
            recepcion_equipo: venta.recepcion_equipo.status,
            productos: productos,
            nota: trimmed_nota, //comentarios
            //pago: venta.sumatoria_pagos.toLocaleString(),
            pago: venta.status_pagos.toLocaleString(),
            internet: venta.internet,
            usuario: venta.usuario,
            fecha: venta.fecha,
          };
        });
        this.setState({
          data: ventas,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
  }

  //EMPIEZAN FILTROS PARA SEARCH
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Buscar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  //TERMINAN FILTROS PARA SEARCH

  //FILTROS FILTER
  handleChange = (pagination, filters) => {
    //console.log('Various parameters', pagination, filters);
    this.setState({
      filteredInfo: filters,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  //TERMINAN FILTROS FILTER

  render() {
    let { filteredInfo, loading } = this.state;
    filteredInfo = filteredInfo || {};

    let obj_col_id_venta = this.props.otroLado //Checando si viene desde el cliente detail, entonces cambiar referencia en el link
      ? {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          ...this.getColumnSearchProps('id'),
          render: (text, record) => <Link to={'/ventas/' + text}>{text}</Link>,
        }
      : {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          ...this.getColumnSearchProps('id'),
          render: (text, record) => <Link to={record.id + '/'}>{text}</Link>,
        };

    const columns = [
      obj_col_id_venta,
      {
        title: 'Cliente',
        dataIndex: 'cliente',
        key: 'cliente',
        ...this.getColumnSearchProps('cliente'),
      },
      {
        title: 'Produtos (cantidad)',
        dataIndex: 'productos',
        key: 'productos',
        render: (tags) => (
          <span>
            {tags.map((tag) => {
              let color = tag.length > 5 ? 'geekblue' : 'green';
              if (tag === 'loser') {
                color = 'volcano';
              }
              return (
                <Tag color={color} key={Math.random()}>
                  {tag}
                </Tag>
              );
            })}
          </span>
        ),
      },
      {
        title: 'Comentarios',
        dataIndex: 'nota',
        key: 'nota',
      },
      {
        title: 'Reparación',
        dataIndex: 'recepcion_equipo',
        key: 'recepcion_equipo',
        align: 'center',
        render: (tag) => {
          let color =
            tag === 'p'
              ? 'magenta'
              : tag === 'e'
              ? 'green'
              : tag === 't'
              ? 'gold'
              : 'cyan';
          return (
            <Tag color={color} key={Math.random()}>
              {tag === 'e'
                ? 'Entregado'
                : tag === 't'
                ? 'Terminado'
                : tag === 'p'
                ? 'En Proceso'
                : 'N/A'}
            </Tag>
          );
        },
        //render: (text, record) => (text === "e" ? "Si" : (text === "t" ? "Si" : (text === "p" ? "Si" : "No")))
      },
      {
        title: 'Pago',
        dataIndex: 'pago',
        key: 'pago',
        align: 'center',
        filters: [
          { text: 'No pagada', value: 'No pagada' },
          { text: 'Pagada', value: 'Pagada' },
          { text: 'Cancelada', value: 'Cancelada' },
        ],
        filteredValue: filteredInfo.pago || null,
        onFilter: (value, record) => record.pago.includes(value),
        render: (tag) => {
          let color =
            tag === 'No pagada'
              ? 'magenta'
              : tag === 'Pagada'
              ? 'green'
              : 'gold';
          return (
            <Tag color={color} key={tag}>
              {tag}
            </Tag>
          );
        },
      },
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
      },
    ];
    return (
      <React.Fragment>
        {this.props.clienteID > 0 ? (
          ''
        ) : (
          <React.Fragment>
            <h3 style={{ textAlign: 'left' }}>
              {this.props.desdeSiempre
                ? 'Ventas Desde Siempre  '
                : 'Ventas Último Mes Solamente  '}
            </h3>
            <h6 style={{ marginLeft: '10px' }}>
              {this.props.desdeSiempre ? (
                <Link to={'/ventas/'}> Ir a Último mes</Link>
              ) : (
                <Link to={'/ventas/desde-siempre/'}> Ir a desde siempre</Link>
              )}
            </h6>
          </React.Fragment>
        )}
        <Table
          columns={columns}
          dataSource={this.state.data}
          bordered={true}
          pagination={{ defaultPageSize: 10 }}
          onChange={this.handleChange}
          loading={loading}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onVisit: () =>
      dispatch(
        newPage('/ventas/', 'Ventas', '/productos/', 'Lista de Productos', 1)
      ),
    //link1, label 1, link 2, label 2
  };
};

export default connect(null, mapDispatchToProps)(Ventas);
