import React from 'react';
import { pagoNuevoURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import * as actionsPago from "../../store/actions/pago"
import Signo from './../../components/Signo'
import { message, Modal, InputNumber, Select } from 'antd';
const { Option } = Select;

class ModalPago extends React.Component {
    state = {
        visiblePago: false,
        monto: null,
        cantidad: 1
    };

    componentDidMount() {
        this.props.onFetchFormasDePago()
        this.props.setClick(this.showModalPago);
    }

    showModalPago = () => {
        this.setState({
            ...this.state,
            ...{
                visiblePago: true,
            }
        });
    };

    handleOkDinero = e => {
        const { monto } = this.state
        const { cliente_id , venta_id, pedido_id, load, formaSelec } =  this.props
        //console.log('formaSelec, monto, data', formaSelec, monto, data)
        console.log(formaSelec, 'formaSelec')
        if (formaSelec === undefined) {
            return message.error('Selecciona una forma de pago');
        }
        axios
            .post(pagoNuevoURL, {
                monto: monto, //diferentes nombres porque así lo manejo en el backend
                forma_de_pago: formaSelec,
                cliente: cliente_id,
                venta_id: venta_id,
                pedido_id: pedido_id,
            })
            .then(res => {
                console.log(res)
                load()
                //sustituye el this.loadVentaData() por cada cosa
                //this.loadVentaData(); //refrescar el state para que aparezcan los datos de la venta actualizados
                message.success('Se registró el pago con éxito');
            })
            .catch(err => {
                message.error(err.response.data.message);
            })

        this.setState({
            visiblePago: false,
        });
    };

    handleCancel = (nombre, e) => {
        this.setState({
            ...this.state,
            [nombre]: false,
            monto: 0,
            cantidad: 1
        });
        this.props.onSubmitResetReduxState()
    };

    handleChange = (nombre, e) => { // en antd pasa el arg sin hacer e.target.value...
        this.setState({ //lo reuso para pago y devolucion piezas
            ...this.state,
            ...{ [nombre]: e } // "e" es la "cantidad" o el "monto", dependiendo de donde se llame la función.
        });
    };

    onChangeSelectHandler = (value) => {
        this.props.onChangeFromaSelec(value)
    };

    render() {
        const { formaSelec, formasDePago } = this.props;

        return (
            <>
                    <Modal
                        title={this.props.title}
                        visible={this.state.visiblePago}
                        onOk={this.handleOkDinero}
                        onCancel={() => this.handleCancel('visiblePago')}
                        cancelText="Cancelar"
                        okText="Aplicar"
                    >
                        <p style={{ color: 'red' }}>Para hacer {this.props.instrucciones} añade un  <b>"-"</b> <i>por ejemplo: -100</i> </p>
                        <Signo monto={this.state.monto} gasto={this.props.gasto} ingreso={`${this.props.ingreso}`}/>
                        <InputNumber value={this.state.monto} autoFocus={true} onChange={(e) => this.handleChange('monto', e)} step={0.1} style={{ width: '20%' }} />
                        <Select
                            showSearch
                            style={{ width: 300, textAlign: 'center' }}
                            placeholder="Buscar forma de pago..."
                            optionFilterProp="children"
                            onChange={(e) => this.onChangeSelectHandler(e)}
                            value={formaSelec}
                            allowClear={true}
                            autoFocus={false}
                        >

                            {
                                formasDePago.map(f => {
                                    return <Option key={f.id} value={f.id}>{f.nombre}</Option>
                                })
                            }

                        </Select>
                    </Modal>
                </>
        );
    }
}

const mapStateToProps = state => {
    return {
        formasDePago: state.pago.formasDePago,
        formaSelec: state.pago.formaSelec
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchFormasDePago: () => dispatch(actionsPago.loadFormasDePago()),
        onChangeFromaSelec: (val) => dispatch(actionsPago.changeFromaSelec(val)),
        onSubmitResetReduxState: () => dispatch({ type: 'RESET_REDUX_STATE' })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPago);