import React from 'react';
import { DevGarVentaProductoURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import { message, Modal, InputNumber } from 'antd';

class ModalDevGar extends React.Component {
    state = {
        visibleDevol: false,
        monto: null,
        cantidad: 1
    };

    componentDidMount() {
        this.props.setClick(this.showModal);
    }

    showModal = () => {
        this.setState({
            ...this.state,
            ...{
                visibleDevol: true,
            }
        });
    };

    handleOkDevGar = (nombre, e) => {
        const { cantidad } = this.state
        const { id_producto } = this.props
        axios
            .post(DevGarVentaProductoURL, {
                cantidad: cantidad,
                id_producto: id_producto,
                devolucion_o_garantia: nombre,
            })
            .then(res => {
                console.log(res)
                this.props.load();
                message.success(res.data.message);
            })
            .catch(err => {
                console.log(err, 'err')
                message.error(err.response.data.message);
            })

        this.setState({
            visibleDevol: false
        });
    };

    handleCancel = (nombre, e) => {
        this.setState({
            ...this.state,
            [nombre]: false,
            monto: 0,
            cantidad: 1
        });
        this.props.onSubmitResetReduxState()
    };

    handleChange = (nombre, e) => { // en antd pasa el arg sin hacer e.target.value...
        this.setState({ //lo reuso para pago y devolucion piezas
            ...this.state,
            ...{ [nombre]: e } // "e" es la "cantidad" o el "monto", dependiendo de donde se llame la función.
        });
    };

    render() {

        return (
            <>
                     <Modal
                        title={this.props.title}
                        visible={this.state.visibleDevol}
                        onOk={(e) => this.handleOkDevGar(this.props.handleNombre, e)}
                        onCancel={() => this.handleCancel('visibleDevol')}
                        cancelText="Cancelar"
                        okText="Aplicar"
                    >
                        {this.props.inBetweenText}
                        <InputNumber value={this.state.cantidad} onChange={(e) => this.handleChange('cantidad', e)} style={{ width: '15%' }} defaultValue={1} />
                    </Modal>
                </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitResetReduxState: () => dispatch({ type: 'RESET_REDUX_STATE' })
    }
}

export default connect(null, mapDispatchToProps)(ModalDevGar);