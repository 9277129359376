import React from 'react';
import Highlighter from 'react-highlight-words';
import { newPage } from "../../store/actions/breadcrumbs";
import { connect } from 'react-redux';
import { Input, Button, Icon, Table, Tag, Select } from 'antd';
//import { connect } from 'react-redux';
//import { initProdutos } from "../../store/actions/core"
import axios from "axios";
import { Link } from 'react-router-dom'
import { recepcionEquipoListURL,recepcionEquipoUpdateURL } from "../../constants";
const { Option } = Select;

class Ventas extends React.Component {
    state = {
        searchText: '',
        loading: false,
        error: null,
        data: []
    }

    handleSelectChange = (value, id) => {
        //console.log(typeof value, id)
        axios
            .patch(recepcionEquipoUpdateURL(id), 
                {
                    status: value,
                }
            )
            .then(res => {
                    //console.log(res);
                    this.loadRecepcionEquiposData()
                })
                .catch(err => {
                    console.log(err.response)
                });
    }

    loadRecepcionEquiposData() {
        this.setState({ loading: true });
        axios
            .get(recepcionEquipoListURL)
            .then(res => {
                //console.log(res.data)
                let recepciones = res.data.map(recepcion => {
                    let change_status = (
                        <Select
                            value={'Cambiar Status'}
                            size={'small'}
                            style={{ width: '50%' }}
                            onChange={(value) => this.handleSelectChange(value, recepcion.id)}
                            >
                            <Option value="p">En Proceso</Option>
                            <Option value="t">Terminado</Option>
                            <Option value="e">Entregado</Option>
                        </Select>
                    )
                    return (
                        {
                            key: recepcion.id,
                            id: recepcion.id,
                            venta_id: recepcion.venta_ref,
                            cliente: recepcion.cliente,
                            modelo: recepcion.modelo.nombre,
                            status: recepcion.status,
                            change_status: change_status,
                        }
                    )
                });
                //console.log(recepciones)
                this.setState({ 
                    data: recepciones,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    }
    
    componentDidMount() {
        this.props.onVisit()
        this.loadRecepcionEquiposData()
    }

    //EMPIEZAN FILTROS PARA SEARCH
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar...`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    //TERMINAN FILTROS PARA SEARCH

    //FILTROS FILTER
    handleChange = (pagination, filters) => {
        //console.log('Various parameters', pagination, filters);
        this.setState({
            filteredInfo: filters
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    //TERMINAN FILTROS FILTER

    render() {
        let { filteredInfo, loading } = this.state;
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center'
            },
            {
                title: 'Venta ID',
                dataIndex: 'venta_id',
                key: 'venta_id',
                align: 'center',
                ...this.getColumnSearchProps('venta_id'),
                render: (text, record) => <Link to={'/ventas/' + record.venta_id + '/'}>{text}</Link>
            },
            {
                title: 'Cliente',
                dataIndex: 'cliente',
                key: 'cliente',
                ...this.getColumnSearchProps('cliente')
            },
            {
                title: 'Modelo',
                dataIndex: 'modelo',
                key: 'modelo',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                filters: [{ text: 'En Proceso', value: 'p' }, { text: 'Terminado', value: 't' }, { text: 'Entregado', value: 'e' }],
                filteredValue: filteredInfo.status || null,
                onFilter: (value, record) => record.status.includes(value),
                render: (tag) => {
                    let color = (tag === "p" ? "magenta" : (tag === "e" ? "green" : "gold"))
                    return (
                        <Tag color={color} key={tag}>
                            {(tag === "e" ? "Entregado" : (tag === "t" ? "Terminado" : "En Proceso"))}
                        </Tag>
                    )
                }
            },
            {
                title: 'Cambiar Status',
                dataIndex: 'change_status',
                key: 'change_status',
            },
        ];
        return (
            <React.Fragment>
                {(this.props.clienteID > 0 ? '' :
                    <h3 style={{ textAlign: 'left' }}>Reparaciones</h3>
                )}
                <Table columns={columns} dataSource={this.state.data} bordered={true} pagination={{ defaultPageSize: 10 }} onChange={this.handleChange} loading={loading}/>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/reparaciones/', 'Reparaciones', '/', '', 1))
        //link1, label 1, link 2, label 2
    }
}

export default connect(null, mapDispatchToProps)(Ventas);