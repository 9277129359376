import React from 'react';
import { Col, Table, Button, Icon, Row, message, Tag } from 'antd';
import axios from "axios";
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { garantiasListURL, proveedoresListURL } from "../../constants";
import { Link } from 'react-router-dom'

class GarantiasList extends React.Component {
    state = {
        filteredInfo: null,
        loading: false,
        error: null,
        data: [],
        filtersProveedores: []
    }

    componentDidMount() {
        this.props.onVisit()
        this.setState({ loading: true });
        axios
            .get(garantiasListURL)
            .then(res => {
                //console.log(res.data)
                let garantias = res.data.map(p => {
                    let trimmed_comentarios = (p.comentarios.length > 15 ? p.comentarios.slice(0, 30) + ' ...' : p.comentarios)
                    return (
                        {
                            key: p.id,
                            id: p.id,
                            proveedor: p.proveedor.nombre,   
                            total: p.total,
                            comentarios: trimmed_comentarios,
                            status: p.status,
                            fecha: p.fecha
                        }
                    )
                });
                this.setState({ data: garantias, loading: false });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
        axios
            .get(proveedoresListURL)
            .then(res => {
                let proveedores = res.data.map(p => {
                    return (
                        {
                            text: p.nombre,
                            value: p.nombre
                        }
                    )
                });
                this.setState({ filtersProveedores: proveedores });
            })
            .catch(err => {
                console.log(err)
            });
    }

    //FILTROS FILTER
    handleChange = (pagination, filters, sorter) => {
        //console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null
        });
        message.success('Se limpiaron los filtros')
    };
    //TERMINAN FILTROS

    render() {
        let { filteredInfo, filtersProveedores, loading } = this.state;
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                render: (text, record) => <Link to={record.id + '/'}>{text}</Link>,
            },
            {
                title: 'Proveedor',
                dataIndex: 'proveedor',
                key: 'proveedor',
                filters: filtersProveedores, //[{ text: 'Elite Vapor', value: 'Elite Vapor' }, { text: 'Relx', value: 'Relx' }],
                filteredValue: filteredInfo.proveedor || null,
                onFilter: (value, record) => record.proveedor.includes(value),
                ellipsis: true,
            },
            {
                title: '# Garantías',
                dataIndex: 'total',
                key: 'total',
                align: 'center'
            },
            {
                title: 'Comentarios',
                dataIndex: 'comentarios',
                key: 'comentarios'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                filters: [{ text: 'Registrar Crédito', value: 'Registrar Crédito' }, { text: 'Piezas Pendientes', value: 'Piezas Pendientes' }, { text: 'Enviada', value: 'Enviada' }, { text: 'Completada', value: 'Completada' }],
                filteredValue: filteredInfo.status || null,
                onFilter: (value, record) => record.status.includes(value),
                render: (tag) => {
                    let color = (tag === "Enviada" ? "gold" : (tag === "Completada" ? "green" : "magenta"))
                    return (
                        <Tag color={color} key={tag}>
                            {tag}
                        </Tag>
                    )
                }
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
            }
        ];

        return (
            <React.Fragment>
                <Row gutter={[16, 16]} >
                    <Col span={12}>
                        <h3 style={{ textAlign: 'left' }}>Solicitudes de Garantías Enviadas</h3>
                    </Col>
                    <Col span={12}>
                        <Button style={{ float: 'right' }} onClick={this.clearAll}><Icon type="filter" /> Limpiar Filtros</Button>
                    </Col>
                </Row>
                <Table columns={columns} dataSource={this.state.data} bordered={true} pagination={{ defaultPageSize: 10 }} onChange={this.handleChange} loading={loading}/>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/solicitud-de-garantias/', 'Solocitud de Garantías', '/', '', 1))
        //link1, label 1, link 2, label 2
    }
}

export default connect(null, mapDispatchToProps)(GarantiasList);
