import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newPage } from '../../../store/actions/breadcrumbs';
import SeleccionarCliente from './SeleccionarCliente';
import VentaProductoForm from './VentaProductoForm';
import FormaDePago from './FormaDePago';
import Confirm from './Confirm';
import RecepcionEquipo from './../../Reparaciones/RecepcionEquipo';
import { Redirect, withRouter } from 'react-router-dom';
import { Steps, Divider, Button, Icon } from 'antd';
import * as actions from './../../../store/actions/recepciones';
const { Step } = Steps;

export class VentaForm extends Component {
  state = {
    step: 0,
    internet: false,
    loading: false,
    error: null,
    tienda: 'V', //State para filtrar productos de esa tienda solamente y también FormaDePago. Mismo valor que el TIENDA tup de models.py
  };

  componentDidMount() {
    this.props.onVisit();
    this.setState({ loading: true });
  }

  componentWillUnmount() {
    this.props.onSubmitResetReduxState();
  }

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  firstStep = () => {
    this.setState({
      step: 0,
    });
  };

  onChange = (current) => {
    this.setState({ step: current });
  };

  onChangeCbx = (e) => {
    e.preventDefault();
    this.setState({ internet: !this.state.internet });
  };

  onChangeTienda = (e) => {
    e.preventDefault();
    this.setState({ tienda: e.target.value });
  };

  continue = (e) => {
    e.preventDefault();
    this.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.prevStep();
  };

  handleOnClick = () => {
    this.props.onChangeConRecepcion();
  };

  render() {
    const { step } = this.state;
    const { conRecepcion } = this.props;
    const steps = (
      <React.Fragment>
        <Steps current={step} onChange={this.onChange}>
          <Step title={['Cliente / ', <Icon key={1} type="tablet" />]} />
          <Step title="Productos" />
          <Step title="Pago" />
          <Step title="Resumen" />
        </Steps>
        <Divider />
      </React.Fragment>
    );

    const boton_continuar = (
      <Button color="primary" variant="contained" onClick={this.continue}>
        Continuar <Icon type="right" />
      </Button>
    );

    const botones_regresar_continuar = (
      <Button.Group>
        <Button color="secondary" variant="contained" onClick={this.back}>
          <Icon type="left" /> Regresar
        </Button>
        {boton_continuar}
      </Button.Group>
    );
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            {steps}
            <SeleccionarCliente
              nextStep={this.nextStep}
              onChangeTienda={this.onChangeTienda}
            />
            <br></br>
            {this.state.tienda === 'A' ? (
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  style={{ margin: '0.5em' }}
                  onClick={this.handleOnClick}
                >
                  <Icon key={1} type={conRecepcion === true ? 'up' : 'down'} />{' '}
                  {conRecepcion === true
                    ? [
                        'CON Recepción de Equipo',
                        <Icon key={2} type="tablet" />,
                      ]
                    : 'SIN Recepcion de Equipo'}
                </Button>
              </div>
            ) : (
              ''
            )}
            {conRecepcion && <RecepcionEquipo ancho="400px" size="default" />}
            <br></br>
            <div style={{ textAlign: 'center' }}>{boton_continuar}</div>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            {steps}
            <div style={{ textAlign: 'center' }}>
              <VentaProductoForm tienda={this.state.tienda} />
              {botones_regresar_continuar}
            </div>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            {steps}
            <div style={{ textAlign: 'center' }}>
              <FormaDePago tienda={this.state.tienda} />
              {botones_regresar_continuar}
            </div>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            {steps}
            <Confirm
              prevStep={this.prevStep}
              firstStep={this.firstStep}
              onChangeCbx={this.onChangeCbx}
              internet={this.state.internet}
              history={this.props.history}
              tienda={this.state.tienda}
            />
          </React.Fragment>
        );
      default:
        return <Redirect to="/cliente/" />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    valorTotal: state.producto.valorTotal,
    conRecepcion: state.recepcion.conRecepcion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onVisit: () =>
      dispatch(newPage('/ventas/', 'Ventas', '/venta/', 'Capturar Venta', 2)),
    //link1, label 1, link 2, label 2
    onSubmitResetReduxState: () => dispatch({ type: 'RESET_REDUX_STATE' }),
    onChangeConRecepcion: () => dispatch(actions.changeConRecepcion()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VentaForm)
);
