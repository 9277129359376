import React, { Component } from 'react';
import { Button, Table, Select, Typography, message, Icon } from 'antd';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/productos"
const { Option } = Select;
const { Paragraph } = Typography;

const columnsPromos = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Promoción',
        dataIndex: 'nombre',
        key: 'nombre',
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
    },
];


export class Promocion extends Component {

    componentDidMount() {
        this.props.onLoadPromoData()
    }

    handleSelectChange = (value) => {
        this.props.onHandleSelectChangePromo(value)
    };

    handleOnClick = () => {
        const { promociones, promosArray, promocionSelec } = this.props

        if (promocionSelec === '') {
            message.error('Debes de seleccionar una promoción.');
            return false
        }

        let promoA = promociones.filter(p => p.id === promocionSelec)

        if (promosArray.length === 0) {
            let new_state = promosArray.concat(promoA)
            this.props.onChangePromosArray(new_state)
        }

        else {
            let filtro_listado_actual = promosArray.find(p => p.id === promoA[0].id) //Ya existe la forma de pago en el listado?

            if (filtro_listado_actual === undefined) {
                let new_state = promosArray.concat(promoA)
                this.props.onChangePromosArray(new_state)
            }
            else {
                message.error('La promoción ya está en el listado');
            }
        }
    };

    handleOnClickDeleteRow = (rowID) => {
        let { promosArray, onChangePromosArray } = this.props;
        let promo = promosArray.filter(p => p.id !== rowID)
        onChangePromosArray(promo);
    };

    render() {
        const { promosArray, promocionSelec, promociones } = this.props;
        let promos = promosArray.map(p => {
            let delete_row = (
                <Button onClick={() => this.handleOnClickDeleteRow(p.id)}>
                    <Icon type="delete" theme="filled" style={{ color: 'red' }} />
                </Button>
            )
            return (
                {
                    key: p.id,
                    id: p.id,
                    nombre: p.nombre,
                    delete: delete_row,
                }
            )
        });

        return (
            <React.Fragment>
                <Paragraph>{this.props.text}</Paragraph>
                {(
                    promosArray.length > 0 ?
                        <Table locale={{ emptyText: 'No hay promociones aplicadas' }} size={'small'} columns={columnsPromos} dataSource={promos} bordered={true} pagination={false} />
                        : ''
                )}
                <br></br>
                <Select
                    showSearch
                    style={{ maxWidth: 400, minWidth: 90, textAlign: 'center' }}
                    placeholder="Buscar promoción..."
                    optionFilterProp="children"
                    onChange={this.handleSelectChange}
                    value={promocionSelec}
                    allowClear={true}
                    autoFocus={true}
                >
                    {
                        promociones.map(p => {
                            return <Option key={p.id} value={p.id}>{p.nombre}</Option>
                        })
                    }

                </Select>
                <Button
                    type="primary"
                    style={{ margin: '0.5em' }}
                    onClick={this.handleOnClick}>
                    <Icon type="plus" />
                </Button>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        promociones: state.producto.promociones,
        promosArray: state.producto.promosArray,
        promocionSelec: state.producto.promocionSelec,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadPromoData: () => dispatch(actions.loadPromoData()),
        onHandleSelectChangePromo: (val) => dispatch(actions.handleSelectChangePromo(val)),
        onChangePromosArray: (val) => dispatch(actions.changePromosArray(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Promocion);