import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    clientes: [],
    comentarios: ''
    //cliente: '', comentado para que salga el placeholder en el select, en lugar de '' vacío
};

const bcrumbsChange = (state, action) => {
    return updateObject(state, {
        to1: action.to1,
        label1: action.label1,
        to2: action.to2,
        label2: action.label2,
        numOfBcrumbs: action.num
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NEW_LOADED_PAGE: //caso breadcrumbs
            return bcrumbsChange(state, action);
        case actionTypes.LOAD_CLIENTES_DATA:
            return {
                ...state,
                clientes: action.payload
            };
        case actionTypes.CHANGE_CLIENTE:
            return {
                ...state,
                cliente: action.payload
            };
        case actionTypes.CHANGE_COMENTARIOS_CLIENTE:
            return {
                ...state,
                comentarios: action.payload
            };
        default:
            return state;
    }
};

export default reducer;