import React from 'react';
import { DetalleClienteURL } from '../../../constants';
import axios from 'axios';
import { connect } from 'react-redux';
import { newPage } from "../../../store/actions/breadcrumbs";
import { Card, Col, Divider, Row } from 'antd';
import { Link } from 'react-router-dom';
import Ventas from './../Ventas';

class DetalleCliente extends React.Component {
    state = {
        data: []
    };

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.onVisit(params)
        //console.log(params)
        axios
            .get(DetalleClienteURL(params.clienteID))
            .then(res => {
                //console.log(res.data)
                this.setState({ data: res.data });
            })
            .catch(err => {
                console.log(err)
                this.setState({ error: err });
            });
    }

    render() {
        const { data } = this.state
        // if (data === undefined) {
        //     return null;
        // }
        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Cliente: {data.nombre}</h3>
                <Divider />
                <Row gutter={16}>
                    <Col span={8}>
                    </Col>
                    <Col span={8}>
                        <Card title={data.nombre} extra={<Link to={`/cliente/${data.id}/editar`}>editar</Link>} style={{ width: 400, textAlign: 'center' }}>
                            <p><b>ID:</b> {data.id}</p>
                            <p><b>Cel/Tel:</b> {data.tel}</p>
                            <p><b>Email:</b> {data.email}</p>
                            <p><b>Agregado: </b> {data.agregado}</p>
                            <p><b>Comentarios: </b> {data.comentarios}</p>
                            <p></p>
                        </Card>
                    </Col>
                    <Col span={8}>
                    </Col>
                </Row>
                <Divider />
                <h4 style={{ textAlign: 'center' }}>Ventas del Cliente</h4>
                {data.id > 0 &&
                    <Ventas clienteID={data.id} otroLado={true} />
                }
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/cliente/', 'Clientes', `/cliente/${params.clienteID}/`, 'Detalle Cliente', 2))
        //link1, label 1, link 2, label 2
    }
}

export default connect(null, mapDispatchToProps)(DetalleCliente);