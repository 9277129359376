import React from 'react';
import {
  FormasDePagoURL,
  ConceptosDePagoURL,
  pagoNuevoURL,
} from '../../constants';
import axios from 'axios';
import { connect } from 'react-redux';
import { newPage } from '../../store/actions/breadcrumbs';
import {
  Button,
  Divider,
  Form,
  Input,
  Icon,
  message,
  InputNumber,
  Select,
} from 'antd';
import Signo from './../../components/Signo';
const { Option } = Select;

class AgegarPago extends React.Component {
  state = {
    conceptos: [],
    formasDePago: [],
    PositivoONegativo: null,
  };

  componentDidMount() {
    this.props.onVisit();
    axios
      .get(FormasDePagoURL)
      .then((res) => {
        let formas = res.data.map((forma) => {
          return {
            key: forma.id,
            id: forma.id,
            nombre: forma.nombre,
          };
        });
        this.setState({ formasDePago: formas });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
    axios
      .get(ConceptosDePagoURL)
      .then((res) => {
        let conceptos = res.data.map((concepto) => {
          return {
            key: concepto.id,
            id: concepto.id,
            nombre: concepto.nombre,
          };
        });
        this.setState({ conceptos: conceptos });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log('Received values of form: ', values);
        axios
          .post(pagoNuevoURL, values)
          .then((res) => {
            console.log(
              'JUST A LOG BEFOR POSSIBLE ERRORES, AGREGARPAGO.JS CMP'
            );
            console.log(res, 'RESPONSE FROM AGREGARPAGO.JS');
            //console.log(values, 'values')
            this.props.history.push(`/pagos/${res.data.id}/`);
            //this.props.history.push(`/pagos/`);
            let pago_o_gasto = values.monto > 0 ? 'ingreso' : 'gasto';
            message.success('¡Se agregó el ' + pago_o_gasto + ' con éxto!', 2);
          })
          .catch((err) => {
            console.log(err);
            message.error(err.response.data.message);
          });
      }
    });
  };

  handleInputChange = (e) => {
    this.setState({
      ...this.state,
      ...{ PositivoONegativo: e },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { conceptos, formasDePago, PositivoONegativo } = this.state;
    return (
      <React.Fragment>
        <h3 style={{ textAlign: 'left' }}>Capturar Ingreso/Gasto</h3>
        <Divider />
        <div style={{ margin: 'auto', maxWidth: '400px', textAlign: 'center' }}>
          <Form labelCol={{}} wrapperCol={{}} onSubmit={this.handleSubmit}>
            <Signo
              monto={PositivoONegativo}
              gasto="un GASTO"
              ingreso="un INGRESO"
            />
            <div style={{ margin: '-1em' }}></div>
            <Form.Item label="Monto">
              {getFieldDecorator('monto', {
                rules: [
                  {
                    required: true,
                    message: '*',
                  },
                ],
              })(
                <InputNumber
                  type="number"
                  placeholder="Monto"
                  autoFocus={true}
                  onChange={this.handleInputChange}
                />
              )}
            </Form.Item>
            <div style={{ margin: '-2em' }}></div>
            <Form.Item label="Concepto">
              {getFieldDecorator('concepto', {
                rules: [
                  {
                    required: true,
                    message: '*',
                  },
                ],
              })(
                <Select
                  showSearch
                  style={{ maxWidth: 400 }}
                  placeholder="Buscar un concepto"
                  optionFilterProp="children"
                  //onChange={this.onChangeHandler}
                  allowClear={true}
                >
                  {conceptos.map((f) => {
                    return (
                      <Option key={f.id} value={f.id}>
                        {f.nombre}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <div style={{ margin: '-2em' }}></div>
            <Form.Item label="Forma de Pago">
              {getFieldDecorator('forma_de_pago', {
                rules: [
                  {
                    required: true,
                    message: '*',
                  },
                ],
              })(
                <Select
                  showSearch
                  style={{ maxWidth: 400 }}
                  placeholder="Buscar forma de pago"
                  optionFilterProp="children"
                  //onChange={this.onChangeHandler}
                  allowClear={true}
                >
                  {formasDePago.map((f) => {
                    return (
                      <Option key={f.id} value={f.id}>
                        {f.nombre}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <div style={{ margin: '-2em' }}></div>
            <Form.Item label="Comentarios">
              {getFieldDecorator(
                'nota',
                {}
              )(
                <Input.TextArea
                  placeholder="Comentarios del pago"
                  maxLength={350}
                />
              )}
            </Form.Item>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Guradar <Icon type="save" />
            </Button>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onVisit: () =>
      dispatch(
        newPage('/agregar-pago/', 'Capturar Ingreso ó Gasto', '/', '', 1)
      ),
    //link1, label 1, link 2, label 2
  };
};

const WrappedApp = Form.create({ name: 'pago-nuevo' })(AgegarPago);
export default connect(null, mapDispatchToProps)(WrappedApp);
