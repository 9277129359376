import * as actionTypes from "./actionTypes";

export const newPage = (to1, label1, to2, label2, num) => {
  return {
    type: actionTypes.NEW_LOADED_PAGE,
    to1: to1,
    label1: label1,
    to2: to2,
    label2: label2,
    num
  };
};