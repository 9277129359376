import * as actionTypes from "../actions/actionTypes";
//import { updateObject } from "../utility";

const initialState = {
    formasDePago: [],
    formasArray: [],
    totalFormasDePago: 0,
    //formaSelec: ''
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_FORMAS_DATA:
            return {
                ...state,
                formasDePago: action.payload
            }
        case actionTypes.CALCULAR_TOTAL_A_PAGAR:
            return {
                ...state,
                totalFormasDePago: action.payload
            }
        case actionTypes.CHANGE_FORMAS_ARRAY_STATE:
            return {
                ...state,
                formasArray: action.payload
            }
        case actionTypes.CHANGE_FORMA_SELEC:
            return {
                ...state,
                formaSelec: action.payload
            }
        default:
            return state;
    }
};

export default reducer;