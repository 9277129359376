import * as actionTypes from "./actionTypes";
import axios from 'axios';
import { proveedoresListURL } from '../../constants'

export const loadProveedoresData = (id) => {
    return dispatch => {
        axios
            .get(proveedoresListURL)
            .then(res => {
                let proveedores = res.data.map(prov => {
                    return (
                        {
                            key: prov.id,
                            id: prov.id,
                            nombre: prov.nombre,
                        }
                    )
                });
                dispatch({
                    type: actionTypes.LOAD_PROVEEDORES_DATA,
                    payload: proveedores //clientes: clientes
                });
            })
            .catch(err => {
                dispatch({
                    type: actionTypes.ERROR,
                    payload: err
                });
            });
    }
}

export const changeProveedor = (value) => {
    return {
        type: actionTypes.CHANGE_PROVEEDOR,
        payload: value
    };
};

export const handleChangedComentarios = (value) => {
    return {
        type: actionTypes.CHANGE_COMENTARIOS_PROVEEDOR,
        payload: value
    };
}