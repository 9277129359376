import React, { Component } from 'react';
import {
  Button,
  Icon,
  Select,
  Statistic,
  Table,
  InputNumber,
  message,
} from 'antd';
import { connect } from 'react-redux';
import * as actions from './../../../store/actions/pago';
const { Option } = Select;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Forma de Pago',
    dataIndex: 'nombre',
    key: 'nombre',
  },
  {
    title: 'Importe',
    dataIndex: 'importe',
    key: 'importe',
  },
  {
    title: '',
    dataIndex: 'delete',
    key: 'delete',
  },
];

export class FormaDePago extends Component {
  componentDidMount() {
    this.props.onFetchFormasDePago(this.props.tienda);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.formasArray !== this.props.formasArray) {
      this.calculateTotal();
    }
  }

  handleChange = (id, e) => {
    let { formasArray } = this.props;
    let new_state = formasArray.map((forma) => {
      if (forma.id === id) {
        forma['importe'] = parseFloat(
          e === '' || e === null || isNaN(e) ? 0 : e
        );
      }
      return forma;
    });
    this.props.onChangeFormasArrayState(new_state);
  };

  handleSelectChange = (value) => {
    this.props.onChangeFromaSelec(value);
  };

  handleOnClickDeleteRow = (rowID) => {
    let { formasArray } = this.props;
    let formasJ = formasArray.filter((forma) => forma.id !== rowID);
    this.props.onChangeFormasArrayState(formasJ);
  };

  handleOnClick = () => {
    const {
      formasDePago,
      formasArray,
      formaSelec,
      valorTotal,
      totalFormasDePago,
    } = this.props;
    if (formaSelec === '') {
      message.error('Selecciona una forma de pago');
      return false;
    }

    let formaA = formasDePago.filter((forma) => forma.id === formaSelec);

    if (formasArray.length === 0) {
      formaA[0]['importe'] = valorTotal;
      let new_state = formasArray.concat(formaA);
      this.props.onChangeFormasArrayState(new_state);
    } else {
      let filtro_listado_actual = formasArray.find(
        (f) => f.id === formaA[0].id
      ); //Ya existe la forma de pago en el listado?

      if (filtro_listado_actual === undefined) {
        formaA[0]['importe'] = Math.abs(valorTotal - totalFormasDePago);
        let new_state = formasArray.concat(formaA);
        this.props.onChangeFormasArrayState(new_state);
      } else {
        message.error('La forma de pago ya está en el listado');
      }
    }
  };

  calculateTotal = () => {
    let { formasArray, onCalcularTotalAPagar } = this.props;
    let values = formasArray.map((forma) => {
      let tot = parseFloat(forma.importe);
      return tot;
    });
    function sumVals(total, num) {
      return total + num;
    }
    let valor_tot = values.length === 0 ? 0 : values.reduce(sumVals);
    onCalcularTotalAPagar(valor_tot);
  };

  render() {
    const {
      formasDePago,
      formaSelec,
      formasArray,
      valorTotal,
      totalFormasDePago,
    } = this.props;
    let formasB = formasArray.map((forma) => {
      let input_importe = (
        <InputNumber
          onChange={(e) => this.handleChange(forma.id, e)}
          type="number"
          style={{ width: '50%' }}
          value={forma.importe >= 0 ? forma.importe : valorTotal}
        />
      );
      let delete_row = (
        <Button onClick={() => this.handleOnClickDeleteRow(forma.id)}>
          <Icon type="delete" theme="filled" style={{ color: 'red' }} />
        </Button>
      );
      return {
        key: forma.id,
        id: forma.id,
        nombre: forma.nombre,
        importe: input_importe,
        delete: delete_row,
      };
    });

    const totalesString = totalFormasDePago + ' / ' + valorTotal;
    return (
      <React.Fragment>
        <Table
          locale={{ emptyText: 'No hay formas de pago seleccionadas' }}
          size={'middle'}
          columns={columns}
          dataSource={formasB}
          bordered={true}
          pagination={false}
        />
        <br></br>
        <Select
          showSearch
          style={{ maxWidth: 400, minWidth: 250, textAlign: 'center' }}
          placeholder="Buscar forma de pago..."
          optionFilterProp="children"
          onChange={this.handleSelectChange}
          value={formaSelec}
          allowClear={true}
          autoFocus={true}
        >
          {formasDePago.map((f) => {
            return (
              <Option key={f.id} value={f.id}>
                {f.nombre}
              </Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          style={{ margin: '0.5em' }}
          onClick={this.handleOnClick}
        >
          <Icon type="plus" />
        </Button>
        <br></br>
        <br></br>
        <Statistic
          title="Total"
          prefix={'MX'}
          value={totalesString}
          precision={2}
        />
        <br></br>
        <br></br>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formasDePago: state.pago.formasDePago,
    formasArray: state.pago.formasArray,
    totalFormasDePago: state.pago.totalFormasDePago,
    formaSelec: state.pago.formaSelec,
    valorTotal: state.producto.valorTotal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchFormasDePago: (tienda) => dispatch(actions.loadFormasDePago(tienda)),
    onCalcularTotalAPagar: (val) => dispatch(actions.calcularTotalAPagar(val)),
    onChangeFormasArrayState: (val) =>
      dispatch(actions.changeFromasArrayState(val)),
    onChangeFromaSelec: (val) => dispatch(actions.changeFromaSelec(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormaDePago);
