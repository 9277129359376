import React from 'react';
import { Divider, Form, Input, Button, Select, InputNumber, message, Row, Col } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import Proveedor from "./../Proveedores/Proveedor";
import { categoriasListURL, marcasListURL, saboresListURL, nicotinasListURL, productoNuevoURL, modelosListURL } from '../../constants';
const { Option } = Select;

class ProductoNuevo extends React.Component {
    state = {
        marcas: [],
        categorias: [],
        sabores: [],
        nicotinas: [],
        modelos: [],
        tienda: null
    };

    componentDidMount() {
        this.props.onVisit();
        axios
            .get(modelosListURL)
            .then(res => {
                this.setState({
                    ...this.state,
                    modelos: res.data
                })
            })
            .catch(err => {
                console.log(err);
            });
        axios
            .get(marcasListURL)
            .then(res => {
                this.setState({
                    ...this.state,
                    marcas: res.data
                })
            })
            .catch(err => {
                console.log(err);
            });
        axios
            .get(categoriasListURL)
            .then(res => {
                this.setState({
                    ...this.state,
                    categorias: res.data
                })
            })
            .catch(err => {
                console.log(err);
            });
        axios
            .get(saboresListURL)
            .then(res => {
                this.setState({
                    ...this.state,
                    sabores: res.data
                })
            })
            .catch(err => {
                console.log(err);
            });
        axios
            .get(nicotinasListURL)
            .then(res => {
                this.setState({
                    ...this.state,
                    nicotinas: res.data
                })
            })
            .catch(err => {
                console.log(err);
            });
    }

    onChangeHandler = (nombre, value) => {
        //console.log(nombre, value, 'PUEDO PASAR EL NOMBRE PARA NO ESCRIBIR 1 HANDLER POR CADA SELECT')
        if(nombre === 'tienda'){
            this.setState({
                ...this.state,
                tienda: value
            })
        }
        this.props.form.setFieldsValue({ //Se usa esto en ves de this.setState
            [nombre]: value,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.proveedor !== prevProps.proveedor) {
            this.props.form.setFieldsValue({ //Se usa esto en ves de this.setState para el cambio en un field del form
                proveedor: this.props.proveedor, //como proveedor es un component, checo cuando cambian los props
            });
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                axios
                    .post(productoNuevoURL,
                        values
                    )
                    .then(res => {
                        console.log(res)
                        //this.props.history.push(`/cliente/${res.data.id}`);
                        this.props.history.replace(`/productos/${res.data.id}`);
                        message.success(`El producto ${values.nombre} fue registrado con éxito.`);
                    })
                    .catch(err => {
                        message.error(err.response.data.message);
                    });
            }
        });
    };

    render() {
        const { marcas, categorias, sabores, nicotinas, modelos } = this.state
        const { getFieldDecorator } = this.props.form;
        return (

            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Nuevo Producto</h3>
                <Divider />
                <div style={{ margin: 'auto', maxWidth: '400px' }}>
                    <Form layout={'vertical'} onSubmit={this.handleSubmit}>
                        <Form.Item label="Tienda">
                            {getFieldDecorator('tienda', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Por favor selecciona la tienda.',
                                    },
                                ],
                            })(
                                <Select
                                    style={{ width: 400, textAlign: 'center' }}
                                    placeholder="Seleccionar tienda..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('tienda', value)}
                                    allowClear={true}
                                >
                                    <Option key={"A"} value={"A"}>ArreglaTuPantalla</Option>
                                    <Option key={"V"} value={"V"}>Vapes</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Proveedor">
                            {getFieldDecorator('proveedor', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Por favor selecciona al proveedor.',
                                    },
                                ],
                            })(<Proveedor noncreate='true' />)}
                        </Form.Item>
                        {this.state.tienda === 'A' && (
                        <Form.Item label="Modelo">
                            {getFieldDecorator('modelo', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Por favor selecciona el modelo',
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400, textAlign: 'center' }}
                                    placeholder="Buscar modelo..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('modelo', value)}
                                    allowClear={true}
                                >
                                    {
                                        modelos.map(m => {
                                            return <Option key={m.id} value={m.id}>{m.nombre}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        )}
                        <Form.Item label="Nombre">
                            {getFieldDecorator('nombre', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Por favor Ingresa el nombre del producto. Menos de 100 caracteres.',
                                        max: 100
                                    },
                                ],
                            })(<Input placeholder="Ingresa el nombre del producto" />)}
                        </Form.Item>
                        <Form.Item label="Marca">
                            {getFieldDecorator('marca', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Por favor selecciona la marca',
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400, textAlign: 'center' }}
                                    placeholder="Buscar marca..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('marca', value)}
                                    allowClear={true}
                                >
                                    {
                                        marcas.map(m => {
                                            return <Option key={m.id} value={m.id}>{m.nombre}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Categoría">
                            {getFieldDecorator('categoria', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Por favor selecciona la categoría',
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400, textAlign: 'center' }}
                                    placeholder="Buscar categoría..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('categoria', value)}
                                    allowClear={true}
                                >
                                    {
                                        categorias.map(c => {
                                            return <Option key={c.id} value={c.id}>{c.nombre}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="Costo">
                                    {getFieldDecorator('costo_del_momento', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el costo del producto',
                                            },
                                        ],
                                    })(<InputNumber type="number" placeholder="Costo" min={0} />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Precio de Venta">
                                    {getFieldDecorator('precio_de_venta', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el precio de venta',
                                            },
                                        ],
                                    })(<InputNumber type="number" placeholder="Precio" min={0} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Descripción">
                            {getFieldDecorator('descripcion', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Por favor ingresa la descripción del producto',
                                        max: 150
                                    },
                                ],
                            })(<Input.TextArea placeholder="Descripción de producto..." min={0} />)}
                        </Form.Item>
                        {this.state.tienda === 'V' && (
                            <>
                        <Form.Item label="Sabor">
                            {getFieldDecorator('sabor', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Por favor selecciona el sabor',
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400, textAlign: 'center' }}
                                    placeholder="Buscar sabor..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('sabor', value)}
                                    allowClear={true}
                                >
                                    {
                                        sabores.map(c => {
                                            return <Option key={c.id} value={c.id}>{c.nombre}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="% Nicotina">
                            {getFieldDecorator('nicotina', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Por favor selecciona el porcentaje de nicotina',
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 400, textAlign: 'center' }}
                                    placeholder="Buscar porcentaje..."
                                    optionFilterProp="children"
                                    onChange={(value) => this.onChangeHandler('nicotina', value)}
                                    allowClear={true}
                                >
                                    {
                                        nicotinas.map(c => {
                                            return <Option key={c.id} value={c.id}>{c.nombre}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        </>
        )
                        }
                        <Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Button type="primary" htmlType="submit">
                                    Agregar Producto
                            </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        proveedor: state.proveedor.proveedor,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/productos/', 'Productos', '/producto-nuevo/', 'Nuevo Producto', 2))
        //link1, label 1, link 2, label 2
    }
}

const WrappedComponent = Form.create({ name: 'producto-nuevo' })(ProductoNuevo);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);

