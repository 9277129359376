import React from 'react';
import { pagoDetailURL, pagoEditarURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { Button, Divider, Form, Input, Icon, message } from 'antd';
import { Link } from 'react-router-dom';

class VentaDetail extends React.Component {
    state = {
        data: null
    };

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.onVisit(params)
        //console.log(params)
        axios
            .get(pagoDetailURL(params.pagoID))
            .then(res => {
                //console.log(res.data)
                this.setState({
                    data: res.data
                });
                this.props.form.setFieldsValue({
                    nota: res.data.nota,
                });
            })
            .catch(err => {
                console.log(err)
                //this.setState({ error: err });
            });
    }

    handleSubmit = e => {
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                axios
                    .patch(pagoEditarURL(params.pagoID), {
                        nota: this.props.form.getFieldsValue().nota
                    })
                    .then(res => {
                        console.log(res)
                        console.log(values, 'values')
                        //this.props.history.push(`/ventas/${res.data.id}/`);
                        message.success('¡Se modificó la nota con éxto!');
                    })
                    .catch(err => {
                        console.log(err)
                        message.error('Hubo un error.');
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data } = this.state;
        if (data === null) {
            return null;
        }

        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Pago ID: {data.id}</h3>
                <Divider />
                <div style={{ textAlign: 'center' }}>
                    <div style={{ maxWidth: '500px', margin: 'auto' }}>
                        {data.cliente.nombre && <h4><b>Cliente: </b> {<Link to={'/cliente/' + data.cliente.id + '/'}>{data.cliente.nombre}</Link>} </h4>}
                        <h4 style={{marginTop: '-0.1em'}}>Tipo de Pago: {(data.monto < 0 ? 'GASTO' : 'INGRESO')}</h4>
                        <p><span style={{ color: (data.monto < 0 ? 'red' : 'green') }}><b>Monto:</b>  {'$ ' + data.monto}</span> | <b>Fecha:</b> {data.fecha} | <b>Capturó:</b> {data.usuario.username}</p>
                        <p><b>Concepto:</b> {data.concepto.nombre} | <b>Forma de Pago:</b> {data.forma_de_pago.nombre} </p>
                        {data.traspaso && <p><b>Traspaso:</b> {data.traspaso}</p>}
                        <b>Comentarios y observaciones</b>
                        <br></br>
                        <Form labelCol={{}} wrapperCol={{}} onSubmit={this.handleSubmit}>
                            <Form.Item>
                                {getFieldDecorator('nota', {
                                })(<Input.TextArea placeholder="Comentarios del pago*" maxLength={350} />)}
                            </Form.Item>
                            <Button
                                color="primary"
                                size='small'
                                variant="contained"
                                onClick={this.handleSubmit}>
                                Guradar <Icon type="save" />
                            </Button>
                        </Form>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/pagos/', 'Pagos', `/pagos/${params.pagoID}/`, 'Detalle de Pago', 2))
        //link1, label 1, link 2, label 2
    }
}

const WrappedApp = Form.create({ name: 'editar-venta' })(VentaDetail);
export default connect(null, mapDispatchToProps)(WrappedApp);