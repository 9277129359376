import React from "react";
import { Form, Icon, Input, Button, Spin, message } from "antd";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "../store/actions/auth";
import { newPage } from "./../store/actions/breadcrumbs";
import Logo from "./../VapesYArreglaAColor.png";

const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class NormalLoginForm extends React.Component {
  componentDidMount(){
    this.props.onVisit()
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onAuth(values.userName, values.password);
        this.props.history.push("/");
      }
      else {
        message.error('Datos incorrectos.');
      }
    });
  };

  render() {
    if (this.props.token) {
      return <Redirect to="/ventas" />;
    }
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>Los datos son incorrectos.</p>;
    }

    const { getFieldDecorator } = this.props.form;
    let dimension = (window.innerWidth < 450 ? '200px' : '300px');
    return (
      <div style={{margin: 'auto', maxWidth: dimension, textAlign: 'center'}}>
        {errorMessage}
        {this.props.loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <>
            <br></br>
            <br></br>
            <img src={Logo} alt='Vapes logo' width={dimension} ></img>
            <br></br>
            <br></br>
            <br></br>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator("userName", {
                  rules: [
                    { required: true, message: "Ingresa tu nombre de usuario" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Usuario"
                  />
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Ingresa tu contraseña" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Contraseña"
                  />
                )}
              </FormItem>

              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                >
                  Login
              </Button>
              </FormItem>
            </Form>
            </>
          )}
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) =>
      dispatch(actions.authLogin(username, password)),
    onVisit: () => dispatch(newPage('/login/', 'Inicio de Sesión', '', '', 1))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm);