import React, { Component } from 'react';
import { newPage } from "../../store/actions/breadcrumbs";
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import { Card, Icon  } from 'antd';

class Chart extends Component {
    constructor(props) {
        super(props);
        const date = new Date()
        this.state = {
            startDate: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10),
            endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().substring(0, 10),
        }
    }

    componentDidMount() {
        if(this.props.perms['ver_reporte_ingresos_e_inventarios'] === true) {
        this.props.onVisit()
        }
        
    }

    render() {

        return (
            this.props.perms['ver_reporte_ingresos_e_inventarios'] === true &&
            (
            <div style={{ margin: 'auto', maxWidth: '400px', textAlign: 'center' }}>
                <Card title="Reporte Inv." extra={<Link to={`/productos/`}>Ir a productos</Link> } style={{ width: 300 }}>
                    <p> <Icon type="warning" theme="filled" style={{ fontSize: '28px', color: 'gold' }} />  Sección en construcción   <Icon type="warning" theme="filled" style={{ fontSize: '28px', color: 'gold' }} /></p>
                    <br></br>
                    <p>Esta sección tendrá: Valor de inventarios en dinero, separado por productos de vapes y arregla.  Tal vez también por categoría.</p>
                    <br></br>
                    <p>Para ir a conocer los niveles actuales de inventarío da clic arriba en "Ir a productos"</p>
                </Card>
            </div>
            )
        )
    }

}

const mapStateToProps = state => {
  return {
    perms: state.auth.permissions
  };
};

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/reportes/inventarios/', 'Reporte de Inventarios', '', '', 1))
        //link1, label 1, link 2, label 2
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chart);