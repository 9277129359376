import React from 'react';
import { productoDetailURL, productoUpdateURL } from '../../constants';
import axios from 'axios'
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { Button, Divider, Form, Input, Icon, message, Row, Col, InputNumber } from 'antd';
import ModalDevGar from './../Garantias/ModalDevGar'
//import { Link } from 'react-router-dom'

class ProductoDetail extends React.Component {
    state = {
        data: null
    };

    loadProductoData = () => {
        const { match: { params } } = this.props;
        this.props.onVisit(params)
        //console.log(params)
        axios
            .get(productoDetailURL(params.productoID))
            .then(res => {
                //console.log(res.data)
                this.setState({
                    data: res.data
                });
                this.props.form.setFieldsValue({
                    costo_del_momento: res.data.costo_del_momento,
                    precio_de_venta: res.data.precio_de_venta,
                    descripcion: res.data.descripcion
                });
            })
            .catch(err => {
                console.log(err)
                //this.setState({ error: err });
            });
    }

    componentDidMount() {
        this.loadProductoData()
    }

    handleSubmit = e => {
        e.preventDefault();
        const { match: { params } } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                axios
                    .patch(productoUpdateURL(params.productoID), {
                        costo_del_momento: this.props.form.getFieldsValue().costo_del_momento,
                        precio_de_venta: this.props.form.getFieldsValue().precio_de_venta,
                        descripcion: this.props.form.getFieldsValue().descripcion
                    })
                    .then(res => {
                        //console.log(values, 'values')
                        message.success('¡Se actualizó el producto con éxto!');
                    })
                    .catch(err => {
                        //console.log(err.response, 'err.response')
                        message.error('Hubo un error.');
                    });
            }
        });
    };

    inBetweenText = (
        "hañsdlfkjañsldfkj",
            this.state.data &&
                        <React.Fragment>
                        <p>Piezas dañadas que regresa el cliente y se van a garantías. Posteriormente se da de baja la pieza que se le va a aplicar como garantía)</p>
                        <br></br>
                            <p>Ingresa la cantidad del producto <b>"{this.state.data.nombre}"</b> a mandar a garantías:</p>
                            </ React.Fragment>
        )

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data } = this.state;
        if (data === null) {
            return null;
        }

        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Producto ID: {data.id}</h3>
                <Divider />
                <div style={{ textAlign: 'center' }}>
                    <div style={{ maxWidth: '500px', margin: 'auto' }}>
                        <h3>{data.nombre}</h3>
                        <p><b>Stock:</b> {data.stock}<b> | Garantías:</b> {data.garantias} | <b>Proveedor:</b> {data.proveedor.identificador} - {data.proveedor.nombre}</p>
                        <p><b>Marca:</b> {data.marca.nombre} | <b>Categoría:</b> {data.categoria.nombre} {(data.modelo.nombre === '' ? '' : [<b key={1}> | Modelo: </b>, data.modelo.nombre])} {(data.sabor.nombre === '' ? '' : [<b key={1}> | Sabor: </b>, data.sabor.nombre])} {(data.nicotina.nombre === '' ? '' : [<b key={1}> | Nicotina: </b>, data.nicotina.nombre])}</p>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => this.clickChild()}>
                            Pieza Defectuosa
                        </Button>
                        <Form labelCol={{}} wrapperCol={{}} onSubmit={this.handleSubmit}>
                            <Row type="flex" justify="center">
                                <Col span={12}>
                                    <Form.Item label="Costo">
                                        {getFieldDecorator('costo_del_momento', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Por favor ingresa el costo del producto',
                                                },
                                            ],
                                        })(<InputNumber type="number" placeholder="Costo" min={0} />)}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Precio de Venta">
                                        {getFieldDecorator('precio_de_venta', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Por favor ingresa el precio de venta',
                                                },
                                            ],
                                        })(<InputNumber type="number" placeholder="Precio" min={0} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Descripción">
                                {getFieldDecorator('descripcion', {
                                })(<Input.TextArea placeholder="Descripción de producto" maxLength={150} />)}
                            </Form.Item>
                            <Button
                                color="primary"
                                size='small'
                                variant="contained"
                                onClick={this.handleSubmit}>
                                Guradar Cambios <Icon type="save" />
                            </Button>
                        </Form>
                    </div>
                </div>
                <ModalDevGar title="Mandar pieza defectuosa a garantías" handleNombre="garantia_pieza_defectuosa_stock" inBetweenText={["Ingresa la cantidad del producto ", <b key="1">{this.state.data.nombre}</b>, " a mandar a garantías:"]} id_producto={this.state.data.id} load={() => this.loadProductoData()} setClick={click => this.clickChild = click} />
            </React.Fragment >
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/productos/', 'Productos', `/productos/${params.productoID}/`, 'Detalle de Producto', 2))
        //link1, label 1, link 2, label 2
    }
}

const WrappedApp = Form.create({ name: 'producto-detail' })(ProductoDetail);
export default connect(null, mapDispatchToProps)(WrappedApp);