import * as actionTypes from "../actions/actionTypes";
//import { updateObject } from "../utility";

const initialState = {
    productos: [],
    producto: '',
    productosVenta: [],
    valorTotal: 0,
    promociones: [],
    promosArray: [],
    promocionSelec: 1,
    productosPedido: [],
    productosGarantia: []
    //producto: '', comentado para que salga el placeholder en el select, en lugar de '' vacío
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_PRODUCTOS_DATA:
            return {
                ...state,
                productos: action.payload
            }
        case actionTypes.HANDLE_SELECT_PRODUCTO:
            return {
                ...state,
                producto: action.payload
            }
        case actionTypes.HANDLE_SELECT_PROMO:
            return {
                ...state,
                promocionSelec: action.payload
            }
        case actionTypes.CONCAT_PRODUCTO_VENTA: // para estos dos de abajo podría solo mandar el state y unificar todo en "CHANGE_PRODUCTO_STATE"
            return {
                ...state,
                ...{ productosVenta: state.productosVenta.concat(action.payload) }
            }
        case actionTypes.DELETE_ROW_PRODUCTO_VENTA:
            return {
                ...state,
                ...{ productosVenta: state.productosVenta.filter(prod => prod.id !== action.payload) }
            }
        case actionTypes.CHANGE_PRODUCTO_VENTA_STATE:
            return {
                ...state,
                ...{ productosVenta: action.payload }
            }
        case actionTypes.CHANGE_PROMOS_ARRAY_STATE:
            return {
                ...state,
                ...{ promosArray: action.payload }
            }
        case actionTypes.CHANGE_PEDIDO_PRODUCTO_STATE:
            return {
                ...state,
                ...{ productosPedido: action.payload }
            }
        case actionTypes.CHANGE_GARANTIA_PRODUCTO_STATE:
            return {
                ...state,
                ...{ productosGarantia: action.payload }
            }
        case actionTypes.RESET_PRODUCTOS_STATE:
            return {
                ...state,
                productosPedido: [],
                productosGarantia: [],
                producto: '',
                valorTotal: 0
            }
        case actionTypes.CALCULAR_VALOR_TOTAL:
            return {
                ...state,
                ...{ valorTotal: action.payload }
            }
        case actionTypes.LOAD_PROMO_DATA:
            return {
                ...state,
                ...{ promociones: action.payload }
            }
        default:
            return state;
    }
};

export default reducer;