import React from "react";
import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import Logo from "./../logo4.png";

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

class CustomLayout extends React.Component {

  render() {
    const { authenticated } = this.props;
    let dimension = (window.innerWidth < 450 ? '0 10px' : '0 50px');
    let header_1 = (
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="logo:1">
              <Link to="/">
                <img src={Logo} alt='Vapes logo' width='125px' ></img>
              </Link>
            </Menu.Item>
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="file-add" />
                    Pedidos
              </span>
                }
              >
                <Menu.Item key="file-add:2">
                  <Link to="/pedidos/">Pedidos</Link>
                </Menu.Item>
                <Menu.Item key="file-add:4">
                  <Link to="/proveedores/">Proveedores</Link>
                </Menu.Item>
                <Menu.Item key="file-add:1">
                  <Link to="/pedido-nuevo/"><Icon type="plus" />Recepción de Mercancía</Link>
                </Menu.Item>
                <Menu.Item key="file-add:3">
                  <Link to="/agregar-proveedor/"><Icon type="plus" />Proveedor</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="barcode" />
                    Productos
              </span>
                }
              >
                <Menu.Item key="build:1">
                  <Link to="/productos/">Productos</Link>
                </Menu.Item>
                <Menu.Item key="build:3">
                  <Link to="/solicitud-de-garantias/">Garantías</Link>
                </Menu.Item>
                <Menu.Item key="build:2">
                  <Link to="/producto-nuevo/"><Icon type="plus" />Producto</Link>
                </Menu.Item>
                <Menu.Item key="build:4">
                  <Link to="/solicitud-de-garantias-nueva/"><Icon type="plus" />Solicitud de Garantías</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="shop" />
                    Ventas
              </span>
                }
              >
                <Menu.Item key="ventas:1">
                  <Link to="/venta/"><Icon type="plus" />Capturar Venta</Link>
                </Menu.Item>
                <Menu.Item key="ventas:3">
                  <Link to="/ventas/">Ventas</Link>
                </Menu.Item>
                <Menu.Item key="ventas:3b">
                  <Link to="/reparaciones/">Reparaciones</Link>
                </Menu.Item>
                <Menu.Item key="ventas:5">
                  <Link to="/cliente/">Clientes</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="control" />
                    Admin
              </span>
                }
              >
                <Menu.Item key="admin:2">
                  <Link to="/pagos/">Pagos</Link>
                </Menu.Item>
                <Menu.Item key="admin:1">
                  <Link to="/agregar-pago/"><Icon type="plus" />Ingreso/Gasto</Link>
                </Menu.Item>
                <Menu.Item key="admin:3">
                  <Link to="/agregar-concepto/"><Icon type="plus" />Concepto de Pago/Gasto</Link>
                </Menu.Item>
                <Menu.Item key="admin:4">
                  <Link to="/traspaso-de-fondos/"><Icon type="plus" />Traspaso de Fondos</Link>
                </Menu.Item>
                <Menu.Item key="admin:5">
                  <a target="_blank" rel="noopener noreferrer" href="https://b.arreglatupantalla.com/admin/"><Icon type="arrow-right" />Panel de Admin</a>
                </Menu.Item>
                <Menu.Item key="admin:6">
                  <Link to="/instrucciones/"><Icon type="unordered-list" />Instrucciones</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="pie-chart" />
                    Reportes
              </span>
                }
              >
                <Menu.Item key="reportes:1">
                  <Link to="/resumen-dia/">Resumen del día</Link>
                </Menu.Item>
                {this.props.perms['ver_reporte_ventas'] === true &&
                <Menu.Item key="reportes:2">
                  <Link to="/reportes/ventas/">Ventas</Link>
                </Menu.Item>
                }
                {this.props.perms['ver_reporte_ingresos_e_inventarios'] === true &&
                <Menu.Item key="reportes:3">
                  <Link to="/reportes/ingresos/">Ingresos y Egresos</Link>
                </Menu.Item>
                }
                {this.props.perms['ver_reporte_ingresos_e_inventarios'] === true &&
                <Menu.Item key="reportes:4">
                  <Link to="/reportes/inventarios/">Inventarios</Link>
                </Menu.Item>
                }
              </SubMenu>
            {authenticated ? (
              <Menu.Item key="1" style={{ float: 'right' }} onClick={this.props.logout}>{localStorage.getItem("username")} | Salir</Menu.Item>
            ) : (
                <Menu.Item key="3" style={{ float: 'right' }}>
                  <Link to="/login">Login</Link>
                </Menu.Item>
              )}
          </Menu>
          
        </Header>
    )
    let header_2_no_auth = (
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="logo:1">
              <Link to="/">
                <img src={Logo} alt='Vapes logo' width='125px' ></img>
              </Link>
            </Menu.Item>
            {authenticated ? (
              <Menu.Item key="1" style={{ float: 'right' }} onClick={this.props.logout}>Logout</Menu.Item>
            ) : (
                <Menu.Item key="3" style={{ float: 'right' }}>
                  <Link to="/login">Login</Link>
                </Menu.Item>
              )}
          </Menu>
        </Header>
    )
    return (
      <Layout>
        {(authenticated ? header_1 : header_2_no_auth )}     
        <Content style={{ padding: dimension, marginTop: 64 }}>
          
          <Breadcrumb style={{ margin: '16px 0' }}>
          {authenticated &&
            <Breadcrumb.Item>
              <Link to="/"><Icon type="home" /></Link>
            </Breadcrumb.Item>
          }
            <Breadcrumb.Item>
              <Link to={this.props.to1}>{this.props.label1}</Link>
            </Breadcrumb.Item>
            {this.props.numOfBcrumbs >= 2 ?
              <Breadcrumb.Item>
                <Link to={this.props.to2}>{this.props.label2}</Link>
              </Breadcrumb.Item>
              : ''}
          </Breadcrumb>
          <div style={{ background: '#fff', padding: 24, minHeight: 380 }}>
            {this.props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>© ArreglaTuPantalla & Vapes Masaryk 2020</Footer>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null,
    to1: state.bcrumbs.to1,
    to2: state.bcrumbs.to2,
    label1: state.bcrumbs.label1,
    label2: state.bcrumbs.label2,
    numOfBcrumbs: state.bcrumbs.numOfBcrumbs,
    perms: state.auth.permissions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomLayout)
);
