import React from 'react';
import { agregarConceptoURL } from '../../constants';
import { Button, Divider, Form, Input, message } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { withRouter } from "react-router-dom";

class ConceptoPago extends React.Component {
    componentDidMount() {
        this.props.onVisit();
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                axios
                    .post(agregarConceptoURL, values)
                    .then(res => {
                        //console.log(res)
                        //console.log(values, 'values')
                        this.props.history.push('/');
                        message.success('¡Se agregó el concepto ' + values.nombre + ' con éxto!');
                    })
                    .catch(err => {
                        console.log(err)
                        message.error(err.response.data.message);
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                <div>
                    <h3 style={{ textAlign: 'left' }}>Agregar Concepto de Pago/Gasto</h3>
                    <Divider />
                </div>
                <div style={{ margin: 'auto', maxWidth: '400px', textAlign: 'center' }}>
                    <Form layout={'vertical'} labelCol={null} onSubmit={this.handleSubmit}>
                        <Form.Item label="Nombre" >
                            {getFieldDecorator('nombre', {
                                rules: [{ required: true, message: 'Por favor ingresa el nombre del concepto de pago!' }],
                            })(<Input maxLength={120} />)}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Agregar Concepto
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/agregar-concepto/', 'Agregar Concepto de Pago / Gasto', '/', '', 1))
        //link1, label 1, link 2, label 2
    }
}

const WrappedApp = Form.create({ name: 'concepto' })(ConceptoPago);
export default withRouter(connect(null, mapDispatchToProps)(WrappedApp));