import React from 'react';
import { proveedorEditarURL, proveedorDetailURL } from '../../constants';
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { Button, Divider, Form, Input, message } from 'antd';
import axios from 'axios'
import { withRouter } from "react-router-dom";

class EditarProveedor extends React.Component {
    state = {
        id: '',
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.onVisit(params);
        //console.log(params)
        //console.log(this.props.form.getFieldsValue().nombre, 'this.props.form.getFieldsValue().nombre')
        axios
            .get(proveedorDetailURL(params.proveedorID))
            .then(res => {
                console.log(res.data)
                this.props.form.setFieldsValue({
                    nombre: res.data.nombre,
                    comentarios: res.data.comentarios
                });
                this.setState({
                    ...this.state,
                    ...{ id: res.data.id }
                })
            })
            .catch(err => {
                console.log(err)
            });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                axios
                    .put(proveedorEditarURL(this.state.id), {
                        nombre: this.props.form.getFieldsValue().nombre,
                        comentarios: this.props.form.getFieldsValue().comentarios
                    })
                    .then(res => {
                        console.log(res)
                        console.log(values, 'values')
                        this.props.history.push(`/proveedores/${res.data.id}/`);
                        message.success('¡Se modificó al proveedor ' + values.nombre + ' con éxto!');
                    })
                    .catch(err => {
                        console.log(err)
                        message.error('Hubo un error. Recuerda que el nombre del proveedor debe de ser único');
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        //const { nombre, comentarios } = this.state.data
        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Editar Proveedor</h3>
                <Divider></Divider>
                <div style={{ margin: 'auto', maxWidth: '400px', textAlign: 'center' }}>
                    <Form layout={'vertical'} onSubmit={this.handleSubmit}>
                        <Form.Item label="Nombre">
                            {getFieldDecorator('nombre', {
                                rules: [{ required: true, message: 'Por favor ingresa el nombre del proveedor!' }],
                            })(<Input maxLength={120} />)}
                        </Form.Item>
                        <Form.Item label="Comentarios">
                            {getFieldDecorator('comentarios', {
                            })(<Input.TextArea maxLength={350} style={{ height: 150 }} />)}
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Guardar
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/proveedores/', 'Proveedores', `/proveedor/${params.proveedorID}/editar/`, 'Editar Proveedor', 2))
        //link1, label 1, link 2, label 2
    }
}

const WrappedApp = Form.create({ name: 'editar-proveedor' })(EditarProveedor);
export default withRouter(connect(null, mapDispatchToProps)(WrappedApp));