import React, { Component } from 'react';
import { newPage } from '../../store/actions/breadcrumbs';
import { connect } from 'react-redux';
import { BalanceAlDia } from './BalanceAlDia';
import { VentasDelDia } from './VentasDelDia';
import { OtrosPagos } from './OtrosPagos';
import { TotalIngresosDia } from './TotalIngresosDia';
import { ventasListURL, PagosURL, BalanceAlDiaURL } from '../../constants';
import { Divider, DatePicker, Radio } from 'antd';
import axios from 'axios';
import moment from 'moment';

export class ResumenDia extends Component {
  state = {
    data: [],
    dataVenta: [],
    dataPago: [],
    dataTotalesDia: [],
    startURL:
      ventasListURL +
      '?fecha=' +
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
        .toISOString()
        .substring(0, 10),
    date: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .substring(0, 10),
    ingresosTotalesDia: 0,
    egresosTotalesDia: 0,
    tienda: 'T', //Por default se muestran todas
  };

  componentDidMount() {
    this.props.onVisit();
  }

  onChange = (date, dateString) => {
    //console.log(date, dateString, 'DDDDDDDATTES');
    const base_url_pagos =
      PagosURL + '?fecha=' + dateString + '&tienda=' + this.state.tienda;
    this.loadVentasData(
      ventasListURL +
        '?fecha=' +
        dateString +
        '&tienda_res_dia=' +
        this.state.tienda
    );
    this.loadPagosDataConTraspaso(base_url_pagos);
    this.loadPagosDataSinTraspaso(base_url_pagos + '&traspaso=sin');
    this.loadTotalesDiaData(
      BalanceAlDiaURL + '?fecha=' + dateString + '&tienda=' + this.state.tienda
    );
  };

  onChangeTienda = (e) => {
    e.preventDefault();
    this.setState({ tienda: e.target.value });
  };

  loadTotalesDiaData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let formas = Object.keys(res.data).map(function (key, index) {
          return {
            key: Math.random(),
            forma_de_pago: key,
            balance: '$ ' + res.data[key].toLocaleString(),
          };
        });
        this.setState({ dataTotalesDia: formas });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
  };

  loadPagosDataConTraspaso = (url) => {
    axios
      .get(url)
      .then((res) => {
        let pagos = res.data.map((p) => {
          return {
            key: p.id,
            venta: p.venta,
            forma_de_pago: p.forma_de_pago.nombre,
            concepto: p.concepto.nombre,
            monto: '$ ' + p.monto.toLocaleString(),
            monto2: p.monto,
          };
        });
        this.setState({
          dataPago: pagos,
        });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
  };

  loadPagosDataSinTraspaso = (url) => {
    axios
      .get(url)
      .then((res) => {
        let pagos = res.data.map((p) => {
          return {
            key: p.id,
            monto2: p.monto,
          };
        });
        const arr_ingresos = [];
        const arr_egresos = [];
        pagos.map((p) => {
          const arr = p.monto2 > 0 ? arr_ingresos : arr_egresos;
          arr.push(p.monto2);
          return 'success';
        });

        const ingresosTotalesDia = arr_ingresos
          .map((m) => m)
          .reduce((sum, el) => {
            return sum + el;
          }, 0);

        const egresosTotalesDia = arr_egresos
          .map((m) => m)
          .reduce((sum, el) => {
            return sum + el;
          }, 0);

        this.setState({
          ingresosTotalesDia,
          egresosTotalesDia,
        });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
  };

  loadVentasData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let ventas = res.data.map((venta) => {
          let productos = venta.productos.map(
            (p) => p.producto.nombre + ' (' + p.cantidad + ')'
          );
          let formas_de_pago = venta.pago.map(
            (p) => p.forma_de_pago.nombre + ' (' + p.monto + ')'
          );
          let trimmed_nota =
            venta.nota.length > 15
              ? venta.nota.slice(0, 15) + ' ...'
              : venta.nota;
          return {
            key: venta.id,
            id: venta.id,
            nota: trimmed_nota,
            productos: productos,
            forma_de_pago: formas_de_pago,
            valor_venta: '$ ' + venta.valor_venta.toLocaleString(),
          };
        });
        this.setState({
          ...this.state,
          ...{ dataVenta: ventas },
        });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
    this.setState({
      ...this.state,
      ...{ dataVenta2: 'ventas' },
    });
  };

  render() {
    const { date, ingresosTotalesDia, egresosTotalesDia } = this.state;
    const dateFormat = 'YYYY-MM-DD';

    return (
      <React.Fragment>
        <h3 style={{ textAlign: 'left' }}>Resumen del día</h3>
        <Divider />
        <div style={{ textAlign: 'center', maxWidth: '800px', margin: 'auto' }}>
          <Radio.Group onChange={this.onChangeTienda} defaultValue="T">
            <Radio.Button value="T">Todas</Radio.Button>
            <Radio.Button value="A">Arregla</Radio.Button>
            <Radio.Button value="V">Vapes</Radio.Button>
          </Radio.Group>
          <h4>Balance al día en dinero</h4>
          <BalanceAlDia tienda={this.state.tienda} />
          <Divider />
          <h5 style={{ textAlign: 'center' }}>
            Fecha seleccionada:{' '}
            <DatePicker
              onChange={this.onChange}
              defaultValue={moment(date, dateFormat)}
            />
          </h5>
          <p style={{ textAlign: 'center' }}>
            <b>Total Ingresos del día:</b> {ingresosTotalesDia.toLocaleString()}{' '}
            | <b>Total Egresos del día:</b> {egresosTotalesDia.toLocaleString()}
          </p>
          <h4 style={{ margin: '-0.1em' }}>Venta de productos del día</h4>
          <span>
            Las ventas no necesariamente se pagaron en el día seleccionado
          </span>
          <VentasDelDia
            data={this.state.dataVenta}
            load={(url) => this.loadVentasData(url)}
            tienda={this.state.tienda}
          />
          <br></br>
          <h4>Ingresos/Gastos del día</h4>
          <OtrosPagos
            data={this.state.dataPago}
            load={(url) => this.loadPagosDataConTraspaso(url)}
            tienda={this.state.tienda}
          />
          <br></br>
          {this.props.perms['cosas_de_socios'] === true && (
            <>
              <h4>Total Ingresos - Egresos del día</h4>
              <TotalIngresosDia
                data={this.state.dataTotalesDia}
                load={(url) => this.loadTotalesDiaData(url)}
                tienda={this.state.tienda}
              />
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    perms: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onVisit: () =>
      dispatch(newPage('/resumen-dia/', 'Resumen del día', '', '', 1)),
    //link1, label 1, link 2, label 2
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumenDia);
