import React from 'react';
import Highlighter from 'react-highlight-words';
import { newPage } from '../../store/actions/breadcrumbs';
import { connect } from 'react-redux';
import { Input, Button, Icon, Table, message, Row, Col, Tag } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PagosURL, FormasDePagoURL, ConceptosDePagoURL } from '../../constants';

class Pagos extends React.Component {
  state = {
    searchText: '',
    filteredInfo: null,
    loading: false,
    error: null,
    data: [],
    filtersForma: [],
    filtersConcepto: [],
  };

  loadData() {
    this.setState({ loading: true });
    let pagos_url_fechas_check =
      this.props.desdeSiempre === true ? PagosURL : PagosURL + '?ultimomes=1';
    axios
      .get(pagos_url_fechas_check)
      .then((res) => {
        //console.log(res.data)
        let pagos = res.data.map((pago) => {
          return {
            key: pago.id,
            id: pago.id,
            monto: pago.monto.toLocaleString(),
            forma_de_pago: pago.forma_de_pago.nombre,
            concepto: pago.concepto.nombre,
            cliente: pago.cliente.nombre,
            cliente_id: pago.cliente.id,
            fecha: pago.fecha,
          };
        });
        //console.log(pagos)
        this.setState({ data: pagos, loading: false });
      })
      .catch((err) => {
        this.setState({ error: err, loading: false });
      });
    axios
      .get(FormasDePagoURL)
      .then((res) => {
        let formas = res.data.map((f) => {
          return {
            text: f.nombre,
            value: f.nombre,
          };
        });
        this.setState({ filtersForma: formas });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(ConceptosDePagoURL)
      .then((res) => {
        let conceptos = res.data.map((c) => {
          return {
            text: c.nombre,
            value: c.nombre,
          };
        });
        this.setState({ filtersConcepto: conceptos });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.props.onVisit();
    this.loadData();
  }

  //EMPIEZAN FILTROS PARA SEARCH
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Buscar producto`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  //TERMINAN FILTROS PARA SEARCH
  //FILTROS FILTER
  handleChange = (pagination, filters, sorter) => {
    //console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
    message.success(
      'Se limpiaron los filtros. El filtro de búsqueda debe hacerse de manera manual.'
    );
  };

  //TERMINAN FILTROS FILTER

  render() {
    let { filteredInfo, filtersForma, filtersConcepto, loading } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (text, record) => <Link to={record.id + '/'}>{text}</Link>,
      },
      {
        title: 'Monto',
        dataIndex: 'monto',
        key: 'monto',
        align: 'center',
        render: (tag) => {
          let color = tag.includes('-') ? 'magenta' : 'green';
          return (
            <Tag color={color} key={Math.random()}>
              {tag}
            </Tag>
          );
        },
      },
      {
        title: 'Cuenta',
        dataIndex: 'forma_de_pago',
        key: 'forma_de_pago',
        filters: filtersForma, //[{ text: 'Elite Vapor', value: 'Elite Vapor' }, { text: 'Relx', value: 'Relx' }],
        filteredValue: filteredInfo.forma_de_pago || null,
        onFilter: (value, record) => record.forma_de_pago.includes(value),
        ellipsis: true,
      },
      {
        title: 'Concepto',
        dataIndex: 'concepto',
        key: 'concepto',
        filters: filtersConcepto, //[{ text: 'Elite Vapor', value: 'Elite Vapor' }, { text: 'Relx', value: 'Relx' }],
        filteredValue: filteredInfo.concepto || null,
        onFilter: (value, record) => record.concepto.includes(value),
        ellipsis: true,
      },
      {
        title: 'Cliente',
        dataIndex: 'cliente',
        key: 'cliente',
        ...this.getColumnSearchProps('cliente'),
        //render: (text, record) => <Link to={record.cliente_id + '/'}>{text}</Link>,
      },
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
      },
    ];

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <React.Fragment>
              <h3 style={{ textAlign: 'left' }}>
                {this.props.desdeSiempre
                  ? 'Pagos Desde Siempre  '
                  : 'Pagos Último Mes Solamente  '}
              </h3>
              <h6 style={{ marginLeft: '10px' }}>
                {this.props.desdeSiempre ? (
                  <Link to={'/pagos/'}> Ir a Último mes</Link>
                ) : (
                  <Link to={'/pagos/desde-siempre/'}> Ir a desde siempre</Link>
                )}
              </h6>
            </React.Fragment>
          </Col>
          <Col span={12}>
            <Button style={{ float: 'right' }} onClick={this.clearAll}>
              <Icon type="filter" /> Limpiar Filtros
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.data}
          bordered={true}
          pagination={{ defaultPageSize: 20 }}
          onChange={this.handleChange}
          loading={loading}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onVisit: () => dispatch(newPage('/pagos/', 'Pagos', '/', '', 1)),
    //link1, label 1, link 2, label 2
  };
};

export default connect(null, mapDispatchToProps)(Pagos);
