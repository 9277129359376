import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Spin } from 'antd';

const PrivateRoute = ({ component: Component, auth, ...rest }) => ( // -JS- component: Component === component={Component} -JSX-
  <Route                                        //same as just passing "auth"
    {...rest}     // En el ejemplo de abajo se ve como se convierte el código de "component=Dashboard" a "component: Dashboard". Por eso puede ser que no se entienda la lógica de JSX pero es porque despues de compile a JAVASCRIPT.
    render={props => {  //No pasas props en este caso       //You need return JSX that will be rendered.
      if (auth.loading) {
        return <div style={{margin: 'auto', maxWidth: '400px', textAlign: 'center', marginTop: '10em'}}> <Spin/> </div>;
      } else if (!localStorage.getItem("token")) {
        return <Redirect to="/login" />;
      } else {
        return <Component {...props} />; // No se pasan props, los quite en incognito y todo y no paso nada
      }
    }}
  /> //Route replaces itself with whatever you pass in the "render" part.
);

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);

/* 
En App.js: JSX
  <PrivateRoute exact path="/" component={Dashboard} />
Se convierte en.. JS

React.createElement(PrivateRoute, {
  exact: true,
  path: "/",
  component: Dashboard
});

JSX...
const PrivateRoute = ({ component: Component, auth: auth, ...rest }) => (
  <Route                                    
    {...rest}
    render={props => { 
      if (auth.isLoading) {
        return <h2>Loading...</h2>;
      } else if (!auth.isAuthenticated) {
        return <Redirect to="/login" />;
      } else {
        return <Component {...props} />;
      }
    }}
  /> 
);

JS....
var PrivateRoute = function PrivateRoute(_ref) {
  var Component = _ref.component,
      auth = _ref.auth,
      rest = _objectWithoutProperties(_ref, ["component", "auth"]);

  return React.createElement(Route, _extends({}, rest, {
    render: function render(props) {
      if (auth.isLoading) {
        return React.createElement("h2", null, "Loading...");
      } else if (!auth.isAuthenticated) {
        return React.createElement(Redirect, {
          to: "/login"
        });
      } else {
        return React.createElement(Component, props);
      }
    }
  }));
};

*/
