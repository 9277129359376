import React from 'react';
import { proveedorNuevoURL } from '../../constants';
import { Button, Divider, Form, Input, message } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { withRouter } from "react-router-dom";
import * as actions from "./../../store/actions/proveedores"


class Proveedor extends React.Component {
    componentDidMount() {
        if(this.props.desdePedido !== true){
        this.props.onVisit();
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                //console.log(proveedoresListURL)
                axios
                    .post(proveedorNuevoURL, values)
                    .then(res => {
                        //console.log(res)
                        //console.log(values, 'values')
                        if (this.props.showSelect) { //dependiendo de la página en que estes te redirige o no
                            this.props.history.push(`/proveedores/${res.data.id}`);
                        } else if (!this.props.showSelect) {
                            this.props.onChangeProveedor(res.data.id)
                            this.props.onClickToogle()
                            this.props.continuar()
                        }
                        message.success('¡Se agregó al proveedor ' + values.nombre + ' con éxto!');
                    })
                    .catch(err => {
                        console.log(err)
                        message.error(err.response.data.message);
                    });
            }
        });
    };

    render() {
        const { showSelect } = this.props
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                {(showSelect === true) ?
                    <div>
                        <h3 style={{ textAlign: 'left' }}>Agregar Proveedor</h3>
                        <Divider />
                    </div>
                    : ''}
                <div style={{ margin: 'auto', maxWidth: '400px', textAlign: 'center' }}>
                    <Form layout={'vertical'} labelCol={null} onSubmit={this.handleSubmit}>
                        <Form.Item label="Nombre" >
                            {getFieldDecorator('nombre', {
                                rules: [{ required: true, message: 'Por favor ingresa el nombre del proveedor!' }],
                            })(<Input maxLength={120} />)}
                        </Form.Item>
                        <Form.Item label="Identificador" >
                            {getFieldDecorator('identificador', {
                                rules: [{ required: true, message: 'Por favor ingresa una o dos letras únicas que identifiquen al proveedor!' }],
                            })(<Input maxLength={120} />)}
                        </Form.Item>
                        <Form.Item label="Comentarios">
                            {getFieldDecorator('comentarios', {
                            })(<Input.TextArea maxLength={350} style={{ height: 100 }} />)}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Agregar Proveedor
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/agregar-cliente/', 'Agregar Proveedor', '/agregar-cliente/', 'Agregar Cliente', 1)),
        //link1, label 1, link 2, label 2
        onChangeProveedor: (value) => dispatch(actions.changeProveedor(value)),
    }
}

const WrappedApp = Form.create({ name: 'proveedor' })(Proveedor);
export default withRouter(connect(null, mapDispatchToProps)(WrappedApp));