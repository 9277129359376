import React from 'react';
import Highlighter from 'react-highlight-words';
import { Table, Input, Button, Icon } from 'antd';
import axios from "axios";
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { clientesListURL } from "../../constants";
import { Link } from 'react-router-dom'

class Clientes extends React.Component {
    state = {
        loading: false,
        error: null,
        data: []
    }

    componentDidMount() {
        this.props.onVisit();
        this.setState({ loading: true });
        axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: 'Token 5ccf5ebd73f35701b3b829b04245f9b614566614'
    };
//         const headers = {
//   'Content-Type': 'application/json',
//   'Authorization': 'Token 5ccf5ebd73f35701b3b829b04245f9b614566614',
// };
        axios
            .get(clientesListURL)
            .then(res => {
                //console.log(res.data)
                let clientes = res.data.map(cliente => {
                    return (
                        {
                            key: cliente.id,
                            id: cliente.id,
                            nombre: cliente.nombre,
                            comentarios: cliente.comentarios,
                            agregado: cliente.agregado,
                        }
                    )
                });
                //console.log(clientes)
                this.setState({ data: clientes, loading: false });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    }

    //EMPIEZAN FILTROS PARA SEARCH
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar producto`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    //TERMINAN FILTROS PARA SEARCH

    render() {
        const { loading } = this.state;
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                ...this.getColumnSearchProps('id')
            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                //render: text => <a href={`/cliente/${this.key}`}>{text}</a>,
                ...this.getColumnSearchProps('nombre'),
                render: (text, record) => <Link to={record.id + '/'}>{text}</Link>,
            },
            {
                title: 'Comentarios',
                dataIndex: 'comentarios',
                key: 'comentarios',
            },
            {
                title: 'Agregado',
                dataIndex: 'agregado',
                key: 'agregado',
            },
        ];

        return (
            <>
                <h3 style={{ textAlign: 'left' }}>Clientes</h3>
                <Table columns={columns} dataSource={this.state.data} bordered={true} pagination={{ defaultPageSize: 10 }} loading={loading} />
            </>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/cliente/', 'Clientes', '/', '', 1))
        //link1, label 1, link 2, label 2
    }
}

export default connect(null, mapDispatchToProps)(Clientes);