import React from 'react';
import { clienteEditarURL, DetalleClienteURL } from '../../../constants';
import { connect } from 'react-redux';
import { newPage } from "../../../store/actions/breadcrumbs";
import { Button, Divider, Form, Input, message } from 'antd';
import axios from 'axios'
import { withRouter } from "react-router-dom";

class EditarCliente extends React.Component {
    state = {
        id: '',
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.onVisit(params);
        //console.log(params)
        //console.log(this.props.form.getFieldsValue().nombre, 'this.props.form.getFieldsValue().nombre')
        axios
            .get(DetalleClienteURL(params.clienteID))
            .then(res => {
                this.props.form.setFieldsValue({
                    nombre: res.data.nombre,
                    email: res.data.email,
                    tel: res.data.tel,
                    comentarios: res.data.comentarios
                });
                this.setState({
                    ...this.state,
                    ...{ id: res.data.id }
                })
            })
            .catch(err => {
                console.log(err)
            });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                axios
                    .put(clienteEditarURL(this.state.id), {
                        nombre: this.props.form.getFieldsValue().nombre,
                        tel: this.props.form.getFieldsValue().tel,
                        email: this.props.form.getFieldsValue().email,
                        comentarios: this.props.form.getFieldsValue().comentarios
                    })
                    .then(res => {
                        this.props.history.push(`/cliente/${res.data.id}/`);
                        message.success('¡Se modificó al cliente ' + values.nombre + ' con éxto!');
                    })
                    .catch(err => {
                        console.log(err)
                        message.error(err.response.data.message);
                        message.error('Hubo un error. Recuerda que el nombre del cliente debe de ser único. El email es válido?');
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        //const { nombre, comentarios } = this.state.data
        return (
            <React.Fragment>
                <h3 style={{ textAlign: 'left' }}>Editar Cliente</h3>
                <Divider></Divider>
                <div style={{ margin: 'auto', maxWidth: '400px', textAlign: 'center' }}>
                    <Form layout={'vertical'} onSubmit={this.handleSubmit}>
                        <Form.Item label="Nombre">
                            {getFieldDecorator('nombre', {
                                rules: [{ required: true, message: 'Por favor ingresa el nombre del cliente!' }],
                            })(<Input maxLength={120} />)}
                        </Form.Item>
                        <Form.Item label="Cel/Tel" >
                            {getFieldDecorator('tel', {
                            })(<Input maxLength={40} />)}
                        </Form.Item>
                        <Form.Item label="Email">
                            {getFieldDecorator('email', {
                            })(<Input maxLength={120} />)}
                        </Form.Item>
                        <Form.Item label="Comentarios">
                            {getFieldDecorator('comentarios', {
                            })(<Input.TextArea maxLength={350} style={{ height: 150 }} />)}
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Guardar
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: (params) => dispatch(newPage('/cliente/', 'Clientes', `/cliente/${params.clienteID}/editar/`, 'Editar Cliente', 2))
        //link1, label 1, link 2, label 2
    }
}

const WrappedApp = Form.create({ name: 'editar-cliente' })(EditarCliente);
export default withRouter(connect(null, mapDispatchToProps)(WrappedApp));