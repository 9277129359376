import React, { Component } from 'react'
import { VentaDetailURL, FormasDePagoURL } from '../../constants';
import axios from 'axios';
import { InputNumber, Select, Button, Icon, Table } from 'antd';
const { Option } = Select;

const columns1 = [
    {
        title: 'Producto',
        dataIndex: 'producto',
        key: 'producto',
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
    },
];

const columns2 = [
    {
        title: 'Forma de pago',
        dataIndex: 'forma_de_pago',
        key: 'forma_de_pago',
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
    },
];

export class Devolucion extends Component {
    state = {
        data: [],
        productos: [],
        inicial: [],
        inicial_formas: [],
        formas: []
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        //this.props.onVisit(params)
        axios
            .get(VentaDetailURL(params.ventaID))
            .then(res => {
                console.log(res.data);
                this.setState({
                    data: res.data,
                    productos: res.data.productos
                });
            })
            .catch(err => {
                console.log(err)
            });

        axios
            .get(FormasDePagoURL)
            .then(res => {
                this.setState({
                    formas: res.data
                });
            })
            .catch(err => {
                console.log(err)
            });
    }

    handleChange2 = (e, index, nombre) => {
        let { inicial, inicial_formas } = this.state;
        if (nombre === 'producto') {
            let new_state = inicial.map((prod, ix) => {
                if (ix === index) {
                    prod.cantidad = e //InputNumber de antd, pasa el valor en e, no en e.target.value
                }
                return prod
            })

            this.setState({
                ...this.state,
                ...{ inicial: new_state }
            });
        } else if (nombre === 'forma') {
            let new_state = inicial_formas.map((f, ix) => {
                if (ix === index) {
                    f.cantidad = e
                }
                return f
            })

            this.setState({
                ...this.state,
                ...{ inicial_formas: new_state }
            });
        }
    };

    onSelectHandler = (e, index) => {
        let { inicial } = this.state;
        let new_state = inicial.map((prod, ix) => {
            if (ix === index) {
                prod.id = parseFloat(e === '' || isNaN(e) ? 1 : e) //InputNumber de antd, pasa el valor en e, no en e.target.value
            }
            return prod
        })

        this.setState({
            ...this.state,
            ...{ inicial: new_state }
        });
        console.log(this.state)
    };

    onClickHandler = (nombre) => {
        console.log(nombre)
        if (nombre === 'producto') {
            let inicialState = this.state.inicial
            this.setState({
                ...this.state,
                ...{
                    inicial: inicialState.concat(
                        {
                            id: 0,
                            cantidad: 1
                        }
                    )
                }
            })
        } else if (nombre === 'forma') {
            let inicialState = this.state.inicial_formas
            this.setState({
                ...this.state,
                ...{
                    inicial_formas: inicialState.concat(
                        {
                            id: 0,
                            cantidad: 0
                        }
                    )
                }
            })
        }
    }

    handleOnClickDeleteRow = (indx, nombre) => {
        let { inicial, inicial_formas } = this.state;
        if (nombre === 'producto') {
            let filas = inicial.filter((x, index) => index !== indx)
            this.setState({
                ...this.state,
                ...{ inicial: filas }
            });
        } else if (nombre === 'forma') {
            let filas = inicial_formas.filter((x, index) => index !== indx)
            this.setState({
                ...this.state,
                ...{ inicial_formas: filas }
            });
        }
    };

    render() {
        const { productos, inicial, inicial_formas, formas } = this.state
        let productosB = inicial.map((p, index) => {
            let input_cantidad = <InputNumber min={1} max={20} onChange={(e) => this.handleChange2(e, index, 'producto')} type="number" style={{ width: '50%' }} defaultValue={p.cantidad} />
            let select_producto = <Select
                showSearch
                style={{ width: 400, textAlign: 'center' }}
                placeholder="Buscar producto"
                optionFilterProp="children"
                onChange={(e) => this.onSelectHandler(e, index)}
                value={(inicial[index].id === 0 ? 'Buscar Producto...' : inicial[index].id)}
                allowClear={true}
            //autoFocus={true}
            >

                {
                    productos.map(p => {
                        return <Option key={p.producto.id} value={p.producto.id}>{p.producto.nombre}</Option>
                    })
                }

            </Select>

            let delete_row = (
                <Button onClick={() => this.handleOnClickDeleteRow(index, 'producto')}>
                    <Icon type="delete" theme="filled" style={{ color: 'red' }} />
                </Button>
            )
            return (
                {
                    key: Math.random(),
                    id: index,
                    producto: select_producto,
                    cantidad: input_cantidad,
                    delete: delete_row,
                }
            )
        });

        let formasB = inicial_formas.map((p, index) => {
            let input_cantidad = <InputNumber onChange={(e) => this.handleChange2(e, index, 'forma')} type="number" style={{ width: '50%' }} defaultValue={p.cantidad} />
            let select_forma = <Select
                showSearch
                style={{ width: 400, textAlign: 'center' }}
                placeholder="Buscar forma"
                optionFilterProp="children"
                onChange={(e) => this.onSelectHandler(e, index)}
                value={(inicial_formas[index].id === 0 ? 'Buscar Formas...' : inicial_formas[index].id)}
                allowClear={true}
            //autoFocus={true}
            >

                {
                    formas.map(f => {
                        return <Option key={f.id} value={f.id}>{f.nombre}</Option>
                    })
                }

            </Select>

            let delete_row = (
                <Button onClick={() => this.handleOnClickDeleteRow(index, 'forma')}>
                    <Icon type="delete" theme="filled" style={{ color: 'red' }} />
                </Button>
            )
            return (
                {
                    key: Math.random(),
                    id: index,
                    forma_de_pago: select_forma,
                    cantidad: input_cantidad,
                    delete: delete_row,
                }
            )
        });
        return (
            <React.Fragment>
                <div style={{ textAlign: 'center' }}>
                    <Table columns={columns1} dataSource={productosB} bordered={true} pagination={false} locale={{ emptyText: 'No hay productos seleccionados' }} size={'middle'} />
                    <Button
                        type="primary"
                        style={{ margin: '0.5em' }}
                        onClick={() => this.onClickHandler('producto')}>
                        <Icon type="plus" />
                    </Button>
                    <br></br>
                    <Table columns={columns2} dataSource={formasB} bordered={true} pagination={false} locale={{ emptyText: 'No hay formas seleccionadas' }} size={'middle'} />
                    <Button
                        type="primary"
                        style={{ margin: '0.5em' }}
                        onClick={() => this.onClickHandler('forma')}>
                        <Icon type="plus" />
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}


export default Devolucion
