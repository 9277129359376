import React from 'react';
import { Col, Table, Input, Button, Icon, Row, message, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import axios from "axios";
import { connect } from 'react-redux';
import { newPage } from "../../store/actions/breadcrumbs";
import { productosListURL, categoriasListURL, marcasListURL } from "../../constants";
import { Link } from 'react-router-dom';

class TablaProductos extends React.Component {
    state = {
        searchText: '',
        filteredInfo: null,
        sortedInfo: null,
        loading: false,
        error: null,
        data: [],
        filtersMarcas: [],
        filtersCategorias: []
    }

    componentDidMount() {
        axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`
      }
        this.props.onVisit()
        this.setState({ loading: true });
        axios
            .get(productosListURL)
            .then(res => {
                //console.log(res.data)
                let productos = res.data.map(prod => {
                    //para darle style lo ideal es usar un className, si uso un span no sirve el sorter.
                    //let stock = <span style={prod.stock > 2 ? { color: 'green' } : { color: 'red' }}> {prod.stock} </span>
                    return (
                        {
                            key: prod.id,
                            stock: prod.stock,
                            nombre: prod.nombre + ' [' + prod.id + ']',
                            proveedor: prod.proveedor.nombre,
                            marca: prod.marca.nombre,
                            categoria: prod.categoria.nombre,
                            precio_de_venta: prod.precio_de_venta.toLocaleString(),
                            costo: prod.costo_del_momento.toLocaleString(),
                            garantias: prod.garantias
                        }
                    )
                });
                this.setState({ data: productos, loading: false  });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
        axios
            .get(categoriasListURL)
            .then(res => {
                let categorias = res.data.map(c => {
                    return (
                        {
                            text: c.nombre,
                            value: c.nombre
                        }
                    )
                });
                this.setState({ filtersCategorias: categorias });
            })
            .catch(err => {
                console.log(err)
            });
        axios
            .get(marcasListURL)
            .then(res => {
                let marcas = res.data.map(m => {
                    return (
                        {
                            text: m.nombre,
                            value: m.nombre
                        }
                    )
                });
                this.setState({ filtersMarcas: marcas });
            })
            .catch(err => {
                console.log(err)
            });
    }

    //EMPIEZAN FILTROS PARA SEARCH
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar...`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    //TERMINAN FILTROS PARA SEARCH
    //FILTROS SORT Y FILTER
    handleChange = (pagination, filters, sorter) => {
        //console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null
        });
        message.success('Se limpiaron los filtros. El filtro de búsqueda debe hacerse de manera manual.')
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'descend',
                columnKey: 'stock',
            },
        });
    };
    //TERMINAN FILTROS SORT Y FILTER

    render() {
        let { filteredInfo, filtersMarcas, filtersCategorias, loading } = this.state;
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Stock',
                dataIndex: 'stock',
                key: 'stock',
                align: 'center',
                sorter: (a, b) => a.stock - b.stock,
                render: (tag) => {
                    let color = (tag > 5 ? "green" : (tag > 3 ? "gold" : "magenta"))
                    return (
                        <Tag color={color} key={tag}>
                            {tag}
                        </Tag>
                    )
                }
            },
            {
                title: 'Nombre [ID]',
                dataIndex: 'nombre',
                key: 'nombre',
                ...this.getColumnSearchProps('nombre'),
                render: (text, record) => <Link to={record.key + '/'}>{text}</Link>, //cuidado, record.id no sirve porque no esta definido en return de productosListURL
            },
            {
                title: 'Proveedor',
                dataIndex: 'proveedor',
                key: 'proveedor',
                ...this.getColumnSearchProps('proveedor'),
                render: (text, record) => <Link to={record.key + '/'}>{text}</Link>, //cuidado, record.id no sirve porque no esta definido en return de productosListURL
            },
            {
                title: 'Marca',
                dataIndex: 'marca',
                key: 'marca',
                filters: filtersMarcas, //[{ text: 'Elite Vapor', value: 'Elite Vapor' }, { text: 'Relx', value: 'Relx' }],
                filteredValue: filteredInfo.marca || null,
                onFilter: (value, record) => record.marca.includes(value)
            },
            {
                title: 'Categoría',
                dataIndex: 'categoria',
                key: 'categoria',
                filters: filtersCategorias, //[{ text: 'Devices', value: 'Devices' }, { text: 'Pods', value: 'Pods' }],
                filteredValue: filteredInfo.categoria || null,
                onFilter: (value, record) => record.categoria.includes(value),
            },
            {
                title: 'Precio de Venta',
                dataIndex: 'precio_de_venta',
                key: 'precio_de_venta',
                align: 'center'
            },
            {
                title: 'Costo del Momento',
                dataIndex: 'costo',
                key: 'costo',
                align: 'center'
            },
            {
                title: 'Garantias',
                dataIndex: 'garantias',
                key: 'garantias',
                align: 'center'
            },
        ];

        return (
            <React.Fragment>
                <Row gutter={[16, 16]} >
                    <Col span={12}>
                        <h3 style={{ textAlign: 'left' }}>Lista de Productos</h3>
                    </Col>
                    <Col span={12}>
                        <Button style={{ float: 'right' }} onClick={this.clearAll}><Icon type="filter" /> Limpiar Filtros</Button>
                    </Col>
                </Row>
                <Table columns={columns} dataSource={this.state.data} bordered={true} pagination={{ defaultPageSize: 10 }} onChange={this.handleChange} loading={loading}/>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVisit: () => dispatch(newPage('/productos/', 'Productos', '/productos/', 'Lista de Productos', 2))
        //link1, label 1, link 2, label 2
    }
}

export default connect(null, mapDispatchToProps)(TablaProductos);
